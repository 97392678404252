import { Modal } from 'components/molecules/Modal';
import { AdminNewsCategoryNewModalType } from './types';
import {
	adminNewsCategoryModalButton,
	adminNewsCategoryNewInputText,
	adminNewsNewModalCloseButton,
	modalInnerStyle,
} from './styles';
import { SecondaryButton } from 'components/molecules/Buttons/Secondary';
import { AdminCloseButton } from 'components/molecules/Buttons/AdminClose';
import { DefaultInputText } from 'components/molecules/InputTexts/Default';
import { CheckBox } from 'components/atoms/CheckBox';

export function AdminNewsCategoryNewModalPresenter({
	isVisible,
	form,
	onClose,
	onSubmit,
}: AdminNewsCategoryNewModalType) {
	return (
		<Modal isVisible={isVisible}>
			<div css={modalInnerStyle}>
				<div className='flex items-center justify-between mb-[1rem]'>
					<div>{form.order.placeholder}:</div>
					<DefaultInputText
						css={adminNewsCategoryNewInputText}
						value={form.order.value}
						type='number'
						autoComplete='text'
						min={1}
						onChange={(e) =>
							form.order.setValue(String(parseInt(e.target.value)))
						}
					/>
				</div>
				<div className='flex items-center justify-between mb-[0.5rem]'>
					<div>{form.name.placeholder}:</div>
					<DefaultInputText
						css={adminNewsCategoryNewInputText}
						value={form.name.value}
						type='text'
						autoComplete='text'
						minlength={1}
						maxlength={10}
						onChange={form.name.onChange}
					/>
				</div>
				<div className='flex items-center justify-between mb-[1.5rem]'>
					<label className='flex cursor-pointer'>
						<CheckBox
							className='inline-block mr-[0.5rem]'
							checked={form.SUSPEND_FLG.value}
							onChange={() =>
								form.SUSPEND_FLG.setValue(!form.SUSPEND_FLG.value)
							}
						/>
						<div>{form.SUSPEND_FLG.placeholder}</div>
					</label>
				</div>
				<div className='flex justify-center'>
					<SecondaryButton css={adminNewsCategoryModalButton} onClick={onClose}>
						キャンセル
					</SecondaryButton>
					<SecondaryButton
						css={adminNewsCategoryModalButton}
						onClick={onSubmit}
					>
						保存
					</SecondaryButton>
				</div>
				<AdminCloseButton
					css={adminNewsNewModalCloseButton}
					onClick={onClose}
				/>
			</div>
		</Modal>
	);
}
