import { useState } from 'react';
import { AdminImageModalFormPresenter } from './presenter';
import { AdminImageModalFormType } from './types';
import { toast } from 'react-toastify';
import { resizeImage } from 'utils/resizeImage';
import { useDialog } from 'hooks/useDialog';
import { deleteReceivingImage } from 'lib/api/admins/receiving';
import { useUserState } from 'hooks/state/useUserState';
import { AdminReceivingDeletePhotoBodyType } from 'types/api/admins/receiving';
import { useAdminReceivingBody } from 'hooks/body/useAdminReceivingBody';

export function AdminImageModaReceivingForm({
	data,
	src,
	label,
	photoNo,
	onSubmit: _onSubmit,
	onClose,
}: AdminImageModalFormType) {
	const [base64, setSrc] = useState<string>();
	const [file, setFile] = useState<File>();
	const { mutate } = useAdminReceivingBody();
	const [isLoading, setLoading] = useState(false);
	const imageUrl = base64 ? `data:image/png;base64,${base64}` : undefined;
	const [, onOpen] = useDialog();
	const [user] = useUserState();

	async function onChange(file: File, base64: string) {
		setSrc(base64);
		const { resizedFile } = await resizeImage(file);
		setFile(resizedFile);
	}

	async function onSubmit() {
		if (isLoading) return;
		if (!file) {
			toast.error('ファイルを選択して下さい');
			return;
		}
		setLoading(true);
		await _onSubmit(file);
		setLoading(false);
		setFile(undefined);
		setSrc(undefined);
	}

	async function onDelete() {
		if (!src) {
			toast.error('ファイルが存在しません。');
			return;
		}

		if (await onOpen('削除の確認', `本当に${label}を削除しますか？`)) {
			const params: AdminReceivingDeletePhotoBodyType = {
				niuke_dempyo_no: data.NIUKE_DEMPYO_NO,
				niuke_no: data.NIUKE_NO,
				photo_no: photoNo,
			};
			if (await deleteReceivingImage(user.token, params)) {
				toast.success('画像の削除に成功しました。');
				mutate();
				setFile(undefined);
				setSrc(undefined);
				onClose();
				return;
			}
		}
		toast.error('画像の削除に失敗しました。');
	}

	return (
		<AdminImageModalFormPresenter
			label={label}
			src={imageUrl ? imageUrl : src}
			onChange={onChange}
			onSubmit={onSubmit}
			onDelete={onDelete}
		/>
	);
}
