import { useAdminNewsCategoryBody } from 'hooks/body/useAdminNewsCategoryBody';
import { CheckBoxType, useCheckBox } from 'hooks/inputs/useCheckBox';
import { InputTextType, useInputText } from 'hooks/inputs/useInputText';
import { useUserState } from 'hooks/state/useUserState';
import { updateNewsCategory } from 'lib/api/admins/newsCategory';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { NewsCategoryItemType } from 'types/api/admins/newsCategory';

export type AdminNewsCategoryFormType = {
	SUSPEND_FLG: CheckBoxType;
	order: InputTextType;
	name: InputTextType;
};

export function useAdminNewsCategoryUpdateForm(data: NewsCategoryItemType) {
	const { mutate } = useAdminNewsCategoryBody();
	const [user] = useUserState();
	const SUSPEND_FLG = useCheckBox(false, '休止');
	const order = useInputText('', '表示順', false);
	const name = useInputText('', 'カテゴリー名称', false);

	async function onSubmit() {
		const response = await updateNewsCategory(user.token, String(data.id), {
			SUSPEND_FLG: form.SUSPEND_FLG.value,
			order: form.order.value,
			name: name.value,
		});
		mutate();
		if (response) {
			toast.success('お知らせを更新しました。');
		}
	}

	const form: AdminNewsCategoryFormType = {
		SUSPEND_FLG: {
			...SUSPEND_FLG,
			onChange: async () => {
				form.SUSPEND_FLG.setValue(!form.SUSPEND_FLG.value);
				const response = await updateNewsCategory(user.token, String(data.id), {
					SUSPEND_FLG: !form.SUSPEND_FLG.value,
				});
				if (response) {
					toast.success('重要フラグを更新しました。');
					mutate();
				}
			},
		},
		order,
		name,
	};

	useEffect(() => {
		if (!data) return;
		form.SUSPEND_FLG.setValue(data.SUSPEND_FLG);
		form.order.setValue(data.order);
		form.name.setValue(data.name);

		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [data]);

	return { form, onSubmit };
}
