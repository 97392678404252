import { UserState } from 'globalStates/atoms/user';
import { SetterOrUpdater, useRecoilState } from 'recoil';
import { SigninResponseType } from 'types/api/users/signin';

type Response = [SigninResponseType, SetterOrUpdater<SigninResponseType>];

export const useUserState = (): Response => {
	const [user, setUser] = useRecoilState<SigninResponseType>(UserState);

	return [user, setUser];
};
