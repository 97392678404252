import { atom } from 'recoil';
import { SigninResponseType } from 'types/api/users/signin';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const INITIAL_USER_STATE: SigninResponseType = {
	token: '',
	requires_password_change: false,
	branch_number: '',
	branch_name: '',
	baisan_cd: '',
	is_admin: false,
	is_first_login: false,
	BUY_PERMIT_FLG: false,
	news_ids: [],
};

export const UserState = atom({
	key: 'UserState',
	default: INITIAL_USER_STATE,
	effects_UNSTABLE: [persistAtom],
});
