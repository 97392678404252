import {
	adminNewsItem1,
	newsCategoryItem1,
	newsCategoryItem2,
	newsWebItem,
} from 'mocks/constants/news';
import { pagination } from 'mocks/constants/pagenation';
import { MockedRequest, ResponseResolver, restContext } from 'msw';
import { AdminNewsResponseType } from 'types/api/admins/news';
import { AdminNewsCategoryResponseType } from 'types/api/admins/newsCategory';
import { AdminNewsWebResponseType } from 'types/api/admins/newsWeb';

export const adminNews: ResponseResolver<
	MockedRequest,
	typeof restContext
> = async (req, res, ctx) => {
	if (true) {
		const response: AdminNewsResponseType = {
			datas: {
				...pagination,
				datas: [adminNewsItem1],
			},
			CATEGORY_NMS: ['カテゴリー1', 'カテゴリー2', 'テストカテゴリー'],
		};
		if (process.env.NODE_ENV == 'development')
			await new Promise((resolve) => setTimeout(resolve, 1000));
		return res(ctx.status(200), ctx.json(response));
	} else {
	}
};

export const newsCategory: ResponseResolver<
	MockedRequest,
	typeof restContext
> = async (req, res, ctx) => {
	if (true) {
		const response: AdminNewsCategoryResponseType = {
			...pagination,
			datas: [newsCategoryItem1, newsCategoryItem2],
		};
		if (process.env.NODE_ENV == 'development')
			await new Promise((resolve) => setTimeout(resolve, 1000));
		return res(ctx.status(200), ctx.json(response));
	} else {
	}
};

export const newsWeb: ResponseResolver<
	MockedRequest,
	typeof restContext
> = async (req, res, ctx) => {
	if (true) {
		const response: AdminNewsWebResponseType = {
			...pagination,
			datas: [newsWebItem],
		};
		if (process.env.NODE_ENV == 'development')
			await new Promise((resolve) => setTimeout(resolve, 1000));
		return res(ctx.status(200), ctx.json(response));
	} else {
	}
};
