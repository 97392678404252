import { Modal } from 'components/molecules/Modal';
import { AdminNewsWebUpdateModalPresenterType } from './types';
import {
	adminNewsWebUpdateInputText,
	adminNewsWebUpdateModalCloseButton,
	adminNewsWebUpdateModalH2,
	adminNewsWebUpdateDateTextInput,
	modalInnerStyle,
	adminNewsWebUpdateTimeTextInput,
	adminNewsWebUpdateTHead,
	adminNewsWebUpdateTBody,
	adminNewsWebImageModalButton,
} from './styles';
import { AdminCloseButton } from 'components/molecules/Buttons/AdminClose';
import { DefaultInputText } from 'components/molecules/InputTexts/Default';
import { CheckBox } from 'components/atoms/CheckBox';
import { SecondaryButton } from 'components/molecules/Buttons/Secondary';
import { DefaultInputDate } from 'components/molecules/InputDates/Defalut';

export function AdminNewsWebUpdateModalPresenter({
	form,
	isVisible,
	onClose,
	onSubmit,
}: AdminNewsWebUpdateModalPresenterType) {
	return (
		<Modal isVisible={isVisible} closeModal={onClose}>
			<div css={modalInnerStyle}>
				<h2 css={adminNewsWebUpdateModalH2}>お知らせ ー 詳細</h2>
				<div className='mb-[1.5rem] flex'>
					<div className='w-[280px] flex items-center justify-between mr-[2rem]'>
						<div>{form.DATE.placeholder}：</div>
						<DefaultInputDate
							css={adminNewsWebUpdateInputText}
							value={form.DATE.value}
							type='date'
							autoComplete='date'
							onChange={form.DATE.onChange}
						/>
					</div>
					<div className='w-[280px] flex items-center justify-between mr-[2rem]'>
						<div>{form.PRINT_ORDER.placeholder}：</div>
						<DefaultInputText
							css={adminNewsWebUpdateInputText}
							value={form.PRINT_ORDER.value}
							type='number'
							autoComplete='text'
							min={1}
							onChange={form.PRINT_ORDER.onChange}
						/>
					</div>
					<div className='flex items-center justify-between'>
						<label className='flex cursor-pointer'>
							<CheckBox
								className='inline-block mr-[0.5rem]'
								checked={form.SUSPEND_FLG.value}
								onChange={form.SUSPEND_FLG.onChange}
							/>
							<div>{form.SUSPEND_FLG.placeholder}</div>
						</label>
					</div>
				</div>
				<div className='flex items-center mb-[1.5rem]'>
					<div className='flex items-center mr-[2rem]'>
						<div className='w-[7rem]'>{form.START_DATE_DATE.placeholder}：</div>
						<DefaultInputDate
							className='mr-[0.5rem]'
							css={adminNewsWebUpdateDateTextInput}
							value={form.START_DATE_DATE.value}
							type='date'
							autoComplete='date'
							onChange={form.START_DATE_DATE.onChange}
						/>
						<DefaultInputText
							css={adminNewsWebUpdateTimeTextInput}
							value={form.START_DATE_TIME.value}
							type='time'
							autoComplete='time'
							onChange={form.START_DATE_TIME.onChange}
						/>
					</div>
					<div className='flex items-center'>
						<div className='w-[7rem]'>{form.END_DATE_DATE.placeholder}：</div>
						<DefaultInputDate
							className='mr-[0.5rem]'
							css={adminNewsWebUpdateDateTextInput}
							value={form.END_DATE_DATE.value}
							type='date'
							autoComplete='date'
							onChange={form.END_DATE_DATE.onChange}
						/>
						<DefaultInputText
							css={adminNewsWebUpdateTimeTextInput}
							value={form.END_DATE_TIME.value}
							type='time'
							autoComplete='time'
							onChange={form.END_DATE_TIME.onChange}
						/>
					</div>
				</div>
				<div className='flex mb-[1rem]'>
					<div
						className='flex items-center w-[200px]'
						css={adminNewsWebUpdateTHead}
					>
						{form.TITLE.placeholder}
					</div>
					<div className='w-[calc(100%_-_200px)]' css={adminNewsWebUpdateTBody}>
						<DefaultInputText
							className='!w-full !border-none'
							css={adminNewsWebUpdateInputText}
							value={form.TITLE.value}
							type='text'
							autoComplete='text'
							onChange={form.TITLE.onChange}
						/>
					</div>
				</div>
				<div className='flex justify-center'>
					<SecondaryButton css={adminNewsWebImageModalButton} onClick={onClose}>
						キャンセル
					</SecondaryButton>
					<SecondaryButton
						css={adminNewsWebImageModalButton}
						onClick={onSubmit}
					>
						保存
					</SecondaryButton>
				</div>

				<AdminCloseButton
					css={adminNewsWebUpdateModalCloseButton}
					onClick={onClose}
				/>
			</div>
		</Modal>
	);
}
