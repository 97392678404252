import { MockedRequest, ResponseResolver, restContext } from 'msw';
import { EmailVerifyBodyType } from 'types/api/users/emailVerify';

export const emailVerify: ResponseResolver<
	MockedRequest,
	typeof restContext
> = async (req, res, ctx) => {
	const { password, token }: EmailVerifyBodyType = await req.json();

	if (password && token) {
		return res(ctx.status(200));
	} else {
		return res(ctx.status(401));
	}
};
