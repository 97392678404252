import { Theme, css } from '@emotion/react';

export const webShowSpListItem = (theme: Theme) => css`
	width: 70%;
	background-color: white;
	padding: 5px;
	border: 1px solid ${theme.users.colors.border.default};
	font-size: 0.875rem;
	text-align: left;
`;

export const webShowSpListItemHead = (theme: Theme) => css`
	width: 30%;
	background-color: ${theme.users.colors.background.tableHead};
	padding: 5px;
	border: 1px solid ${theme.users.colors.border.default};
	font-size: 0.875rem;
	text-align: left;
`;

export const webShowSpListItemPhoto = (theme: Theme) => css`
	display: flex;
	align-items: flex-end;
	color: #2b75ad;
	text-decoration: underline;
`;

export const webShowTbodyTankaInputText = (theme: Theme) => css`
	padding: 6px;
	margin-right: 0.5rem;
`;
