import { Modal } from 'components/molecules/Modal';
import { AdminRecoveryMailCategoryNewModalType } from './types';
import {
	adminNewsCategoryModalButton,
	adminRecoveryMailCategoryNewInputText,
	adminNewsNewModalCloseButton,
	modalInnerStyle,
} from './styles';
import { SecondaryButton } from 'components/molecules/Buttons/Secondary';
import { AdminCloseButton } from 'components/molecules/Buttons/AdminClose';
import { DefaultInputText } from 'components/molecules/InputTexts/Default';

export function AdminRecoveryMailCategoryNewModalPresenter({
	isVisible,
	form,
	onClose,
	onSubmit,
}: AdminRecoveryMailCategoryNewModalType) {
	return (
		<Modal isVisible={isVisible}>
			<div css={modalInnerStyle}>
				<div className='flex items-center justify-between mb-[1.5rem]'>
					<div>{form.name.placeholder}:</div>
					<DefaultInputText
						css={adminRecoveryMailCategoryNewInputText}
						value={form.name.value}
						type='text'
						autoComplete='text'
						minlength={1}
						maxlength={10}
						onChange={form.name.onChange}
					/>
				</div>
				<div className='flex justify-center'>
					<SecondaryButton css={adminNewsCategoryModalButton} onClick={onClose}>
						キャンセル
					</SecondaryButton>
					<SecondaryButton
						css={adminNewsCategoryModalButton}
						onClick={onSubmit}
					>
						作成
					</SecondaryButton>
				</div>
				<AdminCloseButton
					css={adminNewsNewModalCloseButton}
					onClick={onClose}
				/>
			</div>
		</Modal>
	);
}
