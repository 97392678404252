import { useModal } from 'hooks/useModal';
import { AdminLinkUpdateModalPresenter } from './presenter';
import { useAdminLinkUpdateForm } from 'hooks/forms/useAdminLinkUpdateForm';

export function AdminLinkUpdate() {
	const [isVisible, setIsVisible, modalState, setModalState] =
		useModal('adminLinkUpdate');
	const { form, onSubmit } = useAdminLinkUpdateForm(modalState.data);

	return (
		<AdminLinkUpdateModalPresenter
			isVisible={isVisible}
			form={form}
			onClose={() => {
				setModalState({});
				setIsVisible(false);
			}}
			onSubmit={async () => {
				await onSubmit();
				setIsVisible(false);
			}}
		/>
	);
}
