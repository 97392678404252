import { mypageItem1, mypageItem2, mypageItem3 } from 'mocks/constants/mypage';
import { newsItem1, newsItem2, newsItem3 } from 'mocks/constants/news';
import { MockedRequest, ResponseResolver, restContext } from 'msw';
import { MyPageResponseType } from 'types/api/users/mypage';

export const mypage: ResponseResolver<
	MockedRequest,
	typeof restContext
> = async (req, res, ctx) => {
	const response: MyPageResponseType = {
		news: [newsItem1, newsItem2, newsItem3],
		schedules: [mypageItem1, mypageItem2, mypageItem3],
		links: [
			{ link: 'https://www.yama-f-market.com/', label: '京都花市場' },
			{ link: 'https://www.yama-f-market.com/', label: '福岡花市場' },
		],
		calendar_note: '6/21~6/24、6/30~7/2はweb公開がありません',
	};

	if (process.env.NODE_ENV == 'development')
		await new Promise((resolve) => setTimeout(resolve, 1000));

	return res(ctx.status(200), ctx.json(response));
};
