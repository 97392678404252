import { newsItem1, newsItem2, newsItem3 } from 'mocks/constants/news';
import { MockedRequest, ResponseResolver, restContext } from 'msw';
import { NewsDetailResponseType, NewsResponseType } from 'types/api/users/news';

export const newsIndex: ResponseResolver<
	MockedRequest,
	typeof restContext
> = async (req, res, ctx) => {
	const response: NewsResponseType = {
		datas: [newsItem1, newsItem2, newsItem3],
	};

	if (process.env.NODE_ENV == 'development')
		await new Promise((resolve) => setTimeout(resolve, 1000));

	return res(ctx.status(200), ctx.json(response));
};

export const newsDetail: ResponseResolver<
	MockedRequest,
	typeof restContext
> = async (req, res, ctx) => {
	const response: NewsDetailResponseType = {
		...newsItem1,
		DESCRIPTION: '<p>テスト</p><p>テスト</p>',
	};

	if (process.env.NODE_ENV == 'development')
		await new Promise((resolve) => setTimeout(resolve, 1000));

	return res(ctx.status(200), ctx.json(response));
};
