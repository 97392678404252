import { Dispatch, SetStateAction, useState } from 'react';

export type CheckBoxType = {
	value: boolean;
	setValue: Dispatch<SetStateAction<boolean>>;
	placeholder?: string;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export function useCheckBox(
	initialValue: boolean,
	initialPlaceholder: string,
): CheckBoxType {
	const [value, setValue] = useState<boolean>(initialValue);
	const [placeholder] = useState<string>(initialPlaceholder);

	return {
		value,
		setValue,
		placeholder,
		onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
			setValue(!value);
		},
	};
}
