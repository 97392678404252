export const defaultTheme = {
	users: {
		colors: {
			primary: '#079557',
			secondary: '#5eba7d',
			link: {
				default: '#1c6ca8',
				marketIndex: '#593500',
				newsIndex: '#2b75ad',
				newsVisited: '#593500',
			},
			border: {
				default: '#dddddd',
			},
			background: {
				default: '#ffffff',
				primary: '#faf8f5',
				searchForm: '#e7e7e7',
				tableHead: '#f1f1f1',
				gray1: '#f2f2f2',
			},
		},
		spacing: {
			desktop: {
				singlePageInner: '110px',
				singlePageInnerBoxInnerVertical: '70px',
				singlePageInnerBoxInnerHorizontal: '50px',
				singlePageH1Bottom: '30px',
				singlePageH2Bottom: '25px',
				headerInnerVertical: '24px',
				pageInnerHorizontal: '20px',
				webSearchForm: '30px',
			},
			mobile: {
				singlePageInnerBoxInnerVertical: '40px',
				singlePageH2Bottom: '15px',
				webSearchForm: '20px',
			},
		},
		width: {
			desktop: {
				singlePageInnerBox: '570px',
				header: '1250px',
				webFilterForm: '350px',
				marketInner: '1140px',
				newsInner: '768px',
			},
		},
	},
	admins: {
		height: {
			desktop: {
				inner: 'calc(100vh - calc(84px + 70.2px))',
			},
		},
	},
	breakpoints: {
		mobile: '480px',
		tablet: '768px',
		desktop: '1250px',
	},
};
