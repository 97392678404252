import { Modal } from 'components/molecules/Modal';
import { AdminReceivingImageModalPresenterType } from './types';
import {
	adminReceivingImageFormWrapper,
	adminReceivingImageModalCloseButton,
	imageFormWrapper,
	modalInnerStyle,
} from './styles';
import { AdminCloseButton } from 'components/molecules/Buttons/AdminClose';
import { AdminImageModaReceivingForm } from 'components/organisms/AdminImageModalReceivingForm';
import { AdminImageModalItemForm } from 'components/organisms/AdminImageModalItemForm';

export function AdminReceivingImageModalPresenter({
	data,
	urls,
	isVisible,
	onClose,
	onSubmit,
}: AdminReceivingImageModalPresenterType) {
	return (
		<Modal isVisible={isVisible} closeModal={onClose}>
			<div css={modalInnerStyle}>
				<div css={imageFormWrapper}>
					<div css={adminReceivingImageFormWrapper}>
						<AdminImageModaReceivingForm
							data={data}
							src={urls[0]}
							photoNo={1}
							label='荷受画像1'
							onSubmit={(file) => onSubmit(file, 0)}
							onClose={onClose}
						/>
					</div>
					<div css={adminReceivingImageFormWrapper}>
						<AdminImageModaReceivingForm
							data={data}
							src={urls[1]}
							photoNo={2}
							label='荷受画像2'
							onSubmit={(file) => onSubmit(file, 1)}
							onClose={onClose}
						/>
					</div>
					<div css={adminReceivingImageFormWrapper}>
						<AdminImageModalItemForm
							data={data}
							src={urls[2]}
							photoNo={1}
							label='商品画像1'
							onSubmit={(file) => onSubmit(file, 2)}
							onClose={onClose}
						/>
					</div>
					<AdminImageModalItemForm
						data={data}
						src={urls[3]}
						photoNo={2}
						label='商品画像2'
						onSubmit={(file) => onSubmit(file, 3)}
						onClose={onClose}
					/>
				</div>
				<AdminCloseButton
					css={adminReceivingImageModalCloseButton}
					onClick={onClose}
				/>
			</div>
		</Modal>
	);
}
