import { CheckBoxType } from './types';

export function CheckBox({ checked, className, onChange }: CheckBoxType) {
	return (
		<input
			type='checkbox'
			className={className}
			checked={checked}
			onChange={onChange}
		/>
	);
}
