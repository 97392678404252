import Link from 'next/link';
import { discountTag, webShowTbody } from '../Tbody/WebShow/styles';
import { webShowHead } from '../Thead/MarketIndex/styles';
import { NegotiationTablePresenterType } from './types';
import Image from 'next/image';
import { roundedCommaNum } from 'utils/application';
import { Tooltip } from 'react-tooltip';

export function NegotiationTablePresenter({
	data,
	isKanbai,
}: NegotiationTablePresenterType) {
	return (
		<table className='w-full'>
			<thead>
				<tr>
					<th css={webShowHead} rowSpan={2}>
						写真
					</th>
					<th css={webShowHead}>商品名称</th>
					<th css={webShowHead}>色</th>
					<th css={webShowHead}>規格</th>
					<th css={webShowHead}>残口数</th>
					<th css={webShowHead}>単価</th>
				</tr>
				<tr>
					<th css={webShowHead}>出荷者名称</th>
					<th css={webShowHead}>入数</th>
					<th css={webShowHead}>備考</th>
					<th css={webShowHead}>端数</th>
					<th css={webShowHead}>割引</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td css={webShowTbody} rowSpan={2}>
						{data.is_photo_present && (
							<Link href={`/web/${data.id}/photo`} target='_blank'>
								<Image
									className='inline'
									width={24}
									height={24}
									alt='camera'
									src='/images/common/icon_photo.svg'
								/>
							</Link>
						)}
					</td>
					<td css={webShowTbody}>{data.product_name}</td>
					<td css={webShowTbody}>{data.color}</td>
					<td css={webShowTbody}>{data.specification}</td>
					<td css={webShowTbody}>
						{isKanbai ? (
							<span className='text-[#E60000]'>完売</span>
						) : (
							`${data.remaining_quantity}/${data.max_quantity}`
						)}
					</td>
					<td css={webShowTbody}>
						{data.recommended_flag ? (
							<>
								<p>{roundedCommaNum(data.recommended_unit_price)}円</p>
								<p className='line-through'>
									{parseInt(data.unit_price) === 0
										? '未定'
										: `${roundedCommaNum(data.unit_price)}円`}
								</p>
							</>
						) : (
							<>
								{parseInt(data.unit_price) === 0
									? '未定'
									: `${roundedCommaNum(data.unit_price)}円`}
							</>
						)}
					</td>
				</tr>
				<tr>
					<td css={webShowTbody}>{data.shipment_provider_name}</td>
					<td css={webShowTbody}>{data.quantity_per_package}</td>
					<td css={webShowTbody}>{data.remarks}</td>
					<td css={webShowTbody}>{data.hasu}</td>
					<td css={webShowTbody}>
						{data.discount_details.length > 0 && !isKanbai && (
							<span css={discountTag} className={`discount-${data.id}`}>
								割引
							</span>
						)}
						<Tooltip anchorSelect={`.discount-${data.id}`} place='top'>
							<span
								dangerouslySetInnerHTML={{
									__html: data.discount_details.join('<br />'),
								}}
							/>
						</Tooltip>
					</td>
				</tr>
			</tbody>
		</table>
	);
}
