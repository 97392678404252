import { InputTextType, useInputText } from 'hooks/inputs/useInputText';
import { useUserState } from 'hooks/state/useUserState';
import { postWebNegotiation } from 'lib/api/users/web';
import { toast } from 'react-toastify';
import { WebDetailItem } from 'types/api/users/webDetail';
import { WebNegotiationBodyType } from 'types/api/users/webNegotiation';

export type NegotiationFormType = {
	KUCHI_SU: InputTextType;
	TANKA: InputTextType;
	BIKO: InputTextType;
};

export function useNegotiationNewForm(data: WebDetailItem) {
	const [user] = useUserState();
	const KUCHI_SU = useInputText('', '希望口数', false);
	const TANKA = useInputText('', '希望単価', false);
	const BIKO = useInputText('', 'コメント', false);

	async function onSubmit() {
		if (!data?.id) {
			toast.error('商品が選択されていません');
			return;
		}
		if (KUCHI_SU.value === '' || TANKA.value === '') {
			toast.error('口数と単価を入力してください');
			return;
		}
		const body: WebNegotiationBodyType = {
			id: data.id,
			KUCHI_SU: parseInt(KUCHI_SU.value),
			TANKA: parseInt(TANKA.value),
			BIKO: BIKO.value,
		};
		if (await postWebNegotiation(user.token, body)) {
			toast.success('商談要件が送信されました。');
			return;
		}
	}

	function resetForm() {
		KUCHI_SU.setValue('');
		TANKA.setValue('');
		BIKO.setValue('');
	}

	const form: NegotiationFormType = {
		KUCHI_SU,
		TANKA,
		BIKO,
	};

	return { form, onSubmit, resetForm };
}
