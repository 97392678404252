import type { AppProps } from 'next/app';
import { ThemeProvider } from '@emotion/react';
import { defaultTheme } from 'theme/theme';
import { RecoilRoot } from 'recoil';
import { LoginRouter } from 'components/organisms/LoginRouter';
import { PermissionRouter } from 'components/organisms/PermissionRouter';
import { ToastContainer } from 'react-toastify';
import { DefaultSeo } from 'next-seo';
import 'ress';
import 'styles/globals.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-tooltip/dist/react-tooltip.css';
import { ModalLayer } from 'components/organisms/ModalLayer';
import { AxiosClientProvider } from 'components/organisms/AxiosClientProvider';

export const isMock =
	typeof window !== 'undefined' &&
	process.env.NEXT_PUBLIC_API_TARGET === 'MOCK';

export default function App({ Component, pageProps }: AppProps) {
	if (isMock) {
		const { worker } = require('../mocks/browser');
		if (navigator.serviceWorker.controller) worker.stop();
		worker.start();
	}

	return (
		<RecoilRoot>
			<DefaultSeo
				titleTemplate='%s | 花市場Web販売システム'
				defaultTitle='花市場Web販売システム'
			/>
			<LoginRouter>
				<PermissionRouter>
					<AxiosClientProvider>
						<ThemeProvider theme={defaultTheme}>
							<ModalLayer />
							<Component {...pageProps} />
							<ToastContainer autoClose={2000} />
						</ThemeProvider>
					</AxiosClientProvider>
				</PermissionRouter>
			</LoginRouter>
		</RecoilRoot>
	);
}
