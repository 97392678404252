import Link from 'next/link';
import { NegotiationMobileTablePresenterType } from './types';
import Image from 'next/image';
import { roundedCommaNum } from 'utils/application';
import { negotiationSpListItemPhoto } from './styles';
import {
	webShowSpListItem,
	webShowSpListItemHead,
} from '../Tbody/WebShowSpListItem/styles';
import { discountTag } from '../Tbody/WebShow/styles';

export function NegotiationMobileTablePresenter({
	data,
	isKanbai,
}: NegotiationMobileTablePresenterType) {
	return (
		<table className='w-full'>
			<tbody>
				<tr>
					<th css={webShowSpListItemHead}>写真</th>
					<td css={webShowSpListItem}>
						{data.is_photo_present && (
							<Link href={`/web/${data.id}/photo`} target='_blank'>
								<span css={negotiationSpListItemPhoto}>
									<Image
										className='inline'
										width={24}
										height={24}
										alt='camera'
										src='/images/common/icon_photo.svg'
									/>
									<span className='pl-[0.2rem]'>写真を見る</span>
								</span>
							</Link>
						)}
					</td>
				</tr>
				<tr>
					<th css={webShowSpListItemHead}>商品名称</th>
					<td css={webShowSpListItem}>{data.product_name}</td>
				</tr>
				<tr>
					<th css={webShowSpListItemHead}>出荷者名称</th>
					<td css={webShowSpListItem}>{data.shipment_provider_name}</td>
				</tr>
				<tr>
					<th css={webShowSpListItemHead}>色</th>
					<td css={webShowSpListItem}>{data.color}</td>
				</tr>
				<tr>
					<th css={webShowSpListItemHead}>規格</th>
					<td css={webShowSpListItem}>{data.specification}</td>
				</tr>
				<tr>
					<th css={webShowSpListItemHead}>入数</th>
					<td css={webShowSpListItem}>{data.quantity_per_package}</td>
				</tr>
				<tr>
					<th css={webShowSpListItemHead}>端数</th>
					<td css={webShowSpListItem}>{data.hasu}</td>
				</tr>
				<tr>
					<th css={webShowSpListItemHead}>残口数</th>
					<td css={webShowSpListItem}>
						{isKanbai ? (
							<span className='text-[#E60000]'>完売</span>
						) : (
							`${data.remaining_quantity}/${data.max_quantity}`
						)}
					</td>
				</tr>
				<tr>
					<th css={webShowSpListItemHead}>単価</th>
					<td css={webShowSpListItem}>
						{data.recommended_flag ? (
							<>
								<p>{roundedCommaNum(data.recommended_unit_price)}円</p>
								<p className='line-through'>
									{parseInt(data.unit_price) === 0
										? '未定'
										: `${roundedCommaNum(data.unit_price)}円`}
								</p>
							</>
						) : (
							<>
								{parseInt(data.unit_price) === 0
									? '未定'
									: `${roundedCommaNum(data.unit_price)}円`}
							</>
						)}
					</td>
				</tr>
				<tr>
					<th css={webShowSpListItemHead}>割引</th>
					<td css={webShowSpListItem}>
						{data.discount_details.length > 0 && !isKanbai && (
							<span
								css={discountTag}
								className={`discount-${data.id} mr-[0.5rem]`}
								data-for={`discount-${data.id}`}
							>
								割引
							</span>
						)}
						{data.discount_details.length > 0 && !isKanbai && (
							<span
								dangerouslySetInnerHTML={{
									__html: data.discount_details.join('<br />'),
								}}
							/>
						)}
					</td>
				</tr>
				<tr>
					<th css={webShowSpListItemHead}>備考</th>
					<td css={webShowSpListItem}>{data.remarks}</td>
				</tr>
			</tbody>
		</table>
	);
}
