import { AdminNextReservationItem } from 'types/api/admins/nextReservation';

export const nextReservationItem1: AdminNextReservationItem = {
	SALE_DATE: '2023-06-15', // 販売日
	YOYAKU_DATE: '2023-06-14', // 予約入力日
	BAISAN_NM: '田中太郎', // 買受人
	BAISAN_EDA: 1, // 枝番
	SHOHIN_NM: '商品A', // 商品名
	COLOR_NM: '赤', // 色
	specification: '121-100 404-407 005 1-2F 80-120cm', // 規格
	BIKO: '特記事項なし', // 備考
	KUCHI_SU: 5, // 口数
	SO_SU: 55, // 総数
	WEB_TANKA: '500〜1500', // web単価（最安値〜最高値）の形式
	YOYAKU_NO: '1',
};
