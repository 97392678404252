import { cancellableIndexItem1 } from 'mocks/constants/cancellable';
import { MockedRequest, ResponseResolver, restContext } from 'msw';
import {
	CancellableCancelResponseType,
	CancellableIndexResponseType,
} from 'types/api/users/cancellable';

export const cancellableIndex: ResponseResolver<
	MockedRequest,
	typeof restContext
> = async (req, res, ctx) => {
	if (true) {
		const response: CancellableIndexResponseType = {
			datas: [
				cancellableIndexItem1,
				cancellableIndexItem1,
				cancellableIndexItem1,
			],
		};
		if (process.env.NODE_ENV == 'development')
			await new Promise((resolve) => setTimeout(resolve, 1000));
		return res(ctx.status(200), ctx.json(response));
	} else {
		return res(ctx.status(401));
	}
};

export const cancellableCancel: ResponseResolver<
	MockedRequest,
	typeof restContext
> = async (req, res, ctx) => {
	if (true) {
		const response: CancellableCancelResponseType = {
			data: cancellableIndexItem1,
		};
		if (process.env.NODE_ENV == 'development')
			await new Promise((resolve) => setTimeout(resolve, 1000));
		return res(ctx.status(200), ctx.json(response));
	} else {
		return res(ctx.status(401));
	}
};
