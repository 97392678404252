import { v4 as uuidv4 } from 'uuid';
import { MockedRequest, ResponseResolver, restContext } from 'msw';
import { WebNextOrderResponseType } from 'types/api/users/webNextOrder';

export const webNextOrder: ResponseResolver<
	MockedRequest,
	typeof restContext
> = async (req, res, ctx) => {
	if (req.headers.get('Authorization')) {
		if (process.env.NODE_ENV == 'development')
			await new Promise((resolve) => setTimeout(resolve, 1000));
		const response: WebNextOrderResponseType = {
			transactionUUID: uuidv4(),
		};

		return res(ctx.status(200), ctx.json(response));
	} else {
		return res(ctx.status(401));
	}
};
