import { useAdminNewsBody } from 'hooks/body/useAdminNewsBody';
import { CheckBoxType, useCheckBox } from 'hooks/inputs/useCheckBox';
import { InputTextType, useInputText } from 'hooks/inputs/useInputText';
import { SelectBoxType, useSelectBox } from 'hooks/inputs/useSelectBox';
import { useUserState } from 'hooks/state/useUserState';
import { newNews } from 'lib/api/admins/news';
import { uploadFile } from 'lib/api/application';
import { ChangeEvent, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

export type AdminNewsFormType = {
	IMPORTANT_FLG: CheckBoxType;
	SUSPEND_FLG: CheckBoxType;
	DATE: InputTextType;
	TITLE: InputTextType;
	START_DATE_DATE: InputTextType;
	START_DATE_TIME: InputTextType;
	END_DATE_DATE: InputTextType;
	END_DATE_TIME: InputTextType;
	CATEGORY_NM: SelectBoxType;
	DESCRIPTION: InputTextType;
};

export function useAdminNewsNewForm(CATEGORY_NMS?: string[]) {
	const { mutate } = useAdminNewsBody();
	const [user] = useUserState();
	const IMPORTANT_FLG = useCheckBox(false, '重要');
	const SUSPEND_FLG = useCheckBox(false, '休止');
	const DATE = useInputText('', '日付', false);
	const TITLE = useInputText('', 'タイトル', false);
	const CATEGORY_NM = useSelectBox('', 'カテゴリー', [
		{ value: '', label: '' },
		...(CATEGORY_NMS || []).map((d) => {
			return { value: d, label: d };
		}),
	]);
	const START_DATE_DATE = useInputText('', '開始日時', false);
	const START_DATE_TIME = useInputText('', '開始日時', false);
	const END_DATE_DATE = useInputText('', '終了日時', false);
	const END_DATE_TIME = useInputText('', '終了日時', false);
	const DESCRIPTION = useInputText('', '内容', false);
	const [file, setFile] = useState<File>();

	async function onSubmit() {
		const response = await newNews(user.token, {
			SUSPEND_FLG: form.SUSPEND_FLG.value,
			IMPORTANT_FLG: form.IMPORTANT_FLG.value,
			DATE: DATE.value,
			CATEGORY_NM: CATEGORY_NM.value,
			START_DATE: `${START_DATE_DATE.value} ${START_DATE_TIME.value}`,
			END_DATE: `${END_DATE_DATE.value} ${END_DATE_TIME.value}`,
			DESCRIPTION: DESCRIPTION.value,
			TITLE: TITLE.value,
		});
		mutate();
		if (response) {
			console.log(response.FILE_PUT_URL);
			console.log(file);
			if (file && response.FILE_PUT_URL) {
				const fileRes = await uploadFile(response.FILE_PUT_URL, file);
				if (!fileRes) return;
			}
			toast.success('お知らせを更新しました。');
		}
	}

	function onChangeFile(event: ChangeEvent<HTMLInputElement>) {
		if (event.target.files && event.target.files?.length > 0) {
			setFile(event.target.files[0]);
		}
	}

	const form: AdminNewsFormType = {
		IMPORTANT_FLG: {
			...IMPORTANT_FLG,
			onChange: async () => {
				form.IMPORTANT_FLG.setValue(!form.IMPORTANT_FLG.value);
			},
		},
		SUSPEND_FLG: {
			...SUSPEND_FLG,
			onChange: async () => {
				form.SUSPEND_FLG.setValue(!form.SUSPEND_FLG.value);
			},
		},
		DATE,
		START_DATE_DATE,
		START_DATE_TIME,
		END_DATE_DATE,
		END_DATE_TIME,
		CATEGORY_NM,
		TITLE,
		DESCRIPTION,
	};

	useEffect(() => {
		if (!CATEGORY_NMS) return;
		form.CATEGORY_NM.setOptions([
			{ value: '', label: '' },
			...(CATEGORY_NMS || []).map((d) => {
				return { value: d, label: d };
			}),
		]);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [CATEGORY_NMS]);

	return { form, onSubmit, onChangeFile };
}
