import { useUserState } from 'hooks/state/useUserState';
import { useState } from 'react';
import useSWR from 'swr';
import { ProductsParamsType } from 'types/api/admins/products';
import { getAdminProduct } from 'lib/api/admins/product';

export function useAdminProduct() {
	const [user] = useUserState();
	const [params, setParams] = useState<ProductsParamsType>();
	const key = `/product+${Object.values(params || {})}`;
	const { data, error, isLoading, mutate } = useSWR(key, async () =>
		params ? await getAdminProduct(user.token, params) : undefined,
	);

	return { data, mutate, isLoading, setParams };
}
