import { useModal } from 'hooks/useModal';
import { AdminNewsUpdateModalPresenter } from './presenter';
import { useAdminNewsUpdateForm } from 'hooks/forms/useAdminNewsUpdateForm';
import { deleteNewsFile } from 'lib/api/admins/news';
import { useUserState } from 'hooks/state/useUserState';
import { toast } from 'react-toastify';
import { useState } from 'react';

export function AdminNewsUpdate() {
	const [isVisible, setIsVisible, modalState, setModalState] =
		useModal('adminNewsUpdate');
	const [user] = useUserState();
	const { form, onSubmit, onChangeFile } = useAdminNewsUpdateForm(
		modalState.data,
		modalState.CATEGORY_NMS,
	);
	const [isLoading, setLoading] = useState<boolean>(false);

	async function _deleteNewsFile() {
		if (!modalState.data) return;
		const response = await deleteNewsFile(
			user.token,
			modalState.data.INFORMATION_CD,
		);
		if (response) toast.success('ファイルを削除しました');
	}

	return (
		<AdminNewsUpdateModalPresenter
			isVisible={isVisible}
			form={form}
			data={modalState.data}
			isLoading={isLoading}
			onClose={() => {
				setModalState({});
				setIsVisible(false);
			}}
			onSubmit={async () => {
				if (isLoading) return;
				setLoading(true);
				await onSubmit();
				setLoading(false);
				setIsVisible(false);
			}}
			onChangeFile={onChangeFile}
			_deleteNewsFile={_deleteNewsFile}
		/>
	);
}
