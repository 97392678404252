import { marketIndexItems } from 'mocks/constants/market';
import { pagination } from 'mocks/constants/pagenation';
import { MockedRequest, ResponseResolver, restContext } from 'msw';
import { MarketIndexResponseType } from 'types/api/users/marketIndex';

export const marketIndex: ResponseResolver<
	MockedRequest,
	typeof restContext
> = async (req, res, ctx) => {
	if (true) {
		const response: MarketIndexResponseType = {
			...pagination,
			recent_listing_date: '2025-01-02',
			datas: marketIndexItems,
		};
		if (process.env.NODE_ENV == 'development')
			await new Promise((resolve) => setTimeout(resolve, 1000));
		return res(ctx.status(200), ctx.json(response));
	} else {
	}
};
