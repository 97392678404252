import { useUserState } from 'hooks/state/useUserState';
import { useState } from 'react';
import useSWR from 'swr';
import { AdminReceivingParamsType } from 'types/api/admins/receiving';
import { getAdminReceiving } from 'lib/api/admins/receiving';

export function useAdminReceiving() {
	const [user] = useUserState();
	const [params, setParams] = useState<AdminReceivingParamsType>();
	const key = `/receiving+${Object.values(params || {})}`;
	const { data, error, isLoading, mutate } = useSWR(key, async () =>
		params ? await getAdminReceiving(user.token, params) : undefined,
	);

	return { data, isLoading, setParams, mutate };
}
