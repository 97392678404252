import { pagination } from 'mocks/constants/pagenation';
import { MockedRequest, ResponseResolver, restContext } from 'msw';
import { AccessLogShowResponseType } from 'types/api/admins/accessLog';
import { accessLogItem } from '../users/accessLog';

export const accessLogIndex: ResponseResolver<
	MockedRequest,
	typeof restContext
> = async (req, res, ctx) => {
	if (true) {
		const response: AccessLogShowResponseType = {
			datas: {
				...pagination,
				datas: [accessLogItem],
			},
			dates: ['2023/07/21'],
			OPERATORS: ['操作者1', '操作者2'],
		};
		if (process.env.NODE_ENV == 'development')
			await new Promise((resolve) => setTimeout(resolve, 1000));
		return res(ctx.status(200), ctx.json(response));
	} else {
	}
};
