import { AxiosResponse } from 'axios';
import { instance } from 'components/organisms/AxiosClientProvider';
import {
	AdminNewsRecoveryMailCategoryParamsType,
	AdminNewsRecoveryMailCategoryResponseType,
	AdminNewsRecoveryMailNewBodyType,
} from 'types/api/admins/recoveryMailCategory';

export async function getRecoveryMailCategories(
	token: string,
	data: AdminNewsRecoveryMailCategoryParamsType,
) {
	try {
		const response: AxiosResponse<AdminNewsRecoveryMailCategoryResponseType> =
			await instance.get(
				`${process.env.API_ENDPOINT}/api/v1/admins/mail/categories`,
				{
					params: data,
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			);

		return response.data;
	} catch (e) {}
}

export async function newNewsRecoveryMailCategory(
	token: string,
	body: AdminNewsRecoveryMailNewBodyType,
) {
	try {
		await instance.post(
			`${process.env.API_ENDPOINT}/api/v1/admins/mail/categories`,
			{ ...body },
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			},
		);
		return true;
	} catch (e) {
		return false;
	}
}
