import {
	INITIAL_NEWS_ITEM_STATE,
	useAdminNewsBody,
} from 'hooks/body/useAdminNewsBody';
import { CheckBoxType, useCheckBox } from 'hooks/inputs/useCheckBox';
import { InputTextType, useInputText } from 'hooks/inputs/useInputText';
import { SelectBoxType, useSelectBox } from 'hooks/inputs/useSelectBox';
import { useUserState } from 'hooks/state/useUserState';
import { updateNews } from 'lib/api/admins/news';
import { uploadFile } from 'lib/api/application';
import { ChangeEvent, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { NewsItemType } from 'types/api/admins/news';

export type AdminNewsFormType = {
	IMPORTANT_FLG: CheckBoxType;
	SUSPEND_FLG: CheckBoxType;
	DATE: InputTextType;
	TITLE: InputTextType;
	START_DATE_DATE: InputTextType;
	START_DATE_TIME: InputTextType;
	END_DATE_DATE: InputTextType;
	END_DATE_TIME: InputTextType;
	CATEGORY_NM: SelectBoxType;
	DESCRIPTION: InputTextType;
};

export function useAdminNewsUpdateForm(
	_data?: NewsItemType,
	CATEGORY_NMS?: string[],
) {
	const data = _data || INITIAL_NEWS_ITEM_STATE;
	const { mutate } = useAdminNewsBody();
	const [user] = useUserState();
	const IMPORTANT_FLG = useCheckBox(data.IMPORTANT_FLG, '重要');
	const SUSPEND_FLG = useCheckBox(data.SUSPEND_FLG, '休止');
	const DATE = useInputText(
		data.DATE?.replace(/\//g, '-') || '',
		'日付',
		false,
	);
	const TITLE = useInputText(data.TITLE, 'タイトル', false);
	const CATEGORY_NM = useSelectBox(
		data.CATEGORY_NM,
		'カテゴリー',
		(CATEGORY_NMS || []).map((d) => {
			return { value: d, label: d };
		}),
	);
	const START_DATE_DATE = useInputText(
		data.START_DATE?.split(' ')[0].replace(/\//g, '-') || '',
		'開始日時',
		false,
	);
	const START_DATE_TIME = useInputText(
		data.START_DATE?.split(' ')[1] || '',
		'開始日時',
		false,
	);
	const END_DATE_DATE = useInputText(
		data.END_DATE?.split(' ')[0].replace(/\//g, '-') || '',
		'終了日時',
		false,
	);
	const END_DATE_TIME = useInputText(
		data.END_DATE?.split(' ')[1] || '',
		'終了日時',
		false,
	);
	const DESCRIPTION = useInputText(data.DESCRIPTION, '内容', false);
	const [file, setFile] = useState<File>();

	async function onSubmit() {
		const response = await updateNews(user.token, data.INFORMATION_CD, {
			SUSPEND_FLG: form.SUSPEND_FLG.value,
			IMPORTANT_FLG: form.IMPORTANT_FLG.value,
			DATE: DATE.value,
			CATEGORY_NM: CATEGORY_NM.value,
			START_DATE: `${START_DATE_DATE.value} ${START_DATE_TIME.value}`,
			END_DATE: `${END_DATE_DATE.value} ${END_DATE_TIME.value}`,
			DESCRIPTION: DESCRIPTION.value,
			TITLE: TITLE.value,
		});
		mutate();
		if (!response) return;
		if (file && data.FILE_PUT_URL) {
			const fileRes = await uploadFile(data.FILE_PUT_URL, file);
			if (!fileRes) return;
		}
		toast.success('お知らせを更新しました。');
	}

	function onChangeFile(event: ChangeEvent<HTMLInputElement>) {
		if (event.target.files && event.target.files?.length > 0) {
			setFile(event.target.files[0]);
		}
	}

	const form: AdminNewsFormType = {
		IMPORTANT_FLG: {
			...IMPORTANT_FLG,
			onChange: async () => {
				form.IMPORTANT_FLG.setValue(!form.IMPORTANT_FLG.value);
				const response = await updateNews(user.token, data.INFORMATION_CD, {
					IMPORTANT_FLG: !form.IMPORTANT_FLG.value,
				});
				if (response) {
					toast.success('重要フラグを更新しました。');
					mutate();
				}
			},
		},
		SUSPEND_FLG: {
			...SUSPEND_FLG,
			onChange: async () => {
				form.SUSPEND_FLG.setValue(!form.SUSPEND_FLG.value);
				const response = await updateNews(user.token, data.INFORMATION_CD, {
					SUSPEND_FLG: !form.SUSPEND_FLG.value,
				});
				if (response) {
					toast.success('休止フラグを更新しました。');
					mutate();
				}
			},
		},
		DATE,
		START_DATE_DATE,
		START_DATE_TIME,
		END_DATE_DATE,
		END_DATE_TIME,
		CATEGORY_NM,
		TITLE,
		DESCRIPTION,
	};

	useEffect(() => {
		if (!_data) return;
		form.IMPORTANT_FLG.setValue(_data.IMPORTANT_FLG);
		form.SUSPEND_FLG.setValue(_data.SUSPEND_FLG);
		form.DATE.setValue(_data.DATE?.replace(/\//g, '-') || '');
		form.START_DATE_DATE.setValue(
			_data.START_DATE?.split(' ')[0].replace(/\//g, '-') || '',
		);
		form.START_DATE_TIME.setValue(_data.START_DATE?.split(' ')[1] || '');
		form.END_DATE_DATE.setValue(
			_data.END_DATE?.split(' ')[0].replace(/\//g, '-') || '',
		);
		form.END_DATE_TIME.setValue(_data.END_DATE?.split(' ')[1]);
		form.CATEGORY_NM.setValue(_data.CATEGORY_NM);
		form.CATEGORY_NM.setOptions(
			(CATEGORY_NMS || []).map((d) => {
				return { value: d, label: d };
			}),
		);
		form.TITLE.setValue(_data.TITLE);
		form.DESCRIPTION.setValue(_data.DESCRIPTION);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [_data]);

	return { form, onSubmit, onChangeFile };
}
