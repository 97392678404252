import { Modal } from 'components/molecules/Modal';
import { ChangePasswordModalPresenterType } from './types';
import {
	modalInnerStyle,
	changePasswordModalHead,
	changePasswordModalIcon,
	changePasswordWrapper,
	changePasswordModalButton,
} from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShield } from '@fortawesome/free-solid-svg-icons';
import { PrimaryButton } from 'components/molecules/Buttons/Primary';
import { SecondaryButton } from 'components/molecules/Buttons/Secondary';

export function ChangePasswordModalPresenter({
	isVisible,
	onClickNow,
	onClickNext,
}: ChangePasswordModalPresenterType) {
	return (
		<Modal isVisible={isVisible}>
			<div css={modalInnerStyle}>
				<FontAwesomeIcon icon={faShield} css={changePasswordModalIcon} />
				<p css={changePasswordModalHead}>パスワードの変更</p>
				<div css={changePasswordWrapper}>
					<p>パスワードが3ヶ月変更されていません。</p>
					<p>パスワードの変更をご検討ください。</p>
				</div>
				<SecondaryButton css={changePasswordModalButton} onClick={onClickNext}>
					次回変更する
				</SecondaryButton>
				<PrimaryButton css={changePasswordModalButton} onClick={onClickNow}>
					今回変更する
				</PrimaryButton>
			</div>
		</Modal>
	);
}
