import axios, { AxiosError } from 'axios';
import { useUserState } from 'hooks/state/useUserState';
import { useModal } from 'hooks/useModal';
import { refresh } from 'lib/api/users/refresh';
import { isMock } from 'pages/_app';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

export const instance = axios.create({
	baseURL: process.env.API_ENDPOINT,
	timeout: 150000,
});

export function isAxiosError(error: any): error is AxiosError {
	return !!error.isAxiosError;
}

export function AxiosClientProvider({
	children,
}: {
	children: React.ReactElement;
}) {
	const [, setIsVisible] = useModal('sessionFailedModal');
	const [user, setUser] = useUserState();

	useEffect(() => {
		const responseInterceptor = instance.interceptors.response.use(
			async (response) => {
				if (isMock) return response;
				const res = await refresh(user.token);
				setUser({ ...user, token: res.token });
				return response;
			},
			async (error) => {
				if (error.response?.status === 401) {
					setIsVisible(true);
					return;
				}
				if (error.response?.data.detail && error.response?.data.message) {
					toast.error(`${error.response?.data.detail}`);
					toast.error(`${error.response?.data.message}`);
				} else if (error.code === 'ECONNABORTED') {
					toast.error(`タイムアウトしました。時間をおいて再度お試しください`);
				} else {
					toast.error(`システムからエラーが返却されていません`);
					toast.error(`不明なエラーが発生しました`);
				}
				throw error;
			},
		);

		return () => {
			instance.interceptors.response.eject(responseInterceptor);
		};
	});

	return <>{children}</>;
}
