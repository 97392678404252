import { ItemItem } from 'types/api/admins/item';

export const adminItemItem1: ItemItem = {
	ITEM_CD: '12345',
	ITEM_NM: '商品A',
	ITEM_KNM: 'ショウヒンA',
	ITEM_RNM: 'A',
	ITEM_RKNM: 'エー',
	ITEM_LEVEL: '1',
	H_ITEM_NM: '上位品目A',
	PRINT_ORDER: '2',
	SUSPEND_FLG: 0,
};

export const adminItemItem2: ItemItem = {
	ITEM_CD: '67890',
	ITEM_NM: '商品B',
	ITEM_KNM: 'ショウヒンB',
	ITEM_RNM: 'B',
	ITEM_RKNM: 'ビー',
	ITEM_LEVEL: '2',
	H_ITEM_NM: '上位品目B',
	PRINT_ORDER: '1',
	SUSPEND_FLG: 1,
};
