import { AxiosResponse } from 'axios';
import { instance } from 'components/organisms/AxiosClientProvider';
import {
	AdminNewsWebNewBodyType,
	AdminNewsWebParamsType,
	AdminNewsWebResponseType,
	AdminNewsWebUpdateBodyType,
} from 'types/api/admins/newsWeb';

export async function getAdminNewsWeb(
	token: string,
	data: AdminNewsWebParamsType,
) {
	try {
		const response: AxiosResponse<AdminNewsWebResponseType> =
			await instance.get(`${process.env.API_ENDPOINT}/api/v1/admins/news_web`, {
				params: data,
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

		return response.data;
	} catch (e) {}
}

export async function updateNewsWeb(
	token: string,
	id: string,
	body: AdminNewsWebUpdateBodyType,
) {
	try {
		await instance.put(
			`${process.env.API_ENDPOINT}/api/v1/admins/news_web/${id}`,
			{ ...body },
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			},
		);
		return true;
	} catch (e) {
		return false;
	}
}

export async function newNewsWeb(token: string, body: AdminNewsWebNewBodyType) {
	try {
		await instance.post(
			`${process.env.API_ENDPOINT}/api/v1/admins/news_web`,
			{ ...body },
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			},
		);
		return true;
	} catch (e) {
		return false;
	}
}

export async function deleteNewsWeb(token: string, id: string) {
	try {
		await instance.delete(
			`${process.env.API_ENDPOINT}/api/v1/admins/news_web/delete/${id}`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			},
		);
		return true;
	} catch (e) {
		return false;
	}
}
