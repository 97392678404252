import { SaleShowItem } from 'types/api/users/saleShow';

export const saleIndexItems = [
	{
		id: 1,
		purchaseDate: '20220923',
		totalPurchase: 5000,
		salesTaxAmount: 500,
		otherTotal: 1000,
		totalWithTax: 6500,
		totalTransactions: 10,
		isSokuho: true,
	},
	{
		id: 2,
		purchaseDate: '20220922',
		totalPurchase: 5000,
		salesTaxAmount: 500,
		otherTotal: 1000,
		totalWithTax: 6500,
		totalTransactions: 10,
		isSokuho: false,
	},
];

export const saleShowItems: SaleShowItem[] = [
	{
		is_photo_present: true,
		id: 1,
		shipment_provider_name: '出荷者名',
		product_name: '商品名',
		color: '赤',
		specification: '121-100 404-407 005 1-2F 80-120cm',
		remarks: '備考',
		quantity_per_package: 10,
		quantity: 5,
		unit_price: 2599,
		hasu: 2,
		cost: 12995,
		sale_kbn: 'WEB',
		sosu: 10,
		baisan_id: '0000-001',
	},
	{
		is_photo_present: true,
		id: 1,
		shipment_provider_name: '出荷者名',
		product_name: '商品名',
		color: '赤',
		specification: '121-100 404-407 005 1-2F 80-120cm',
		remarks: '備考',
		quantity_per_package: 10,
		quantity: 5,
		unit_price: 2599,
		hasu: 2,
		cost: 12995,
		sale_kbn: '相対',
		sosu: 10,
		baisan_id: '0000-001',
	},
];

export const branchItems = [
	{ branch_name: '山口県中央花市場', branch_number: '001' },
	{ branch_name: '山口県登録者名称', branch_number: '002' },
	{ branch_name: '山口県登録者名称', branch_number: '003' },
	{ branch_name: '山口県登録者名称', branch_number: '004' },
	{ branch_name: '山口県登録者名称', branch_number: '005' },
	{ branch_name: '山口県登録者名称', branch_number: '006' },
];
