import { useModal } from 'hooks/useModal';
import { AdminNewsNewModalPresenter } from './presenter';
import { useAdminNewsNewForm } from 'hooks/forms/useAdminNewsNewForm';
import { useAdminNewsBody } from 'hooks/body/useAdminNewsBody';

export function AdminNewsNew() {
	const [isVisible, setIsVisible, , setModalState] = useModal('adminNewsNew');
	const { data, mutate } = useAdminNewsBody();
	const { form, onSubmit, onChangeFile } = useAdminNewsNewForm(
		data?.CATEGORY_NMS,
	);

	return (
		<AdminNewsNewModalPresenter
			isVisible={isVisible}
			form={form}
			onClose={() => {
				setIsVisible(false);
				setModalState({});
			}}
			onSubmit={async () => {
				await onSubmit();
				mutate();
				setModalState({});
				setIsVisible(false);
			}}
			onChangeFile={onChangeFile}
		/>
	);
}
