import { Modal } from 'components/molecules/Modal';
import { AdminLinkUpdateModalPresenterType } from './types';
import {
	adminLinkUpdateInputText,
	adminLinkUpdateModalCloseButton,
	adminLinkUpdateModalH2,
	adminLinkModalButton,
	modalInnerStyle,
} from './styles';
import { AdminCloseButton } from 'components/molecules/Buttons/AdminClose';
import { DefaultInputText } from 'components/molecules/InputTexts/Default';
import { SecondaryButton } from 'components/molecules/Buttons/Secondary';

export function AdminLinkUpdateModalPresenter({
	form,
	isVisible,
	onClose,
	onSubmit,
}: AdminLinkUpdateModalPresenterType) {
	return (
		<Modal isVisible={isVisible} closeModal={onClose}>
			<div css={modalInnerStyle}>
				<h2 css={adminLinkUpdateModalH2}>リンク集 ー 詳細</h2>
				<div className='mb-[1.5rem] flex items-center'>
					<div className='w-[7rem]'>{form.LINK_CD.placeholder}：</div>
					<DefaultInputText
						css={adminLinkUpdateInputText}
						value={form.LINK_CD.value}
						type='text'
						autoComplete='text'
						onChange={form.LINK_CD.onChange}
					/>
				</div>
				<div className='mb-[1.5rem] flex items-center'>
					<div className='w-[7rem]'>{form.LINK_NM.placeholder}：</div>
					<DefaultInputText
						css={adminLinkUpdateInputText}
						value={form.LINK_NM.value}
						type='text'
						autoComplete='text'
						onChange={form.LINK_NM.onChange}
					/>
				</div>
				<div className='mb-[3rem] flex items-center'>
					<div className='w-[7rem]'>{form.LINK_URL.placeholder}：</div>
					<DefaultInputText
						css={adminLinkUpdateInputText}
						value={form.LINK_URL.value}
						type='text'
						autoComplete='text'
						onChange={form.LINK_URL.onChange}
					/>
				</div>

				<div className='flex justify-center'>
					<SecondaryButton css={adminLinkModalButton} onClick={onClose}>
						キャンセル
					</SecondaryButton>
					<SecondaryButton css={adminLinkModalButton} onClick={onSubmit}>
						保存
					</SecondaryButton>
				</div>

				<AdminCloseButton
					css={adminLinkUpdateModalCloseButton}
					onClick={onClose}
				/>
			</div>
		</Modal>
	);
}
