import { PurchaserItem } from 'types/api/admins/purchaser';

export const purchaserItem: PurchaserItem = {
	BAISAN_CD: 'ABC123',
	BAISAN_EDA: '001',
	BAISAN_EDA_NM: 'テスト買受人',
	BAISAN_EDA_KNM: 'テストカイウケニン',
	BAISAN_EDA_RNM: 'テスト略称',
	ADDR: '東京都渋谷区',
	TEL: '012-345-6789',
	FAX: '987-654-3210',
	ZIP_CD: '123-4567',
	MAIL_ADDR: 'test@example.com',
	SALE_RATE: 1.1,
	SUSPEND_FLG: 0,
	is_admin: true,
};
