import { productsItem, productsItem2 } from 'mocks/constants/admins/products';
import { pagination } from 'mocks/constants/pagenation';
import { MockedRequest, ResponseResolver, restContext } from 'msw';
import {
	ProductsResponseType,
	ProductsSuspensionsResponseType,
} from 'types/api/admins/products';

export const productsIndex: ResponseResolver<
	MockedRequest,
	typeof restContext
> = async (req, res, ctx) => {
	if (true) {
		const response: ProductsResponseType = {
			...pagination,
			datas: [productsItem, productsItem2],
		};
		if (process.env.NODE_ENV == 'development')
			await new Promise((resolve) => setTimeout(resolve, 1000));
		return res(ctx.status(200), ctx.json(response));
	} else {
		return res(ctx.status(401));
	}
};

export const productSuspensions: ResponseResolver<
	MockedRequest,
	typeof restContext
> = async (req, res, ctx) => {
	if (true) {
		const response: ProductsSuspensionsResponseType = {};
		if (process.env.NODE_ENV == 'development')
			await new Promise((resolve) => setTimeout(resolve, 1000));
		return res(ctx.status(200), ctx.json(response));
	} else {
		return res(ctx.status(401));
	}
};

export const uploadImage: ResponseResolver<
	MockedRequest,
	typeof restContext
> = async (req, res, ctx) => {
	if (true) {
		const response: ProductsSuspensionsResponseType = {};
		if (process.env.NODE_ENV == 'development')
			await new Promise((resolve) => setTimeout(resolve, 1000));
		return res(ctx.status(200), ctx.json(response));
	} else {
		return res(ctx.status(401));
	}
};
