import { AxiosResponse } from 'axios';
import { instance } from 'components/organisms/AxiosClientProvider';
import {
	AdminNewsNewBodyType,
	AdminNewsNewResponseType,
	AdminNewsParamsType,
	AdminNewsResponseType,
	AdminNewsUpdateBodyType,
} from 'types/api/admins/news';

export async function getAdminNews(token: string, data: AdminNewsParamsType) {
	try {
		const response: AxiosResponse<AdminNewsResponseType> = await instance.get(
			`${process.env.API_ENDPOINT}/api/v1/admins/news`,
			{
				params: data,
				headers: {
					Authorization: `Bearer ${token}`,
				},
			},
		);

		return response.data;
	} catch (e) {}
}

export async function updateNews(
	token: string,
	id: string,
	body: AdminNewsUpdateBodyType,
) {
	try {
		await instance.put(
			`${process.env.API_ENDPOINT}/api/v1/admins/news/${id}`,
			{ ...body },
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			},
		);
		return true;
	} catch (e) {
		return false;
	}
}

export async function newNews(token: string, body: AdminNewsNewBodyType) {
	try {
		const response: AxiosResponse<AdminNewsNewResponseType> =
			await instance.post(
				`${process.env.API_ENDPOINT}/api/v1/admins/news`,
				{ ...body },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			);
		return response.data;
	} catch (e) {
		return false;
	}
}

export async function deleteNews(token: string, id: string) {
	try {
		await instance.delete(
			`${process.env.API_ENDPOINT}/api/v1/admins/news/delete/${id}`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			},
		);
		return true;
	} catch (e) {
		return false;
	}
}

export async function deleteNewsFile(token: string, id: string) {
	try {
		await instance.delete(
			`${process.env.API_ENDPOINT}/api/v1/admins/news/file/delete/${id}`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			},
		);
		return true;
	} catch (e) {
		return false;
	}
}
