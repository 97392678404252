import { useAdminNewsRecoveryMailCategory } from 'hooks/api/useAdminNewsRecoveryMailCategory';
import { useAdminNewsRecoveryMailBody } from 'hooks/body/useAdminNewsRecoveryMailBody';
import { InputTextType, useInputText } from 'hooks/inputs/useInputText';
import { SelectBoxType, useSelectBox } from 'hooks/inputs/useSelectBox';
import { useUserState } from 'hooks/state/useUserState';
import { useDialog } from 'hooks/useDialog';

import {
	newNewsRecoveryMail,
	updateNewsRecoveryMail,
} from 'lib/api/admins/recoveryNews';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { MailItem } from 'types/api/admins/recoveryMail';

export type AdminNewsRecoveryMailFormType = {
	START_DATE_DATE: InputTextType;
	START_DATE_TIME: InputTextType;
	TITLE: InputTextType;
	DESCRIPTION: InputTextType;
	CATEGORY_NM: SelectBoxType;
};

export function useAdminNewsRecoveryMailUpdateForm(mailItem: MailItem) {
	const [user] = useUserState();
	const [, onOpen] = useDialog();
	const { data } = useAdminNewsRecoveryMailCategory({ per: 10000000, page: 1 });
	const { mutate } = useAdminNewsRecoveryMailBody();

	const START_DATE_DATE = useInputText('', '開始日時', false);
	const START_DATE_TIME = useInputText('', '開始日時', false);
	const TITLE = useInputText('', 'タイトル', false);
	const DESCRIPTION = useInputText('', '内容', false);
	const CATEGORY_NM = useSelectBox('', 'カテゴリー', [
		{ value: '', label: '' },
		...(data?.datas || []).map((d) => {
			return { value: d.CATEGORY_NO.toString(), label: d.CATEGORY };
		}),
	]);

	async function onSubmit() {
		if (!CATEGORY_NM.value || CATEGORY_NM.value === '') {
			toast.error('カテゴリーを選択して下さい');
			return;
		}
		if (!TITLE.value || TITLE.value === '') {
			toast.error('タイトルを入力して下さい');
			return;
		}
		if (!DESCRIPTION.value || DESCRIPTION.value === '') {
			toast.error('内容を入力して下さい');
			return;
		}
		if (!(await onOpen('メールを更新しますか？', ''))) return;
		if (!mailItem) return;

		const response = await updateNewsRecoveryMail(
			user.token,
			mailItem.MAIL_NO.toString(),
			{
				send_date:
					new Date(`${START_DATE_DATE.value} ${START_DATE_TIME.value}`) ||
					new Date(),
				text: DESCRIPTION.value,
				title: TITLE.value,
				category_no: parseInt(CATEGORY_NM.value),
			},
		);

		if (response) {
			toast.success('メールを送信しました');
			mutate();
		}
	}

	const form: AdminNewsRecoveryMailFormType = {
		START_DATE_DATE,
		START_DATE_TIME,
		TITLE,
		DESCRIPTION,
		CATEGORY_NM,
	};

	useEffect(() => {
		CATEGORY_NM.setOptions([
			{ value: '', label: '' },
			...(data?.datas || []).map((d) => {
				return { value: d.CATEGORY_NO.toString(), label: d.CATEGORY };
			}),
		]);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [data]);

	useEffect(() => {
		if (!mailItem) return;
		form.TITLE.setValue(mailItem.MAIL_TITLE);
		form.DESCRIPTION.setValue(mailItem.MAIL_TEXT);
		form.CATEGORY_NM.setValue(mailItem.MAIL_CATEGORY_NO.toString());
		form.START_DATE_DATE.setValue(
			mailItem.MAIL_SEND_START_DATE?.split(' ')[0].replace(/\//g, '-') || '',
		);
		form.START_DATE_TIME.setValue(mailItem.MAIL_SEND_START_DATE?.split(' ')[1]);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [mailItem]);

	return { form, onSubmit };
}
