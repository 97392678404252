import { css } from '@emotion/react';

export const adminCloseButtonWrapper = () => css`
	color: white;
	cursor: pointer;
	background-color: red;
	line-height: 1;
	font-weight: 900;
	width: 40px;
	height: 40px;
	* {
		line-height: 1;
	}
`;
