import { Theme, css } from '@emotion/react';

export const modalInnerStyle = (theme: Theme) => css`
	position: relative;
	width: 500px;
	padding: 50px;
`;

export const adminLinkUpdateModalH2 = (theme: Theme) => css`
	font-weight: 700;
	font-size: 1.25rem;
	line-height: 1.5;
	margin: 0 0 25px;
`;

export const adminLinkUpdateModalCloseButton = (theme: Theme) => css`
	position: absolute;
	right: -10px;
	top: -10px;
	background-color: #333333;
`;

export const adminLinkUpdateInputText = (theme: Theme) => css`
	width: calc(100% - 7rem); // モック: 6.5625rem, fontsize 15px
	appearance: auto;
	padding: 6px;
	border-radius: 5px;
`;

export const adminLinkModalButton = (theme: Theme) => css`
	width: 170px;
	padding-left: 0;
	padding-right: 0;
	margin: 0 10px;
`;
