import { useRouter } from 'next/router';
import { PermissionRouterType } from './types';
import { useUserState } from 'hooks/state/useUserState';
import { authorizationUnnecessaryPathes } from '../LoginRouter';
import { useModal } from 'hooks/useModal';

export function PermissionRouter({ children }: PermissionRouterType) {
	const router = useRouter();
	const [user, setUser] = useUserState();
	const [, setVisible] = useModal('changePasswordModal');

	if (user.requires_password_change) {
		setVisible(true);
	}

	// パスワードの変更が不要の時/change-passwordにアクセスしたらリダイレクトする
	if (router.pathname == '/change-password' && !user.is_first_login) {
		router.push('/');
		return <div></div>;
	}

	if (
		!authorizationUnnecessaryPathes.includes(router.pathname) &&
		router.pathname !== '/change-password' &&
		user.is_first_login
	) {
		router.push('/change-password');
		return <div></div>;
	}

	if (
		!router.pathname.includes('/news/important') &&
		!router.pathname.includes('/change-password') &&
		!router.pathname.includes('/login') &&
		user.news_ids?.length > 0
	) {
		const ids = [...user.news_ids];
		router.push(`/news/important/${ids[0]}`);
		return <div></div>;
	}

	if (router.pathname === '/setting' && user.branch_number !== '001') {
		router.push('/');
		return <div></div>;
	}

	if (router.pathname.includes('/web') && !user.BUY_PERMIT_FLG) {
		router.push('/');
		return <div></div>;
	}

	return <>{children}</>;
}
