import { Modal } from 'components/molecules/Modal';
import { AdminNewsWebNewModalPresenterType } from './types';
import {
	adminNewsWebNewInputText,
	adminNewsWebNewModalCloseButton,
	adminNewsWebNewModalH2,
	adminNewsWebNewDateTextInput,
	modalInnerStyle,
	adminNewsWebNewTimeTextInput,
	adminNewsWebNewTHead,
	adminNewsWebNewTBody,
	adminNewsWebImageModalButton,
} from './styles';
import { AdminCloseButton } from 'components/molecules/Buttons/AdminClose';
import { DefaultInputText } from 'components/molecules/InputTexts/Default';
import { CheckBox } from 'components/atoms/CheckBox';
import { SecondaryButton } from 'components/molecules/Buttons/Secondary';
import { DefaultInputDate } from 'components/molecules/InputDates/Defalut';

export function AdminNewsWebNewModalPresenter({
	form,
	isVisible,
	onClose,
	onSubmit,
}: AdminNewsWebNewModalPresenterType) {
	return (
		<Modal isVisible={isVisible} closeModal={onClose}>
			<div css={modalInnerStyle}>
				<h2 css={adminNewsWebNewModalH2}>テロップ ー 新規追加</h2>
				<div className='mb-[1.5rem] flex'>
					<div className='w-[280px] flex items-center justify-between mr-[2rem]'>
						<div>{form.DATE.placeholder}：</div>
						<DefaultInputDate
							css={adminNewsWebNewInputText}
							value={form.DATE.value}
							type='date'
							autoComplete='date'
							onChange={form.DATE.onChange}
						/>
					</div>
					<div className='w-[280px] flex items-center justify-between mr-[2rem]'>
						<div>{form.PRINT_ORDER.placeholder}：</div>
						<DefaultInputText
							css={adminNewsWebNewInputText}
							value={form.PRINT_ORDER.value}
							type='number'
							autoComplete='text'
							min={1}
							onChange={form.PRINT_ORDER.onChange}
						/>
					</div>
					<div className='flex items-center justify-between'>
						<label className='flex cursor-pointer'>
							<CheckBox
								className='inline-block mr-[0.5rem]'
								checked={form.SUSPEND_FLG.value}
								onChange={form.SUSPEND_FLG.onChange}
							/>
							<div>{form.SUSPEND_FLG.placeholder}</div>
						</label>
					</div>
				</div>
				<div className='flex items-center mb-[1.5rem]'>
					<div className='flex items-center mr-[2rem]'>
						<div className='w-[7rem]'>{form.START_DATE_DATE.placeholder}：</div>
						<DefaultInputDate
							className='mr-[0.5rem]'
							css={adminNewsWebNewDateTextInput}
							value={form.START_DATE_DATE.value}
							type='date'
							autoComplete='date'
							onChange={form.START_DATE_DATE.onChange}
						/>
						<DefaultInputText
							css={adminNewsWebNewTimeTextInput}
							value={form.START_DATE_TIME.value}
							type='time'
							autoComplete='time'
							onChange={form.START_DATE_TIME.onChange}
						/>
					</div>
					<div className='flex items-center'>
						<div className='w-[7rem]'>{form.END_DATE_DATE.placeholder}：</div>
						<DefaultInputDate
							className='mr-[0.5rem]'
							css={adminNewsWebNewDateTextInput}
							value={form.END_DATE_DATE.value}
							type='date'
							autoComplete='date'
							onChange={form.END_DATE_DATE.onChange}
						/>
						<DefaultInputText
							css={adminNewsWebNewTimeTextInput}
							value={form.END_DATE_TIME.value}
							type='time'
							autoComplete='time'
							onChange={form.END_DATE_TIME.onChange}
						/>
					</div>
				</div>
				<div className='flex mb-[2rem]'>
					<div
						className='flex items-center w-[200px]'
						css={adminNewsWebNewTHead}
					>
						{form.TITLE.placeholder}
					</div>
					<div className='w-[calc(100%_-_200px)]' css={adminNewsWebNewTBody}>
						<DefaultInputText
							className='!w-full !border-none'
							css={adminNewsWebNewInputText}
							value={form.TITLE.value}
							type='text'
							autoComplete='text'
							onChange={form.TITLE.onChange}
						/>
					</div>
				</div>
				<div className='flex justify-center'>
					<SecondaryButton css={adminNewsWebImageModalButton} onClick={onClose}>
						キャンセル
					</SecondaryButton>
					<SecondaryButton
						css={adminNewsWebImageModalButton}
						onClick={onSubmit}
					>
						保存
					</SecondaryButton>
				</div>

				<AdminCloseButton
					css={adminNewsWebNewModalCloseButton}
					onClick={onClose}
				/>
			</div>
		</Modal>
	);
}
