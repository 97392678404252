import { Dispatch, SetStateAction, useState } from 'react';

export type InputTextType = {
	value: string;
	setValue: Dispatch<SetStateAction<string>>;
	required: boolean;
	placeholder?: string;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export function useInputText(
	initialValue: string,
	initialPlaceholder: string,
	required: boolean,
): InputTextType {
	const [value, setValue] = useState<string>(initialValue);
	const [placeholder] = useState<string>(initialPlaceholder);

	return {
		value,
		setValue,
		required,
		placeholder,
		onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
			const value = event.target.value;
			setValue(value);
		},
	};
}
