import { Theme, css } from '@emotion/react';
import { maxScreen } from 'styles/mediaQuery';

export const modalInnerStyle = (theme: Theme) => css`
	position: relative;
	width: 1080px;
	max-height: 90vh;
	${maxScreen('mobile')} {
		width: 92vw;
		max-height: 80vh;
		max-height: 90dvh;
	}
`;

export const imageFormWrapper = (theme: Theme) => css`
	max-height: 90vh;
	padding: 50px;
	overflow-y: scroll;
	-ms-overflow-style: none;
	scrollbar-width: none;
	&::-webkit-scrollbar {
		display: none;
	}
	${maxScreen('mobile')} {
		max-height: 80vh;
		max-height: 90dvh;
		padding: 6vw;
	}
`;

export const adminReceivingImageModalH2 = (theme: Theme) => css`
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-weight: 700;
	font-size: 1.25rem;
	line-height: 1.5;
	margin: 0 0 25px;
	button {
		font-weight: normal;
	}
`;

export const adminReceivingImageModalImageWrapper = (theme: Theme) => css`
	position: relative;
	width: calc(50% - 0.5rem);
	min-height: 300px;
	background: ${theme.users.colors.background.gray1};
	margin-bottom: 30px;

	img {
		display: block;
		object-fit: contain;
		position: relative !important;
	}
`;

export const adminReceivingImageModalDropArea = (theme: Theme) => css`
	width: calc(50% - 0.5rem);
	background: ${theme.users.colors.background.gray1};
	margin-bottom: 30px;
`;

export const adminReceivingImageModalButton = (theme: Theme) => css`
	width: 170px;
	padding-left: 0;
	padding-right: 0;
	margin: 0 10px;
`;

export const adminReceivingImageModalCloseButton = (theme: Theme) => css`
	position: absolute;
	right: -15px;
	top: -15px;
	background-color: #333333;
	${maxScreen('mobile')} {
		scale: 0.9;
	}
`;

export const adminReceivingImageFormWrapper = (theme: Theme) => css`
	border-bottom: 1px solid ${theme.users.colors.border.default};
	margin-bottom: 2rem;
`;
