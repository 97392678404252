import { adminReceivingOptions } from 'mocks/constants/admins/receivingOption';
import { MockedRequest, ResponseResolver, restContext } from 'msw';
import { AdminReceivingOptionResponseType } from 'types/api/admins/receivingOption';

export const receivingIndexOption: ResponseResolver<
	MockedRequest,
	typeof restContext
> = async (req, res, ctx) => {
	if (true) {
		const response: AdminReceivingOptionResponseType = {
			...adminReceivingOptions,
		};
		console.log(response);
		if (process.env.NODE_ENV == 'development')
			await new Promise((resolve) => setTimeout(resolve, 1000));
		return res(ctx.status(200), ctx.json(response));
	} else {
		return res(ctx.status(401));
	}
};
