import { useModal } from 'hooks/useModal';
import { AdminProductImageModalPresenter } from './presenter';
import { useState } from 'react';
import { toast } from 'react-toastify';
import {
	putProduct,
	uploadImage,
	deleteProductImage,
} from 'lib/api/admins/product';
import { useAdminProductBody } from 'hooks/body/useAdminProductBody';
import { useUserState } from 'hooks/state/useUserState';
import {
	AdminProductDeletePhotoBodyType,
	ProductUpdateBodyType,
	ProductsItem,
} from 'types/api/admins/products';
import { resizeImage } from 'utils/resizeImage';
import { useDialog } from 'hooks/useDialog';

export function AdminProductImageModal() {
	const [isVisible, setIsVisible, modalState] = useModal('adminProductImage');
	const [base64, setSrc] = useState<string>();
	const [isLoading, setLoading] = useState(false);
	const [user] = useUserState();
	const [file, setFile] = useState<File>();
	const [, onOpen] = useDialog();
	const { mutate } = useAdminProductBody();
	const imageUrl = base64 ? `data:image/png;base64,${base64}` : undefined;
	const data = modalState.data as ProductsItem | undefined;
	const type = modalState.type === 'image1' ? 'image1' : 'image2';
	const upKey = type === 'image1' ? 'image1_put_url' : 'image2_put_url';

	async function onChange(file: File, base64: string) {
		setSrc(base64);
		const { resizedFile } = await resizeImage(file);
		setFile(resizedFile);
	}

	async function onSubmit() {
		if (isLoading || !data) {
			toast.error(isLoading ? '読み込み中です' : 'データが存在しません');
			return;
		}
		if (!file) {
			toast.error('ファイルを選択して下さい');
			return;
		}
		setLoading(true);
		const putBody: ProductUpdateBodyType = {
			image1: data.image1 && data.image1 !== '' ? true : false,
			image2: data.image2 && data.image2 !== '' ? true : false,
			shohin_cd: data.id,
		};
		putBody[type] = true;
		if (
			(await uploadImage(data[upKey], file)) &&
			(await putProduct(user.token, putBody))
		) {
			toast.success('画像を保存しました');
			setLoading(false);
			setIsVisible(false);
			setFile(undefined);
			setSrc(undefined);
			mutate();
			return;
		}
		setLoading(false);
		toast.error('画像の保存に失敗しました');
	}

	async function onDelete() {
		if (isLoading || !data) return;

		setLoading(true);
		if (await onOpen('削除の確認', '本当に削除しますか？')) {
			const deleteParams: AdminProductDeletePhotoBodyType = {
				shohin_cd: data.id,
				photo_no: type === 'image1' ? 1 : 2,
			};
			if (await deleteProductImage(user.token, deleteParams)) {
				toast.success('画像の削除に成功しました。');
				mutate();
				setFile(undefined);
				setSrc(undefined);
				setLoading(false);
				setIsVisible(false);
				return;
			}
		}
		setLoading(false);
		toast.error('画像の削除に失敗しました。');
	}

	if (!data) return <div></div>;
	return (
		<AdminProductImageModalPresenter
			isVisible={isVisible}
			src={base64 ? imageUrl || '' : data[type] || ''}
			onClose={() => {
				setIsVisible(false);
				setFile(undefined);
				setSrc(undefined);
			}}
			onChange={onChange}
			onSubmit={onSubmit}
			onDelete={onDelete}
		/>
	);
}
