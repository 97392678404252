import { useModal } from 'hooks/useModal';
import { AdminLinkNewModalPresenter } from './presenter';
import { useAdminLinkNewForm } from 'hooks/forms/useAdminLinkNewForm';

export function AdminLinkNew() {
	const [isVisible, setIsVisible] = useModal('adminLinkNew');
	const { form, onSubmit } = useAdminLinkNewForm();

	return (
		<AdminLinkNewModalPresenter
			isVisible={isVisible}
			form={form}
			onClose={() => {
				setIsVisible(false);
			}}
			onSubmit={async () => {
				await onSubmit();
				setIsVisible(false);
			}}
		/>
	);
}
