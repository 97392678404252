import { css, Theme } from '@emotion/react';
import { maxScreen } from 'styles/mediaQuery';

export const modalWraper = (
	isVisible: boolean,
	transitionTime: number = 500,
) => css`
	visibility: ${isVisible ? 'visible' : 'hidden'};
	opacity: ${isVisible ? 1 : 0};
	position: fixed;
	width: 100%;
	height: 100vh;
	height: 100dvh;
	z-index: 1000000;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	transition: all ${transitionTime / 1000}s ease-out;
`;

export const modalBgWraper = (theme: Theme) => css`
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.3);
`;

export const modalInner = (theme: Theme) => css`
	position: absolute;
	background-color: ${theme.users.colors.background.default};
	border-radius: 5px;
	top: 50%;
	left: 50%;
	transform: translateY(-50%) translateX(-50%);
	-webkit-transform: translateY(-50%) translateX(-50%);
`;
