import { pagination } from 'mocks/constants/pagenation';
import { MockedRequest, ResponseResolver, restContext } from 'msw';
import { TimeResponseType } from 'types/api/admins/time';

export const timeIndex: ResponseResolver<
	MockedRequest,
	typeof restContext
> = async (req, res, ctx) => {
	if (true) {
		const response: TimeResponseType = {
			START_TIME: '10:00',
			END_TIME: '22:00',
		};
		if (process.env.NODE_ENV == 'development')
			await new Promise((resolve) => setTimeout(resolve, 1000));
		return res(ctx.status(200), ctx.json(response));
	} else {
	}
};
