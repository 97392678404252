import { css } from '@emotion/react';

export const defaultInputDateWrapper = (value: string | number) => css`
	position: relative;
	border: 1px solid #ddd;
	transition: all 0.2s ease;
	background-color: white;
	color: ${value != '' ? 'black' : 'transparent'};
	font-size: 1rem;
	padding: 12px 6px;
	${value === '' &&
	css`
		&:before {
			display: flex;
			align-items: center;
			color: black;
			content: '年/月/日';
			width: calc(100% - 3rem);
			height: 100%;
			top: 0;
			left: 0;
			padding: 0 6px;
			position: absolute;
			background-color: white;
		}
	`}
	&:focus {
		background-color: #f8f8f8;
		&:before {
			background-color: #f8f8f8;
		}
	}
`;
