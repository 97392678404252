import { rest } from 'msw';
import { signin } from './api/v1/users/signin';
import { signout } from './api/v1/users/signout';
import { changePassword } from './api/v1/users/change-password';
import { emailVerify } from './api/v1/users/email/verify';
import { resetPassword } from './api/v1/users/reset-password';
import { web } from './api/v1/users/web';
import { webDetails } from './api/v1/users/webDetail';
import { webBuy } from './api/v1/users/webBuy';
import { webNextOrder } from './api/v1/users/webNextOrder';
import { webNextOrderDone } from './api/v1/users/webNextOrderDone';
import { webBuyDone } from './api/v1/users/webBuyDone';
import { webPhoto } from './api/v1/users/webPhoto';
import { marketIndex } from './api/v1/users/marketIndex';
import { marketDetail } from './api/v1/users/marketDetail';
import { nextReservationIndex } from './api/v1/users/nextReservationIndex';
import { settingIndex, updateSetting } from './api/v1/users/settingIndex';
import { cancellableIndex } from './api/v1/users/cancellable';
import { saleIndex as userSaleIndex } from './api/v1/users/saleIndex';
import { saleShow } from './api/v1/users/saleShow';
import {
	productSuspensions,
	productsIndex,
	uploadImage,
} from './api/v1/admins/products';
import { saleIndex } from './api/v1/admins/sale';
import { mypage } from './api/v1/users/mypage';
import { adminNews, newsCategory, newsWeb } from './api/v1/admins/news';
import { adminNextReservation } from './api/v1/admins/nextReservation';
import { receivingIndex } from './api/v1/admins/receiving';
import { receivingIndexOption } from './api/v1/admins/receivingOption';
import { itemIndex } from './api/v1/admins/item';
import { negotiationsIndex } from './api/v1/admins/negotiations';
import { shipperIndex } from './api/v1/admins/shipper';
import { purchaserIndex } from './api/v1/admins/purchaser';
import { timeIndex } from './api/v1/admins/time';
import { linkIndex } from './api/v1/admins/link';
import { newsDetail, newsIndex } from './api/v1/users/news';
import { accessLogIndex } from './api/v1/admins/accessLog';
import { systemLogIndex } from './api/v1/admins/systemLog';
import { businessIndex } from './api/v1/users/business';
import { systemParameterIndex } from './api/v1/admins/systemParameter';
import { revoveryMailIndex } from './api/v1/admins/recoveryMail';
import { recoveryMailCategory } from './api/v1/admins/recoveryMailCategory';

export const handlers = [
	rest.post(process.env.API_ENDPOINT + '/api/v1/users/signin', signin),
	rest.post(process.env.API_ENDPOINT + '/api/v1/users/signout', signout),
	rest.post(
		process.env.API_ENDPOINT + '/api/v1/users/change-password',
		changePassword,
	),
	rest.post(
		process.env.API_ENDPOINT + '/api/v1/users/reset-password',
		resetPassword,
	),
	rest.post(
		process.env.API_ENDPOINT + '/api/v1/users/email/verify',
		emailVerify,
	),
	rest.get(process.env.API_ENDPOINT + '/api/v1/users/news', newsIndex),
	rest.get(process.env.API_ENDPOINT + '/api/v1/users/news/:id', newsDetail),
	rest.get(process.env.API_ENDPOINT + '/api/v1/users/web', web),
	rest.get(process.env.API_ENDPOINT + '/api/v1/users/web/detail', webDetails),
	rest.post(process.env.API_ENDPOINT + '/api/v1/users/web/buy', webBuy),
	rest.get(process.env.API_ENDPOINT + '/api/v1/users/web/buy/done', webBuyDone),
	rest.post(
		process.env.API_ENDPOINT + '/api/v1/users/web/next-order',
		webNextOrder,
	),
	rest.get(
		process.env.API_ENDPOINT + '/api/v1/users/web/next-order/done',
		webNextOrderDone,
	),
	rest.get(process.env.API_ENDPOINT + '/api/v1/users/web/photo', webPhoto),
	rest.get(process.env.API_ENDPOINT + '/api/v1/users/market', marketIndex),
	rest.get(
		process.env.API_ENDPOINT + '/api/v1/users/market/detail',
		marketDetail,
	),
	rest.get(
		process.env.API_ENDPOINT + '/api/v1/users/next-reservation',
		nextReservationIndex,
	),
	rest.get(process.env.API_ENDPOINT + '/api/v1/users/setting', settingIndex),
	rest.post(process.env.API_ENDPOINT + '/api/v1/users/setting', updateSetting),
	rest.get(
		process.env.API_ENDPOINT + '/api/v1/users/negotiation',
		businessIndex,
	),
	rest.get(
		process.env.API_ENDPOINT + '/api/v1/users/cancellable',
		cancellableIndex,
	),
	rest.post(
		process.env.API_ENDPOINT + '/api/v1/users/cancellable/cancel',
		cancellableIndex,
	),
	rest.get(process.env.API_ENDPOINT + '/api/v1/users/sale', userSaleIndex),
	rest.get(process.env.API_ENDPOINT + '/api/v1/users/sale/detail', saleShow),
	rest.get(process.env.API_ENDPOINT + '/api/v1/users/mypage', mypage),
	rest.get(process.env.API_ENDPOINT + '/api/v1/admins/products', productsIndex),
	rest.post(
		process.env.API_ENDPOINT + '/api/v1/admins/products/suspensions',
		productSuspensions,
	),
	rest.post(
		process.env.API_ENDPOINT + '/api/v1/admins/products/upload-image',
		uploadImage,
	),
	rest.get(process.env.API_ENDPOINT + '/api/v1/admins/sale', saleIndex),
	rest.get(
		process.env.API_ENDPOINT + '/api/v1/admins/news_category',
		newsCategory,
	),
	rest.get(
		process.env.API_ENDPOINT + '/api/v1/admins/reserve',
		adminNextReservation,
	),
	rest.get(
		process.env.API_ENDPOINT + '/api/v1/admins/receiving',
		receivingIndex,
	),
	rest.get(
		process.env.API_ENDPOINT + '/api/v1/admins/receiving/option',
		receivingIndexOption,
	),
	rest.get(
		process.env.API_ENDPOINT + '/api/v1/admins/negotiations',
		negotiationsIndex,
	),
	rest.get(process.env.API_ENDPOINT + '/api/v1/admins/item', itemIndex),
	rest.get(process.env.API_ENDPOINT + '/api/v1/admins/shipper', shipperIndex),
	rest.get(
		process.env.API_ENDPOINT + '/api/v1/admins/purchaser',
		purchaserIndex,
	),
	rest.get(process.env.API_ENDPOINT + '/api/v1/admins/time', timeIndex),
	rest.get(process.env.API_ENDPOINT + '/api/v1/admins/news', adminNews),
	rest.get(process.env.API_ENDPOINT + '/api/v1/admins/link', linkIndex),
	rest.get(process.env.API_ENDPOINT + '/api/v1/admins/news_web', newsWeb),
	rest.get(
		process.env.API_ENDPOINT + '/api/v1/admins/access_log',
		accessLogIndex,
	),
	rest.get(
		process.env.API_ENDPOINT + '/api/v1/admins/system_log',
		systemLogIndex,
	),
	rest.get(
		process.env.API_ENDPOINT + '/api/v1/admins/system_parameter',
		systemParameterIndex,
	),
	rest.get(
		process.env.API_ENDPOINT + '/api/v1/admins/mails',
		revoveryMailIndex,
	),
	rest.get(
		process.env.API_ENDPOINT + '/api/v1/admins/mail/categories',
		recoveryMailCategory,
	),
];
