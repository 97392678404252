import { useModal } from 'hooks/useModal';
import { AdminNewsRecoveryMailUpdateModalPresenter } from './presenter';
import { useAdminNewsRecoveryMailBody } from 'hooks/body/useAdminNewsRecoveryMailBody';
import { useAdminNewsRecoveryMailUpdateForm } from 'hooks/forms/useAdminNewsRecoveryMailUpdateForm';

export function AdminNewsRecoveryMailUpdate() {
	const [isVisible, setIsVisible, modalState, setModalState] = useModal(
		'adminNewsRecoveryMailUpdate',
	);
	const [, setIsCategoryModalVisible] = useModal(
		'adminNewsRecoveryMailCategoryNew',
	);
	const { mutate } = useAdminNewsRecoveryMailBody();
	const { form, onSubmit } = useAdminNewsRecoveryMailUpdateForm(
		modalState.mailItem,
	);

	return (
		<AdminNewsRecoveryMailUpdateModalPresenter
			isVisible={isVisible}
			form={form}
			onClose={() => {
				setIsVisible(false);
				setModalState({});
			}}
			onSubmit={async () => {
				await onSubmit();
				mutate();
				setModalState({});
				setIsVisible(false);
			}}
			onClickCategoryModalOpen={() => {
				setModalState({});
				setIsVisible(false);
				setIsCategoryModalVisible(true);
			}}
		/>
	);
}
