type ResizeResult = {
	resizedFile: File;
	width: number;
	height: number;
};

export const resizeImage = async (file: File): Promise<ResizeResult> => {
	return new Promise<ResizeResult>((resolve, reject) => {
		const img = new Image();
		img.src = URL.createObjectURL(file);

		img.onload = () => {
			const maxWidth = 500;
			const maxHeight = 500;
			let newWidth = img.width;
			let newHeight = img.height;

			if (img.width > maxWidth || img.height > maxHeight) {
				const aspectRatio = img.width / img.height;

				if (aspectRatio > 1) {
					newWidth = maxWidth;
					newHeight = Math.round(maxWidth / aspectRatio);
				} else {
					newHeight = maxHeight;
					newWidth = Math.round(maxHeight * aspectRatio);
				}
			}

			const canvas = document.createElement('canvas');
			canvas.width = newWidth;
			canvas.height = newHeight;

			const ctx = canvas.getContext('2d');
			ctx?.drawImage(img, 0, 0, newWidth, newHeight);

			canvas.toBlob(
				(blob) => {
					if (blob) {
						const resizedFile = new File([blob], file.name, {
							type: file.type,
						});
						resolve({ resizedFile, width: newWidth, height: newHeight });
					} else {
						reject(new Error('Failed to create blob.'));
					}
				},
				file.type,
				0.9,
			);
		};

		img.onerror = () => {
			reject(new Error('Failed to load image.'));
		};
	});
};
