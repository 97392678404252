import { Modal } from 'components/molecules/Modal';
import { SessionFailedModalPresenterType } from './types';
import {
	modalInnerStyle,
	dialogText,
	dialogButtonWrapper,
	dialogTitleText,
} from './styles';
import { PrimaryButton } from 'components/molecules/Buttons/Primary';
import { SecondaryButton } from 'components/molecules/Buttons/Secondary';

export function SessionFailedModalPresenter({
	state,
	setClose,
}: SessionFailedModalPresenterType) {
	return (
		<Modal isVisible={state.isVisible} transitionTime={200}>
			<div css={modalInnerStyle}>
				<p css={dialogTitleText}>{state.title}</p>
				<p
					css={dialogText}
					dangerouslySetInnerHTML={{
						__html: state.text.replaceAll('\n', '<br />'),
					}}
				/>
				<div css={dialogButtonWrapper}>
					<SecondaryButton
						className='w-[120px] !px-0 !py-[0.6rem] mr-[1rem]'
						onClick={() => setClose(false)}
					>
						キャンセル
					</SecondaryButton>
					<PrimaryButton
						className='w-[120px] !px-0 !py-[0.6rem]'
						onClick={() => setClose(true)}
					>
						OK
					</PrimaryButton>
				</div>
			</div>
		</Modal>
	);
}
