import { Theme, css } from '@emotion/react';

export const modalInnerStyle = (theme: Theme) => css`
	position: relative;
	width: 480px;
	padding: 50px 50px 2rem;
`;

export const adminNewsNewModalCloseButton = (theme: Theme) => css`
	position: absolute;
	right: -10px;
	top: -10px;
	background-color: #333333;
`;

export const adminNewsCategoryModalButton = (theme: Theme) => css`
	display: block;
	width: 170px;
	padding-left: 0;
	padding-right: 0;
	margin: 0 10px;
`;

export const adminRecoveryMailCategoryNewInputText = (theme: Theme) => css`
	width: calc(100% - 9rem); // モック: 6.5625rem, fontsize 15px
	appearance: auto;
	padding: 6px;
	border-radius: 5px;
`;
