import { SecondaryButton } from 'components/molecules/Buttons/Secondary';
import { AdminImageModalFormPresenterType } from './types';
import {
	adminImageModalButton,
	adminImageModalDropArea,
	adminImageModalFormWrapper,
	adminImageModalH2,
	adminImageModalImageWrapper,
} from './styles';
import Image from 'next/image';
import { DropZone } from '../DropZone';

export function AdminImageModalFormPresenter({
	src,
	label,
	onChange,
	onSubmit,
	onDelete,
}: AdminImageModalFormPresenterType) {
	return (
		<>
			<h2 css={adminImageModalH2}>
				{label}
				<div>
					<SecondaryButton css={adminImageModalButton} onClick={onSubmit}>
						保存
					</SecondaryButton>
					<SecondaryButton css={adminImageModalButton} onClick={onDelete}>
						削除
					</SecondaryButton>
				</div>
			</h2>
			<div css={adminImageModalFormWrapper}>
				<div css={adminImageModalImageWrapper}>
					{src && src !== '' ? <Image src={src} alt='' fill /> : <div></div>}
				</div>
				<DropZone css={adminImageModalDropArea} onChange={onChange} />
			</div>
		</>
	);
}
