import { adminReceivingItem } from 'mocks/constants/admins/receiving';
import { pagination } from 'mocks/constants/pagenation';
import { MockedRequest, ResponseResolver, restContext } from 'msw';
import { AdminReceivingResponseType } from 'types/api/admins/receiving';

export const receivingIndex: ResponseResolver<
	MockedRequest,
	typeof restContext
> = async (req, res, ctx) => {
	if (true) {
		const response: AdminReceivingResponseType = {
			...pagination,
			datas: [adminReceivingItem],
		};
		if (process.env.NODE_ENV == 'development')
			await new Promise((resolve) => setTimeout(resolve, 1000));
		return res(ctx.status(200), ctx.json(response));
	} else {
		return res(ctx.status(401));
	}
};
