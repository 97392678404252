import {
	DialogState,
	DialogType,
	INITIAL_DIALOG_STATE,
} from 'globalStates/atoms/dialog';
import { useRecoilState } from 'recoil';

type Response = [
	DialogType,
	(title: string, text: string) => Promise<boolean>,
	(result: boolean) => void,
];

export function useDialog(): Response {
	const [state, setIsVisible] = useRecoilState(DialogState);

	const onOpen = (title: string, text: string) => {
		return new Promise<boolean>((r) => {
			setIsVisible({ isVisible: true, title, text, resolve: r });
		});
	};

	const onClose = (result: boolean) => {
		setIsVisible({
			...INITIAL_DIALOG_STATE,
			title: state.title,
			text: state.text,
		});
		if (state.resolve) {
			state.resolve(result);
		}
	};

	return [state, onOpen, onClose];
}
