import { css } from '@emotion/react';

export const defaultSelectBox = () => css`
	width: 100%;
	border-radius: 3px;
	height: 35px;
	border: 1px solid #ddd;
	background-color: #fff;
	font-size: 1rem;
	transition: all 0.2s ease;
	padding: 0 10px;
	cursor: pointer;
`;
