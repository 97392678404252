import { saleItem } from 'mocks/constants/admins/sales';
import { pagination } from 'mocks/constants/pagenation';
import { item_names } from 'mocks/constants/web';
import { MockedRequest, ResponseResolver, restContext } from 'msw';
import { AdminSaleResponseType } from 'types/api/admins/sale';

export const saleIndex: ResponseResolver<
	MockedRequest,
	typeof restContext
> = async (req, res, ctx) => {
	if (true) {
		const response: AdminSaleResponseType = {
			ITEM_NMS: item_names,
			SHOHIN_NMS: ['商品名1', '商品名2'],
			SHUKKASHA_NMS: ['出荷者名1', '出荷者名2'],
			TANTOSHA_NMS: ['担当者名1', '担当者名2'],
			BAISAN_NMS: ['0244001 テスト買受人'],
			SALE_KBNS: [{ 1: 'せり' }, { 2: '注文' }, { 3: '相対' }, { 4: 'WEB' }],
			DATAS: {
				...pagination,
				datas: [saleItem],
			},
		};
		if (process.env.NODE_ENV == 'development')
			await new Promise((resolve) => setTimeout(resolve, 1000));
		return res(ctx.status(200), ctx.json(response));
	} else {
	}
};
