import { MockedRequest, ResponseResolver, restContext } from 'msw';
import { webPhotoItem } from 'mocks/constants/web';
import { WebPhotoResponseType } from 'types/api/users/webPhoto';

export const webPhoto: ResponseResolver<
	MockedRequest,
	typeof restContext
> = async (req, res, ctx) => {
	if (req.headers.get('Authorization')) {
		if (process.env.NODE_ENV == 'development')
			await new Promise((resolve) => setTimeout(resolve, 1000));
		const response: WebPhotoResponseType = webPhotoItem;

		return res(ctx.status(200), ctx.json(response));
	} else {
		return res(ctx.status(401));
	}
};
