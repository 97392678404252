import { AxiosResponse } from 'axios';
import { instance } from 'components/organisms/AxiosClientProvider';
import {
	AdminReceivingParamsType,
	AdminReceivingResponseType,
	AdminReceivingUpdateBodyType,
	AdminReceivingDeletePhotoBodyType,
} from 'types/api/admins/receiving';

export async function getAdminReceiving(
	token: string,
	data: AdminReceivingParamsType,
) {
	try {
		const response: AxiosResponse<AdminReceivingResponseType> =
			await instance.get(
				`${process.env.API_ENDPOINT}/api/v1/admins/receiving`,
				{
					params: data,
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			);

		return response.data;
	} catch (e) {}
}

export async function putReceiving(
	token: string,
	data: AdminReceivingUpdateBodyType,
) {
	try {
		await instance.put(
			`${process.env.API_ENDPOINT}/api/v1/admins/receiving`,
			{ ...data },
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			},
		);
		return true;
	} catch (e) {}
}

export async function deleteReceivingImage(
	token: string,
	data: AdminReceivingDeletePhotoBodyType,
) {
	try {
		await instance.delete(
			`${process.env.API_ENDPOINT}/api/v1/admins/receiving/delete`,
			{
				data: { ...data },
				headers: {
					Authorization: `Bearer ${token}`,
				},
			},
		);
		return true;
	} catch (e) {
		return false;
	}
}
