export const settingItem1 = {
	branch_number: '002',
	branch_name: '山口県中央花市場',
	sell_margin_rate: 0,
	purchase_approval: true,
};

export const settingItem2 = {
	branch_number: '003',
	branch_name: '山口県中央花市場2',
	sell_margin_rate: 10,
	purchase_approval: false,
};
