import { Modal } from 'components/molecules/Modal';
import { NegotiationModalType } from './types';
import {
	modalContentsStyle,
	modalInnerStyle,
	negotiationModalButton,
	negotiationModalCloseButton,
	negotiationModalInputText,
	negotiationModalInputTextInner,
	negotiationModalInputTextWrapper,
	negotiationModalModalH2,
	negotiationModalTBody,
	negotiationModalTHead,
	onlyDesktop,
	onlyMobile,
} from './styles';
import { SecondaryButton } from 'components/molecules/Buttons/Secondary';
import { AdminCloseButton } from 'components/molecules/Buttons/AdminClose';
import { DefaultInputText } from 'components/molecules/InputTexts/Default';
import { CheckBox } from 'components/atoms/CheckBox';
import { webShowHead } from 'components/organisms/Table/Thead/MarketIndex/styles';
import { NegotiationTable } from 'components/organisms/Table/Negotiation';
import { DefaultTextArea } from 'components/molecules/Textareas/Default';
import { NegotiationMobileTable } from 'components/organisms/Table/NegotiationMobile';

export function NegotiationModalPresenter({
	isVisible,
	data,
	form,
	onClose,
	onSubmit,
}: NegotiationModalType) {
	return (
		<Modal isVisible={isVisible}>
			<div css={modalInnerStyle}>
				<div css={modalContentsStyle}>
					<div css={onlyDesktop}>
						{data && <NegotiationTable data={data} />}
					</div>
					<div css={onlyMobile}>
						{data && <NegotiationMobileTable data={data} />}
					</div>
					<h2 css={negotiationModalModalH2}>商談</h2>
					<div css={negotiationModalInputTextWrapper}>
						<div css={negotiationModalInputTextInner}>
							<div>{form.KUCHI_SU.placeholder}：</div>
							<DefaultInputText
								css={negotiationModalInputText}
								value={form.KUCHI_SU.value}
								type='number'
								autoComplete='text'
								min={0}
								onChange={form.KUCHI_SU.onChange}
							/>
						</div>
						<div css={negotiationModalInputTextInner}>
							<div>{form.TANKA.placeholder}：</div>
							<DefaultInputText
								css={negotiationModalInputText}
								value={form.TANKA.value}
								type='number'
								autoComplete='text'
								min={0}
								onChange={form.TANKA.onChange}
							/>
						</div>
					</div>
					<div css={onlyDesktop} className='flex mb-[2rem]'>
						<div
							className='flex items-center w-[200px]'
							css={negotiationModalTHead}
						>
							{form.BIKO.placeholder}
						</div>
						<div className='w-[calc(100%_-_200px)]' css={negotiationModalTBody}>
							<DefaultTextArea
								className='w-full rounded-sm !border-none min-h-[8rem]'
								value={form.BIKO.value}
								onChange={(e) => form.BIKO.setValue(e.target.value)}
							/>
						</div>
					</div>

					<div css={onlyMobile} className='mb-[2rem]'>
						<div className='flex items-center mb-[1rem]'>
							{form.BIKO.placeholder}:
						</div>
						<div css={negotiationModalTBody}>
							<DefaultTextArea
								className='w-full rounded-sm !border-none min-h-[8rem]'
								value={form.BIKO.value}
								onChange={(e) => form.BIKO.setValue(e.target.value)}
							/>
						</div>
					</div>
					<div className='flex justify-center'>
						<SecondaryButton css={negotiationModalButton} onClick={onClose}>
							キャンセル
						</SecondaryButton>
						<SecondaryButton css={negotiationModalButton} onClick={onSubmit}>
							送信
						</SecondaryButton>
					</div>
				</div>
				<AdminCloseButton css={negotiationModalCloseButton} onClick={onClose} />
			</div>
		</Modal>
	);
}
