import { settingItem1, settingItem2 } from 'mocks/constants/setting';
import { MockedRequest, ResponseResolver, restContext } from 'msw';
import { SettingIndexResponseType } from 'types/api/users/setting';

export const settingIndex: ResponseResolver<
	MockedRequest,
	typeof restContext
> = async (req, res, ctx) => {
	if (true) {
		const response: SettingIndexResponseType = {
			datas: [settingItem1, settingItem2],
		};
		if (process.env.NODE_ENV == 'development')
			await new Promise((resolve) => setTimeout(resolve, 1000));
		return res(ctx.status(200), ctx.json(response));
	} else {
		return res(ctx.status(401));
	}
};

export const updateSetting: ResponseResolver<
	MockedRequest,
	typeof restContext
> = async (req, res, ctx) => {
	if (true) {
		if (process.env.NODE_ENV == 'development')
			await new Promise((resolve) => setTimeout(resolve, 1000));
		return res(ctx.status(200));
	} else {
		return res(ctx.status(401));
	}
};
