import { MailCategoryItem } from 'types/api/admins/recoveryMailCategory';

export const adminRecoveryMailCategoryItem1: MailCategoryItem = {
	CATEGORY_NO: 1,
	CATEGORY: 'テストカテゴリー1',
	CREATE_DATE: `${new Date()}`,
	UPDATE_DATE: `${new Date()}`,
};

export const adminRecoveryMailCategoryItem2: MailCategoryItem = {
	CATEGORY_NO: 2,
	CATEGORY: 'テストカテゴリー2',
	CREATE_DATE: `${new Date()}`,
	UPDATE_DATE: `${new Date()}`,
};
