import { Modal } from 'components/molecules/Modal';
import { SessionFailedModalPresenterType } from './types';
import {
	modalInnerStyle,
	sessionFailedModalHead,
	sessionFailedModalIcon,
	sessionFailedWrapper,
} from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { PrimaryButton } from 'components/molecules/Buttons/Primary';

export function SessionFailedModalPresenter({
	isVisible,
	onClickLogout,
}: SessionFailedModalPresenterType) {
	return (
		<Modal isVisible={isVisible}>
			<div css={modalInnerStyle}>
				<FontAwesomeIcon icon={faXmark} css={sessionFailedModalIcon} />
				<p css={sessionFailedModalHead}>セッションタイムアウト</p>
				<div css={sessionFailedWrapper}>
					<p>セッションがタイムアウトしました。</p>
					<p>再度ログインしてください。</p>
				</div>
				<PrimaryButton onClick={onClickLogout}>ログイン</PrimaryButton>
			</div>
		</Modal>
	);
}
