import { adminNegotiationsItems } from 'mocks/constants/admins/negotiations';
import { pagination } from 'mocks/constants/pagenation';
import { item_names } from 'mocks/constants/web';
import { MockedRequest, ResponseResolver, restContext } from 'msw';
import { AdminNegotiationsResponseType } from 'types/api/admins/negotiations';

export const negotiationsIndex: ResponseResolver<
	MockedRequest,
	typeof restContext
> = async (req, res, ctx) => {
	if (true) {
		const response: AdminNegotiationsResponseType = {
			ITEM_NMS: item_names,
			SHOHIN_NMS: ['商品名1', '商品名2'],
			SHUKKASHA_NMS: ['出荷者名1', '出荷者名2'],
			TANTOSHA_NMS: ['担当者名1', '担当者名2'],
			DATAS: {
				...pagination,
				datas: adminNegotiationsItems,
			},
		};
		if (process.env.NODE_ENV == 'development')
			await new Promise((resolve) => setTimeout(resolve, 1000));
		return res(ctx.status(200), ctx.json(response));
	} else {
		return res(ctx.status(401));
	}
};
