import { FC, ReactElement, useEffect, useState } from 'react';

export const PersistGate: FC<{ children: ReactElement[] | ReactElement }> = ({
	children,
}) => {
	const [s, st] = useState(false);

	useEffect(() => {
		const timer = setTimeout(() => {
			st(true);
		}, 1);
		return () => {
			clearTimeout(timer);
		};
	});

	/* recoil presist が localstrageから取得するときに同期処理になり、nextでエラーが出るため0.01秒表示しないように */
	if (typeof window !== 'undefined' && s) {
		return <>{children}</>;
	}
	return <></>;
};
