import { pagination } from 'mocks/constants/pagenation';
import {
	colors,
	item_names,
	shippers,
	varieties,
	webDetailItem1,
	webDetailItem2,
	webDetailItem3,
	webDetailItem4,
	webDetailItem5,
} from 'mocks/constants/web';
import { MockedRequest, ResponseResolver, restContext } from 'msw';
import { WebDetailResponseType } from 'types/api/users/webDetail';
import { getNthDateOptions } from 'utils/date';

export const webDetails: ResponseResolver<
	MockedRequest,
	typeof restContext
> = async (req, res, ctx) => {
	if (true) {
		const response: WebDetailResponseType = {
			item_names: item_names,
			open_hours: ['10時以降', '11時以降'],
			sale_dates: getNthDateOptions(100).map((d) => d.value),
			shippers: shippers,
			varieties: varieties,
			colors: colors,
			is_web_open: true,
			is_recommended: true,
			datas: {
				...pagination,
				datas: [
					webDetailItem1,
					webDetailItem2,
					webDetailItem3,
					webDetailItem4,
					webDetailItem5,
				],
			},
		};
		if (process.env.NODE_ENV == 'development')
			await new Promise((resolve) => setTimeout(resolve, 1000));
		return res(ctx.status(200), ctx.json(response));
	} else {
	}
};
