import { Modal } from 'components/molecules/Modal';
import { AdminNewsRecoveryMailUpdateModalPresenterType } from './types';
import {
	adminNewsNewInputText,
	adminNewsNewModalCloseButton,
	adminNewsNewModalH2,
	modalInnerStyle,
	adminNewsNewTHead,
	adminNewsNewTBody,
	adminNewsImageModalButton,
	adminRecoveryMailNewSelectBox,
} from './styles';
import { AdminCloseButton } from 'components/molecules/Buttons/AdminClose';
import { DefaultInputText } from 'components/molecules/InputTexts/Default';
import { SecondaryButton } from 'components/molecules/Buttons/Secondary';
import { RichTextEditorForm } from 'components/organisms/RichTextEditorForm';
import { DefaultInputDate } from 'components/molecules/InputDates/Defalut';
import { DefaultSelectBox } from 'components/molecules/SelectBoxes/Default';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

export function AdminNewsRecoveryMailUpdateModalPresenter({
	form,
	isVisible,
	onClose,
	onSubmit,
	onClickCategoryModalOpen,
}: AdminNewsRecoveryMailUpdateModalPresenterType) {
	return (
		<Modal isVisible={isVisible} closeModal={onClose}>
			<div css={modalInnerStyle}>
				<h2 css={adminNewsNewModalH2}>一斉メール編集</h2>
				<div className='mb-[1.5rem] flex'>
					<div className='flex items-center mr-[2rem]'>
						<div className='mr-[1rem]'>
							{form.START_DATE_DATE.placeholder}：
						</div>
						<DefaultInputDate
							css={adminNewsNewInputText}
							className='!w-[140px] mr-[0.5rem]'
							value={form.START_DATE_DATE.value}
							type='date'
							autoComplete='date'
							onChange={form.START_DATE_DATE.onChange}
						/>
						<DefaultInputText
							css={adminNewsNewInputText}
							className='!w-[100px] mr-[1rem]'
							value={form.START_DATE_TIME.value}
							type='time'
							autoComplete='time'
							onChange={form.START_DATE_TIME.onChange}
						/>
					</div>
					<div className='flex items-center mr-[2rem] w-[280px]'>
						<div className='mr-[1rem]'>{form.CATEGORY_NM.placeholder}：</div>
						<DefaultSelectBox
							css={adminRecoveryMailNewSelectBox}
							options={form.CATEGORY_NM.options}
							value={form.CATEGORY_NM.value}
							onChange={form.CATEGORY_NM.onChange}
						/>
					</div>
					<div className='flex items-center mr-[2rem]'>
						<SecondaryButton
							className='text-[0.8rem] !px-[1rem]'
							onClick={() => onClickCategoryModalOpen()}
						>
							<span>
								<FontAwesomeIcon className='mr-[0.25rem]' icon={faPlusCircle} />
								カテゴリーを新規追加
							</span>
						</SecondaryButton>
					</div>
				</div>
				<div className='flex mb-[1rem]'>
					<div className='flex items-center w-[200px]' css={adminNewsNewTHead}>
						{form.TITLE.placeholder}
					</div>
					<div className='w-[calc(100%_-_200px)]' css={adminNewsNewTBody}>
						<DefaultInputText
							className='!w-full !border-none'
							css={adminNewsNewInputText}
							value={form.TITLE.value}
							type='text'
							autoComplete='text'
							onChange={form.TITLE.onChange}
						/>
					</div>
				</div>
				<div className='flex mb-[1rem]'>
					<div className='flex items-center w-[200px]' css={adminNewsNewTHead}>
						{form.DESCRIPTION.placeholder}
					</div>
					<div className='w-[calc(100%_-_200px)]'>
						<RichTextEditorForm
							initialFormValue={form.DESCRIPTION.value}
							setFormValue={form.DESCRIPTION.setValue}
						/>
					</div>
				</div>
				<div className='flex justify-center'>
					<SecondaryButton css={adminNewsImageModalButton} onClick={onClose}>
						キャンセル
					</SecondaryButton>
					<SecondaryButton css={adminNewsImageModalButton} onClick={onSubmit}>
						更新
					</SecondaryButton>
				</div>

				<AdminCloseButton
					css={adminNewsNewModalCloseButton}
					onClick={onClose}
				/>
			</div>
		</Modal>
	);
}
