import { Button } from 'components/atoms/Button';
import { AdminCloseButtonType } from './types';
import { adminCloseButtonWrapper } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

export function AdminCloseButton(props: AdminCloseButtonType) {
	return (
		<Button css={adminCloseButtonWrapper} {...props}>
			<FontAwesomeIcon icon={faTimes} />
		</Button>
	);
}
