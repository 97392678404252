import { useAdminNews } from 'hooks/api/useAdminNews';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { AdminNewsParamsType, NewsItemType } from 'types/api/admins/news';

export function generateQueryObject(query: {
	[k: string]: any;
}): AdminNewsParamsType {
	return {
		query: query.query || '',
		include_suspension_rows: query.include_suspension_rows || '0',
		per: Number(query.per) || 50,
		page: Number(query.page) || 1,
	};
}

export const INITIAL_NEWS_ITEM_STATE: NewsItemType = {
	INFORMATION_CD: '',
	DATE: '',
	START_DATE: '',
	END_DATE: '',
	TITLE: '',
	DESCRIPTION: '',
	CATEGORY_NM: '',
	IMPORTANT_FLG: false,
	SUSPEND_FLG: false,
};

export function useAdminNewsBody() {
	const { data, mutate, isLoading, setParams } = useAdminNews();
	const router = useRouter();

	useEffect(() => {
		if (!router) return;
		const query = router.query as { [k: string]: string };

		setParams(generateQueryObject(query));
		/* setParamsを依存関係に持たせないため */
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [router]);

	return { data, isLoading, mutate };
}
