import { AdminShipperItem } from 'types/api/admins/shipper';

export const adminShipperItem1: AdminShipperItem = {
	SHUKKASHA_CD: '0001',
	SHUKKASHA_KBN: 'A',
	SHUKKASHA_NM: '出荷者1',
	SHUKKASHA_KNM: 'シュッカシャ1',
	SHUKKASHA_RNM: 'Shukkasha1',
	SHUKKASHA_KRNM: 'シュッカシャカナ1',
	DAIHYOSHA_NM: '代表者1',
	DAIHYOSHA_KNM: 'ダイヒョウシャ1',
	ADDR: '住所1',
	ZIP_CD: '111-1111',
	TEL: '000-1234-5678',
	FAX: '000-9876-5432',
	SHUKKA_TANTOSHA_NM: '担当者1',
	TANTOSHA_MAIL: 'example1@example.com',
	SUSPEND_FLG: 0,
	PAY_DANTAI_CD: 'P0001',
	PAY_DANTAI_NM: '支払い団体1',
	SANCHI_CD: 'S0001',
	SANCHI_NM: '産地1',
	ITAKU_KAITSUKE_NM: '委託買付1',
	ITAKU_KAITSUKE_CD: 'I0001',
};
