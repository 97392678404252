import { marketDetailItems } from 'mocks/constants/market';
import { pagination } from 'mocks/constants/pagenation';
import { MockedRequest, ResponseResolver, restContext } from 'msw';
import { MarketDetailResponseType } from 'types/api/users/marketDetail';

export const marketDetail: ResponseResolver<
	MockedRequest,
	typeof restContext
> = async (req, res, ctx) => {
	if (true) {
		const response: MarketDetailResponseType = {
			...pagination,
			datas: marketDetailItems,
		};
		if (process.env.NODE_ENV == 'development')
			await new Promise((resolve) => setTimeout(resolve, 1000));
		return res(ctx.status(200), ctx.json(response));
	} else {
	}
};
