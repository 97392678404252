import { useModal } from 'hooks/useModal';
import { AdminNewsRecoveryMailModalPresenter } from './presenter';
import { useAdminNewsRecoveryMailNewForm } from 'hooks/forms/useAdminNewsRecoveryMailNewForm';
import { useAdminNewsRecoveryMailBody } from 'hooks/body/useAdminNewsRecoveryMailBody';

export function AdminNewsRecoveryMailNew() {
	const [isVisible, setIsVisible, , setModalState] = useModal(
		'adminNewsRecoveryMailNew',
	);
	const [, setIsCategoryModalVisible] = useModal(
		'adminNewsRecoveryMailCategoryNew',
	);
	const { mutate } = useAdminNewsRecoveryMailBody();
	const { form, onSubmit } = useAdminNewsRecoveryMailNewForm();

	return (
		<AdminNewsRecoveryMailModalPresenter
			isVisible={isVisible}
			form={form}
			onClose={() => {
				setIsVisible(false);
				setModalState({});
			}}
			onSubmit={async () => {
				await onSubmit();
				mutate();
				setModalState({});
				setIsVisible(false);
			}}
			onClickCategoryModalOpen={() => {
				setModalState({});
				setIsVisible(false);
				setIsCategoryModalVisible(true);
			}}
		/>
	);
}
