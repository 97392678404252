import { Theme, css } from '@emotion/react';
import { maxScreen } from 'styles/mediaQuery';

export const modalInnerStyle = (theme: Theme) => css`
	position: relative;
	width: 960px;
	padding: 50px 50px 2rem;
	${maxScreen('mobile')} {
		width: 92vw;
		padding: 0;
	}
`;

export const modalContentsStyle = css`
	${maxScreen('mobile')} {
		max-height: 75vh;
		padding: 8vw 4vw;
		overflow-y: scroll;
	}
`;

export const negotiationModalModalH2 = (theme: Theme) => css`
	font-weight: 700;
	font-size: 1.25rem;
	line-height: 1.5;
	padding: 2rem 0 1rem;
`;

export const negotiationModalCloseButton = (theme: Theme) => css`
	position: absolute;
	right: -10px;
	top: -10px;
	background-color: #333333;
	${maxScreen('mobile')} {
		scale: 0.75;
		right: -15px;
		top: -20px;
	}
`;

export const negotiationModalButton = (theme: Theme) => css`
	display: block;
	width: 170px;
	padding-left: 0;
	padding-right: 0;
	margin: 0 10px;
`;

export const negotiationModalInputTextWrapper = (theme: Theme) => css`
	display: flex;
	margin-bottom: 1.5rem;
	${maxScreen('mobile')} {
		display: block;
	}
`;

export const negotiationModalInputTextInner = (theme: Theme) => css`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-right: 2rem;
	width: 280px;
	${maxScreen('mobile')} {
		display: flex;
		margin: 0;
		margin-bottom: 1rem;
	}
`;

export const negotiationModalInputText = (theme: Theme) => css`
	width: calc(100% - 7rem); // モック: 6.5625rem, fontsize 15px
	appearance: auto;
	padding: 6px;
	border-radius: 5px;
`;

export const negotiationModalTHead = (theme: Theme) => css`
	background-color: ${theme.users.colors.background.tableHead};
	padding: 10px;
	border: 1px solid ${theme.users.colors.border.default};
	border-right: 0;
`;

export const negotiationModalTBody = (theme: Theme) => css`
	background-color: ${theme.users.colors.background.default};
	padding: 10px;
	border: 1px solid ${theme.users.colors.border.default};
`;

export const onlyMobile = (theme: Theme) => css`
	display: none;
	${maxScreen('mobile')} {
		display: block;
	}
`;

export const onlyDesktop = (theme: Theme) => css`
	display: flex;
	${maxScreen('mobile')} {
		display: none;
	}
`;
