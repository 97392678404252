import axios, { AxiosResponse } from 'axios';
import {
	PasswordUpdateDateRequestBodyType,
	SigninBodyType,
	SigninResponseType,
} from 'types/api/users/signin';

export async function signin(data: SigninBodyType) {
	const response: AxiosResponse<SigninResponseType> = await axios.post(
		`${process.env.API_ENDPOINT}/api/v1/users/signin`,
		{
			...data,
		},
	);
	return response.data;
}

export async function extendsPassword(
	token: string,
	data: PasswordUpdateDateRequestBodyType,
) {
	const response: AxiosResponse = await axios.post(
		`${process.env.API_ENDPOINT}/api/v1/users/extends_password`,
		{ ...data },
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		},
	);
	return response.data;
}
