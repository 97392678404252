import { useAdminNewsWeb } from 'hooks/api/useAdminNewsWeb';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import {
	AdminNewsWebParamsType,
	AdminNewsWebItemType,
} from 'types/api/admins/newsWeb';

export function generateQueryObject(query: {
	[k: string]: any;
}): AdminNewsWebParamsType {
	return {
		query: query.query || '',
		include_suspension_rows: query.include_suspension_rows || '0',
		per: Number(query.per) || 50,
		page: Number(query.page) || 1,
	};
}

export const INITIAL_NEWS_WEB_ITEM_STATE: AdminNewsWebItemType = {
	id: '',
	DATE: '',
	START_DATE: '',
	END_DATE: '',
	TITLE: '',
	PRINT_ORDER: '',
	SUSPEND_FLG: false,
};

export function useAdminNewsWebBody() {
	const { data, mutate, isLoading, setParams } = useAdminNewsWeb();
	const router = useRouter();

	useEffect(() => {
		if (!router) return;
		const query = router.query as { [k: string]: string };

		setParams(generateQueryObject(query));
		/* setParamsを依存関係に持たせないため */
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [router]);

	return { data, isLoading, mutate };
}
