import axios, { AxiosResponse } from 'axios';

export async function refresh(token: string) {
	const response: AxiosResponse<{ token: string }> = await axios.post(
		`${process.env.API_ENDPOINT}/api/v1/users/refresh`,
		{},
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		},
	);
	return response.data;
}
