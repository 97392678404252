import { AxiosResponse } from 'axios';
import { instance } from 'components/organisms/AxiosClientProvider';
import {
	AdminProductDeletePhotoBodyType,
	ProductsParamsType,
	ProductsResponseType,
	ProductsSuspensionsBodyType,
	ProductUpdateBodyType,
} from 'types/api/admins/products';

export async function getAdminProduct(token: string, data: ProductsParamsType) {
	try {
		const response: AxiosResponse<ProductsResponseType> = await instance.get(
			`${process.env.API_ENDPOINT}/api/v1/admins/products`,
			{
				params: data,
				headers: {
					Authorization: `Bearer ${token}`,
				},
			},
		);

		return response.data;
	} catch (e) {}
}

export async function putProduct(token: string, data: ProductUpdateBodyType) {
	try {
		await instance.put(
			`${process.env.API_ENDPOINT}/api/v1/admins/products`,
			{ ...data },
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			},
		);
		return true;
	} catch (e) {}
}

export async function postSuspensions(
	token: string,
	data: ProductsSuspensionsBodyType,
) {
	try {
		await instance.post(
			`${process.env.API_ENDPOINT}/api/v1/admins/products/suspensions`,
			{ ...data },
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			},
		);
		return true;
	} catch (e) {}
}

export async function uploadImage(url: string, file: File) {
	try {
		await instance.put(url, file, {
			headers: {
				'Content-Type': file.type,
			},
		});
		return true;
	} catch (e) {}
}

export async function deleteProductImage(
	token: string,
	data: AdminProductDeletePhotoBodyType,
) {
	try {
		await instance.delete(
			`${process.env.API_ENDPOINT}/api/v1/admins/products/delete`,
			{
				data: { ...data },
				headers: {
					Authorization: `Bearer ${token}`,
				},
			},
		);
		return true;
	} catch (e) {
		return false;
	}
}
