export function getNthDateOptions(n: number) {
	return Array.from({ length: n + 1 }, (_, i) => {
		let date = new Date(Date.now() + i * 24 * 60 * 60 * 1000)
			.toISOString()
			.slice(0, 10)
			.replace(/-/g, '/');
		return { label: date, value: date };
	});
}

export function getListingDateOptions() {
	return [...Array(12)].map((_, index) => {
		const label = `${index + 12}時以降`;
		return { label: label, value: label };
	});
}

export function getLastDayOfMonth(year: number, month: number): Date {
	return new Date(year, month + 1, 0);
}

export function getFirstDayOfMonth(year: number, month: number): Date {
	return new Date(year, month, 1);
}

export function getNextMonthDate(): Date {
	const currentDate = new Date();
	return new Date(currentDate.getFullYear(), currentDate.getMonth() + 1);
}

export function formatYYYYMMDDDate(date: Date): string {
	const year = date.getFullYear().toString();
	const month = (date.getMonth() + 1).toString().padStart(2, '0');
	const day = date.getDate().toString().padStart(2, '0');
	return `${year}-${month}-${day}`;
}

export function YYYYMMDD2Date(dateString: string): Date {
	const year = dateString.slice(0, 4);
	const month = dateString.slice(4, 6);
	const day = dateString.slice(6, 8);
	return new Date(`${year}-${month}-${day}`);
}

export function getYMD(date: Date) {
	const year = date.getFullYear().toString();
	const month = (date.getMonth() + 1).toString().padStart(2, '0');
	const day = date.getDate().toString().padStart(2, '0');
	return { year, month, day };
}

export function getDayOfWeek(date: Date): string {
	const weekdays = ['日', '月', '火', '水', '木', '金', '土'];
	const dayOfWeek = date.getDay();
	return weekdays[dayOfWeek];
}
