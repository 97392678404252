import { defaultTextAreaWrapper } from './styles';

export function DefaultTextArea(props: DefaultTextArea) {
	return (
		<textarea
			{...props}
			css={defaultTextAreaWrapper}
			className={props.className + ' rounded-[8px]'}
		/>
	);
}
