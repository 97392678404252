import { InputDate } from 'components/atoms/InputDate';
import { DefaultInputDateType } from './types';
import { defaultInputDateWrapper } from './styles';

export function DefaultInputDate<T extends string>({
	className = '',
	value,
	...props
}: DefaultInputDateType<T>) {
	return (
		<InputDate
			css={defaultInputDateWrapper(value)}
			value={value}
			className={className + ' rounded-[8px] w-full'}
			{...props}
		/>
	);
}
