export const marketIndexItems = [
	{
		item_name: '菊',
		quantity: 20,
		high_price: 300,
		average: 300,
	},
	{
		item_name: 'カーネーション',
		quantity: 20,
		high_price: 300,
		average: 300,
	},
	{
		item_name: 'ストック',
		quantity: 24,
		high_price: 300,
		average: 300,
	},
	{
		item_name: 'フリージア',
		quantity: 42,
		high_price: 460,
		average: 460,
	},
	{
		item_name: 'トルコ桔梗',
		quantity: 45,
		high_price: 1100,
		average: 661,
	},
];

export const marketDetailItems = [
	{
		variety_name: 'カーネ　白系',
		quantity: 2778,
		high_price: 70,
		average: 45,
	},
	{
		variety_name: 'カーネ　赤系',
		quantity: 1540,
		high_price: 70,
		average: 35,
	},
	{
		variety_name: 'カーネ　黄系',
		quantity: 1500,
		high_price: 70,
		average: 40,
	},
	{
		variety_name: 'カーネ　桃系',
		quantity: 800,
		high_price: 70,
		average: 70,
	},
	{
		variety_name: 'カーネ　紫系',
		quantity: 3400,
		high_price: 70,
		average: 50,
	},
];
