export function convertValuesToString(obj: { [key: string]: any }): {
	[key: string]: string;
} {
	return Object.fromEntries(
		Object.entries(obj).map(([k, v]) => [k, String(v)]),
	);
}

export function getOptions(obj: { [k: string]: string }) {
	return Object.keys(obj).map((key) => {
		return { label: obj[key], value: key };
	});
}

export function fireToBase64(file: File): Promise<string> {
	return new Promise<string>((resolve, reject) => {
		const reader = new FileReader();
		reader.onloadend = () => {
			const base64String = reader.result as string;
			const [, base64Data] = base64String.split(',');
			resolve(base64Data);
		};
		reader.onerror = reject;
		reader.readAsDataURL(file);
	});
}

export function roundedNum(num: string | number) {
	const _num = typeof num === 'string' ? Number(num) : num;
	const roundedNumber = Math.round(_num);
	return roundedNumber.toString();
}

export function roundedCommaNum(num: string | number) {
	const _num = typeof num === 'string' ? Number(num) : num;
	const _roundedNumber = Math.round(_num);
	const commaNumber = _roundedNumber.toLocaleString('ja-JP');
	return commaNumber;
}

export function delay(ms: number): Promise<void> {
	return new Promise((resolve) => {
		setTimeout(resolve, ms);
	});
}
