import { atomFamily } from 'recoil';

export const ModalVisibilityState = atomFamily({
	key: 'ModalVisibilityState',
	default: false,
});

export const ModalState = atomFamily({
	key: 'ModalState',
	default: {},
});
