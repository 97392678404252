import { BusinessIndexItem } from 'types/api/users/business';

export const businessItem: BusinessIndexItem = {
	id: 1,
	is_photo_present: true,
	shipment_provider_name: 'Provider A',
	product_name: 'Product X',
	color: '赤',
	specification: 'Size M',
	quantity_per_package: 10,
	KUCHI_SU: 100,
	OLD_TANKA: 100.0,
	TANKA: 10.0,
	status: '再商談',
	comment: 'コメントがここに表示されますコメントがここに表示されます',
	negotiation_no: '12345678',
};
