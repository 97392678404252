import { nextReservationItem } from 'mocks/constants/next-reservation';
import { pagination } from 'mocks/constants/pagenation';
import { MockedRequest, ResponseResolver, restContext } from 'msw';
import { NextReservationIndexResponseType } from 'types/api/users/nextReservationIndex';

export const nextReservationIndex: ResponseResolver<
	MockedRequest,
	typeof restContext
> = async (req, res, ctx) => {
	if (true) {
		const response: NextReservationIndexResponseType = {
			...pagination,
			datas: [nextReservationItem, nextReservationItem, nextReservationItem],
		};
		if (process.env.NODE_ENV == 'development')
			await new Promise((resolve) => setTimeout(resolve, 1000));
		return res(ctx.status(200), ctx.json(response));
	} else {
		return res(ctx.status(401));
	}
};
