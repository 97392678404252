import { ModalState, ModalVisibilityState } from 'globalStates/atoms/modal';
import { SetterOrUpdater, useRecoilState } from 'recoil';
import { ModalType } from 'types/modal';

type Response = [
	boolean,
	SetterOrUpdater<boolean>,
	{ [k: string]: any },
	SetterOrUpdater<{ [k: string]: any }>,
];

export function useModal(modalType: ModalType): Response {
	const [isVisible, setIsVisible] = useRecoilState(
		ModalVisibilityState(modalType),
	);

	const [modalState, setModalState] = useRecoilState(ModalState(modalType));

	return [isVisible, setIsVisible, modalState, setModalState];
}
