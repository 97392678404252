import { Theme, css } from '@emotion/react';
import { maxScreen } from 'styles/mediaQuery';

export const modalInnerStyle = (theme: Theme) => css`
	position: relative;
	padding: 2rem;
	text-align: center;
	${maxScreen('mobile')} {
		white-space: nowrap;
		padding: 6vw;
	}
`;

export const sessionFailedModalIcon = (theme: Theme) => css`
	font-size: 4rem;
	color: ${theme.users.colors.primary};
	margin-bottom: 0.5rem;
`;

export const sessionFailedWrapper = (theme: Theme) => css`
	margin-bottom: 1rem;
	font-size: 0.8rem;
	${maxScreen('mobile')} {
		margin-bottom: 1rem;
	}
`;

export const sessionFailedModalHead = (theme: Theme) => css`
	font-size: 1rem;
	font-weight: bold;
	margin-bottom: 1rem;
	${maxScreen('mobile')} {
		margin-bottom: 0.5rem;
	}
`;
