import { atom } from 'recoil';

export type DialogType = {
	isVisible: boolean;
	text: string;
	title: string;
	resolve?: (value: boolean | PromiseLike<boolean>) => void;
};

export const INITIAL_DIALOG_STATE: DialogType = {
	isVisible: false,
	text: '',
	title: '',
	resolve: undefined,
};

export const DialogState = atom({
	key: 'Dialog',
	default: INITIAL_DIALOG_STATE,
});
