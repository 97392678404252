import { css } from '@emotion/react';

export const secondaryButtonWrapper = () => css`
	color: #079557;
	border-radius: 5px;
	cursor: pointer;
	background-color: white;
	border: 1px solid #079557;
	padding: 12px 3rem;
	line-height: 1;
	* {
		line-height: 1;
	}
`;
