import { useAdminNewsCategoryBody } from 'hooks/body/useAdminNewsCategoryBody';
import { CheckBoxType, useCheckBox } from 'hooks/inputs/useCheckBox';
import { InputTextType, useInputText } from 'hooks/inputs/useInputText';
import { useUserState } from 'hooks/state/useUserState';
import { newNewsCategory } from 'lib/api/admins/newsCategory';
import { toast } from 'react-toastify';

export type AdminNewsCategoryFormType = {
	SUSPEND_FLG: CheckBoxType;
	order: InputTextType;
	name: InputTextType;
};

export function useAdminNewsCategoryNewForm() {
	const { mutate } = useAdminNewsCategoryBody();
	const [user] = useUserState();
	const SUSPEND_FLG = useCheckBox(false, '休止');
	const order = useInputText('', '表示順', false);
	const name = useInputText('', 'カテゴリー名称', false);

	async function onSubmit() {
		const response = await newNewsCategory(user.token, {
			SUSPEND_FLG: form.SUSPEND_FLG.value,
			order: form.order.value,
			name: name.value,
		});
		mutate();
		if (response) {
			toast.success('お知らせを更新しました。');
			form.SUSPEND_FLG.setValue(false);
			form.name.setValue('');
			form.order.setValue('');
		}
	}

	const form: AdminNewsCategoryFormType = {
		SUSPEND_FLG: {
			...SUSPEND_FLG,
			onChange: async () => {
				form.SUSPEND_FLG.setValue(!form.SUSPEND_FLG.value);
			},
		},
		order,
		name,
	};

	return { form, onSubmit };
}
