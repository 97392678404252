import { modalBgWraper, modalInner, modalWraper } from './styles';
import { ModalPropsType } from './types';

export function Modal({
	closeModal,
	children,
	isVisible,
	transitionTime,
}: ModalPropsType) {
	return (
		<div css={modalWraper(isVisible, transitionTime)}>
			<div css={modalBgWraper} onClick={() => closeModal && closeModal()} />
			<div css={modalInner}>{children}</div>
		</div>
	);
}
