import { useUserState } from 'hooks/state/useUserState';
import { useState } from 'react';
import useSWR from 'swr';
import { getAdminNewsWeb } from 'lib/api/admins/newsWeb';
import { AdminNewsWebParamsType } from 'types/api/admins/newsWeb';

export function useAdminNewsWeb() {
	const [user] = useUserState();
	const [params, setParams] = useState<AdminNewsWebParamsType>();
	const key = `/news_web+${Object.values(params || {})}`;
	const { data, error, isLoading, mutate } = useSWR(key, async () =>
		params ? await getAdminNewsWeb(user.token, params) : undefined,
	);

	return { data, mutate, isLoading, setParams };
}
