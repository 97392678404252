import { AdminNegotiationsItem } from 'types/api/admins/negotiations';

export const adminNegotiationsItems: AdminNegotiationsItem[] = [
	{
		negotiation_no: '00000001',
		SHUKKASHA_NM: '出荷者A',
		BAISAN_CD: '0000000001',
		SHOHIN_NM: '商品A',
		COLOR_NM: '赤',
		specification: 'サイズM',
		BIKO: '備考テキスト',
		IRI_SU: 10,
		KUCHI_SU: 5,
		UNIT_PRICE: 100,
		SHODAN_STATUS: '成立',
		COMMENT: '現在商談中です。少々お待ちください。',
	},
	{
		negotiation_no: '00000002',
		SHUKKASHA_NM: '出荷者A',
		BAISAN_CD: '0000000001',
		SHOHIN_NM: '商品A',
		COLOR_NM: '赤',
		specification: 'サイズM',
		BIKO: '備考テキスト',
		IRI_SU: 10,
		KUCHI_SU: 5,
		UNIT_PRICE: 100,
		SHODAN_STATUS: '商談中',
		COMMENT: '現在商談中です。少々お待ちください。',
	},
	{
		negotiation_no: '00000003',
		SHUKKASHA_NM: '出荷者A',
		BAISAN_CD: '0000000001',
		SHOHIN_NM: '商品A',
		COLOR_NM: '赤',
		specification: 'サイズM',
		BIKO: '備考テキスト',
		IRI_SU: 10,
		KUCHI_SU: 5,
		UNIT_PRICE: 100,
		SHODAN_STATUS: '商談中',
		COMMENT: '現在商談中です。少々お待ちください。',
	},
];
