import { useAdminNewsRecoveryMailCategory } from 'hooks/api/useAdminNewsRecoveryMailCategory';
import { InputTextType, useInputText } from 'hooks/inputs/useInputText';
import { useUserState } from 'hooks/state/useUserState';
import { newNewsRecoveryMailCategory } from 'lib/api/admins/recoveryMailCategory';
import { toast } from 'react-toastify';

export type AdminRecoveryMailCategoryFormType = {
	name: InputTextType;
};

export function useAdminRecoveryMailCategoryNewForm() {
	const { mutate } = useAdminNewsRecoveryMailCategory({
		per: 10000000,
		page: 1,
	});
	const [user] = useUserState();
	const name = useInputText('', 'カテゴリー名称', false);

	async function onSubmit() {
		const response = await newNewsRecoveryMailCategory(user.token, {
			category: name.value,
		});
		mutate();
		if (response) {
			toast.success('カテゴリーを作成しました');
			form.name.setValue('');
		}
	}

	const form: AdminRecoveryMailCategoryFormType = {
		name,
	};

	return { form, onSubmit };
}
