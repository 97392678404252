import { Theme, css } from '@emotion/react';
import { maxScreen } from 'styles/mediaQuery';

export const adminImageModalH2 = (theme: Theme) => css`
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-weight: 700;
	font-size: 1.25rem;
	line-height: 1.5;
	margin: 0 0 25px;
	button {
		font-weight: normal;
	}
	${maxScreen('mobile')} {
		margin: 0 0 1rem;
	}
`;

export const adminImageModalFormWrapper = css`
	display: flex;
	justify-content: space-between;
	${maxScreen('mobile')} {
		display: block;
	}
`;

export const adminImageModalImageWrapper = (theme: Theme) => css`
	position: relative;
	width: calc(50% - 0.5rem);
	min-height: 300px;
	background: ${theme.users.colors.background.gray1};
	margin-bottom: 30px;

	${maxScreen('mobile')} {
		width: 100%;
		min-height: auto;
		aspect-ratio: 16/9;
		margin-bottom: 1rem;
	}

	img {
		display: block;
		object-fit: contain;
		position: relative !important;
	}
`;

export const adminImageModalDropArea = (theme: Theme) => css`
	width: calc(50% - 0.5rem);
	background: ${theme.users.colors.background.gray1};
	margin-bottom: 30px;
	${maxScreen('mobile')} {
		width: 100%;
		min-height: auto;
		aspect-ratio: 16/9;
		margin-bottom: 1rem;
	}
`;

export const adminImageModalButton = (theme: Theme) => css`
	width: 170px;
	padding-left: 0;
	padding-right: 0;
	margin: 0 10px;
	${maxScreen('mobile')} {
		width: 8rem;
		font-size: 1rem;
		height: 2.5rem;
		padding: 0;
		margin: 0;
	}
`;
