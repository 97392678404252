import { useModal } from 'hooks/useModal';
import { AdminNewsWebUpdateModalPresenter } from './presenter';
import { useAdminNewsWebUpdateForm } from 'hooks/forms/useAdminNewsWebUpdateForm';

export function AdminNewsWebUpdate() {
	const [isVisible, setIsVisible, modalState, setModalState] =
		useModal('adminNewsWebUpdate');
	const { form, onSubmit } = useAdminNewsWebUpdateForm(modalState.data);

	return (
		<AdminNewsWebUpdateModalPresenter
			isVisible={isVisible}
			form={form}
			onClose={() => {
				setModalState({});
				setIsVisible(false);
			}}
			onSubmit={async () => {
				await onSubmit();
				setIsVisible(false);
			}}
		/>
	);
}
