import { pagination } from 'mocks/constants/pagenation';
import { MockedRequest, ResponseResolver, restContext } from 'msw';
import { AdminNewsRecoveryMailResponseType } from 'types/api/admins/recoveryMail';
import {
	adminRecoveryMailItem1,
	adminRecoveryMailItem2,
} from 'mocks/constants/admins/recoveryMail';

export const revoveryMailIndex: ResponseResolver<
	MockedRequest,
	typeof restContext
> = async (req, res, ctx) => {
	if (true) {
		const response: AdminNewsRecoveryMailResponseType = {
			datas: [adminRecoveryMailItem1, adminRecoveryMailItem2],
			...pagination,
		};
		if (process.env.NODE_ENV == 'development')
			await new Promise((resolve) => setTimeout(resolve, 1000));
		return res(ctx.status(200), ctx.json(response));
	} else {
	}
};
