import { useModal } from 'hooks/useModal';
import { AdminReceivingImageModalPresenter } from './presenter';
import { toast } from 'react-toastify';
import { putProduct, uploadImage } from 'lib/api/admins/product';
import { useAdminReceivingBody } from 'hooks/body/useAdminReceivingBody';
import { ProductUpdateBodyType } from 'types/api/admins/products';
import {
	AdminReceivingItem,
	AdminReceivingUpdateBodyType,
} from 'types/api/admins/receiving';
import { putReceiving } from 'lib/api/admins/receiving';
import { useUserState } from 'hooks/state/useUserState';

export function AdminReceivingImageModal() {
	const [isVisible, setIsVisible, modalState] = useModal('adminReceivingImage');
	const { mutate } = useAdminReceivingBody();
	const [user] = useUserState();

	async function onSubmit(file: File, id: 0 | 1 | 2 | 3) {
		const uploadUrl = modalState.uploadUrls[id];
		const data = modalState.data as AdminReceivingItem;
		if (uploadUrl && (await uploadImage(uploadUrl, file))) {
			if (id === 0 || id === 1) {
				const putBody: AdminReceivingUpdateBodyType = {
					image1:
						modalState.urls[0] && modalState.urls[0] !== '' ? true : false,
					image2:
						modalState.urls[1] && modalState.urls[1] !== '' ? true : false,
					niuke_dempyo_no: data.NIUKE_DEMPYO_NO,
					niuke_no: data.NIUKE_NO,
				};
				putBody[id === 0 ? 'image1' : 'image2'] = true;
				if (await putReceiving(user.token, putBody)) {
					toast.success('画像を保存しました');
					setIsVisible(false);
					mutate();
					return;
				}
			} else {
				const putBody: ProductUpdateBodyType = {
					image1:
						modalState.urls[2] && modalState.urls[2] !== '' ? true : false,
					image2:
						modalState.urls[3] && modalState.urls[3] !== '' ? true : false,
					shohin_cd: data.SHOHIN_CD,
				};
				putBody[id === 2 ? 'image1' : 'image2'] = true;
				if (await putProduct(user.token, putBody)) {
					toast.success('画像を保存しました');
					setIsVisible(false);
					mutate();
					return;
				}
			}
		}
		toast.error('画像の保存に失敗しました');
	}

	if (
		!modalState.uploadUrls ||
		!modalState.urls ||
		!Array.isArray(modalState.uploadUrls) ||
		!Array.isArray(modalState.urls) ||
		modalState.uploadUrls.length !== 4 ||
		modalState.urls.length !== 4
	) {
		return <></>;
	}

	return (
		<AdminReceivingImageModalPresenter
			data={modalState.data}
			urls={modalState.urls}
			isVisible={isVisible}
			onClose={() => setIsVisible(false)}
			onSubmit={onSubmit}
		/>
	);
}
