import { useUserState } from 'hooks/state/useUserState';
import useSWR from 'swr';
import { AdminNewsRecoveryMailCategoryParamsType } from 'types/api/admins/recoveryMailCategory';
import { getRecoveryMailCategories } from 'lib/api/admins/recoveryMailCategory';

export function useAdminNewsRecoveryMailCategory(
	params: AdminNewsRecoveryMailCategoryParamsType,
) {
	const [user] = useUserState();
	const key = `/mail/categories+${Object.values(params || {})}`;

	const { data, error, isLoading, mutate } = useSWR(key, async () =>
		params ? await getRecoveryMailCategories(user.token, params) : undefined,
	);

	return { data, mutate, isLoading };
}
