import { useUserState } from 'hooks/state/useUserState';
import { useRouter } from 'next/router';
import { LoginRouterType } from './types';
import { PersistGate } from '../PersistGate';

export const authorizationUnnecessaryPathes = [
	'/login',
	'/reset-password',
	'/email/verify',
];

export function LoginRouter({ children }: LoginRouterType) {
	const router = useRouter();

	/* 未ログイン時ルーティング処理 */
	if (!authorizationUnnecessaryPathes.includes(router.pathname)) {
		return (
			<PersistGate>
				<CheckUserGate>
					<CheckAdminGate>{children}</CheckAdminGate>
				</CheckUserGate>
			</PersistGate>
		);
	}

	return <div>{children}</div>;
}

function CheckUserGate({ children }: LoginRouterType) {
	const router = useRouter();
	const [user] = useUserState();

	if (typeof window !== 'undefined' && user.token == '') {
		router.push('/login');
		return <div></div>;
	}

	return <>{children}</>;
}

function CheckAdminGate({ children }: LoginRouterType) {
	const router = useRouter();
	const [user] = useUserState();

	if (router.route.includes('admins') && !user.is_admin) {
		router.push('/');
		return <div></div>;
	}

	return <>{children}</>;
}
