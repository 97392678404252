import { useUserState } from 'hooks/state/useUserState';
import { getAdminLink } from 'lib/api/admins/link';
import { useState } from 'react';
import useSWR from 'swr';
import { LinkParamsType } from 'types/api/admins/link';

export function useAdminLink() {
	const [user] = useUserState();
	const [params, setParams] = useState<LinkParamsType>();
	const key = `/link+${Object.values(params || {})}`;
	const { data, error, isLoading, mutate } = useSWR(key, async () =>
		params ? await getAdminLink(user.token, params) : undefined,
	);

	return { data, isLoading, setParams, mutate };
}
