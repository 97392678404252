import { useModal } from 'hooks/useModal';
import { AdminNewsCategoryUpdateModalPresenter } from './presenter';
import { useAdminNewsCategoryUpdateForm } from 'hooks/forms/useAdminNewsCategoryUpdateForm';

export function AdminNewsCategoryUpdate() {
	const [isVisible, setVisible, modalState, setModalState] = useModal(
		'adminNewsCategoryUpdate',
	);
	const { form, onSubmit } = useAdminNewsCategoryUpdateForm(modalState.data);

	return (
		<AdminNewsCategoryUpdateModalPresenter
			isVisible={isVisible}
			form={form}
			onClose={() => {
				setModalState({});
				setVisible(false);
			}}
			onSubmit={async () => {
				await onSubmit();
				setVisible(false);
			}}
		/>
	);
}
