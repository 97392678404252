import { InputDateType } from './types';

export function InputDate<T extends string | number>({
	className,
	name,
	placeholder,
	type,
	value,
	min,
	max = '2999-12-31',
	required,
	autoComplete,
	disabled,
	pattern,
	readonly,
	autoFocus,
	onChange,
}: InputDateType<T>) {
	return (
		<input
			className={className}
			name={name}
			placeholder={placeholder}
			type={type}
			value={value}
			min={min}
			max={max}
			pattern={pattern}
			required={required}
			disabled={disabled}
			readOnly={readonly}
			autoComplete={autoComplete}
			autoFocus={autoFocus}
			onChange={onChange}
		/>
	);
}
