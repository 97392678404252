import { SelectBoxType } from './types';

export function SelectBox({
	className,
	options,
	value,
	onChange,
}: SelectBoxType) {
	return (
		<select className={className} value={value} onChange={onChange}>
			{options.map((option) => (
				<option key={option.value} value={option.value}>
					{option.label}
				</option>
			))}
		</select>
	);
}
