import { Dispatch, SetStateAction, useState } from 'react';

export type SelectBoxType = {
	value: string;
	setValue: Dispatch<SetStateAction<string>>;
	placeholder?: string;
	options: { value: string; label: string }[];
	setOptions: Dispatch<SetStateAction<{ value: string; label: string }[]>>;
	onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
};

export function useSelectBox(
	initialValue: string,
	initialPlaceholder: string,
	initialOptions: { value: string; label: string }[],
): SelectBoxType {
	const [value, setValue] = useState<string>(initialValue);
	const [placeholder] = useState<string>(initialPlaceholder);
	const [options, setOptions] =
		useState<{ value: string; label: string }[]>(initialOptions);

	return {
		value,
		setValue,
		placeholder,
		options,
		setOptions,
		onChange: (event: React.ChangeEvent<HTMLSelectElement>) => {
			const value = event.target.value;
			setValue(value);
		},
	};
}
