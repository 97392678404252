import { useAdminNewsWebBody } from 'hooks/body/useAdminNewsWebBody';
import { CheckBoxType, useCheckBox } from 'hooks/inputs/useCheckBox';
import { InputTextType, useInputText } from 'hooks/inputs/useInputText';
import { useSelectBox } from 'hooks/inputs/useSelectBox';
import { useUserState } from 'hooks/state/useUserState';
import { newNewsWeb } from 'lib/api/admins/newsWeb';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

export type AdminNewsWebFormType = {
	SUSPEND_FLG: CheckBoxType;
	DATE: InputTextType;
	TITLE: InputTextType;
	START_DATE_DATE: InputTextType;
	START_DATE_TIME: InputTextType;
	END_DATE_DATE: InputTextType;
	END_DATE_TIME: InputTextType;
	PRINT_ORDER: InputTextType;
};

export function useAdminNewsWebNewForm(CATEGORY_NMS?: string[]) {
	const { mutate } = useAdminNewsWebBody();
	const [user] = useUserState();
	const SUSPEND_FLG = useCheckBox(false, '休止');
	const DATE = useInputText('', '日付', false);
	const TITLE = useInputText('', 'タイトル', false);
	const PRINT_ORDER = useInputText('', '優先度', false);
	const START_DATE_DATE = useInputText('', '開始日時', false);
	const START_DATE_TIME = useInputText('', '開始日時', false);
	const END_DATE_DATE = useInputText('', '終了日時', false);
	const END_DATE_TIME = useInputText('', '終了日時', false);

	async function onSubmit() {
		const response = await newNewsWeb(user.token, {
			SUSPEND_FLG: form.SUSPEND_FLG.value,
			PRINT_ORDER: form.PRINT_ORDER.value,
			DATE: DATE.value,
			START_DATE: `${START_DATE_DATE.value} ${START_DATE_TIME.value}`,
			END_DATE: `${END_DATE_DATE.value} ${END_DATE_TIME.value}`,
			TITLE: TITLE.value,
		});
		mutate();
		if (response) {
			toast.success('お知らせを更新しました。');
		}
	}

	const form: AdminNewsWebFormType = {
		SUSPEND_FLG: {
			...SUSPEND_FLG,
			onChange: async () => {
				form.SUSPEND_FLG.setValue(!form.SUSPEND_FLG.value);
			},
		},
		DATE,
		START_DATE_DATE,
		START_DATE_TIME,
		END_DATE_DATE,
		END_DATE_TIME,
		PRINT_ORDER,
		TITLE,
	};

	return { form, onSubmit };
}
