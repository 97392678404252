import { Modal } from 'components/molecules/Modal';
import { AdminNewsUpdateModalPresenterType } from './types';
import {
	adminNewsUpdateInputText,
	adminNewsUpdateModalCloseButton,
	adminNewsUpdateModalH2,
	adminNewsUpdateDateTextInput,
	modalInnerStyle,
	adminNewsUpdateTimeTextInput,
	adminNewsUpdateSelectBox,
	adminNewsUpdateTHead,
	adminNewsUpdateTBody,
	adminNewsImageModalButton,
	minusCircleStyle,
} from './styles';
import { AdminCloseButton } from 'components/molecules/Buttons/AdminClose';
import { DefaultInputText } from 'components/molecules/InputTexts/Default';
import { DefaultSelectBox } from 'components/molecules/SelectBoxes/Default';
import { CheckBox } from 'components/atoms/CheckBox';
import { SecondaryButton } from 'components/molecules/Buttons/Secondary';
import Link from 'next/link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { Oval } from 'react-loader-spinner';
import { useTheme } from '@emotion/react';
import { RichTextEditorForm } from 'components/organisms/RichTextEditorForm';
import { DefaultInputDate } from 'components/molecules/InputDates/Defalut';

export function AdminNewsUpdateModalPresenter({
	form,
	isVisible,
	data,
	isLoading,
	onClose,
	onSubmit,
	onChangeFile,
	_deleteNewsFile,
}: AdminNewsUpdateModalPresenterType) {
	const theme = useTheme();

	return (
		<Modal isVisible={isVisible} closeModal={onClose}>
			<div css={modalInnerStyle}>
				<h2 css={adminNewsUpdateModalH2}>お知らせ ー 詳細</h2>
				<div className='mb-[1.5rem] flex'>
					<div className='w-[280px] flex items-center justify-between mr-[2rem]'>
						<div>{form.DATE.placeholder}：</div>
						<DefaultInputDate
							css={adminNewsUpdateInputText}
							value={form.DATE.value}
							type='date'
							autoComplete='date'
							onChange={form.DATE.onChange}
						/>
					</div>
					<div className='w-[280px] flex items-center justify-between mr-[2rem]'>
						<div>{form.CATEGORY_NM.placeholder}：</div>
						<DefaultSelectBox
							css={adminNewsUpdateSelectBox}
							options={form.CATEGORY_NM.options}
							value={form.CATEGORY_NM.value}
							onChange={form.CATEGORY_NM.onChange}
						/>
					</div>
					<div className='flex items-center justify-between mr-[1rem]'>
						<label className='flex cursor-pointer'>
							<CheckBox
								className='inline-block mr-[0.5rem]'
								checked={form.IMPORTANT_FLG.value}
								onChange={form.IMPORTANT_FLG.onChange}
							/>
							<div>{form.IMPORTANT_FLG.placeholder}</div>
						</label>
					</div>
					<div className='flex items-center justify-between'>
						<label className='flex cursor-pointer'>
							<CheckBox
								className='inline-block mr-[0.5rem]'
								checked={form.SUSPEND_FLG.value}
								onChange={form.SUSPEND_FLG.onChange}
							/>
							<div>{form.SUSPEND_FLG.placeholder}</div>
						</label>
					</div>
				</div>
				<div className='flex items-center mb-[1.5rem]'>
					<div className='flex items-center mr-[2rem]'>
						<div className='w-[7rem]'>{form.START_DATE_DATE.placeholder}：</div>
						<DefaultInputDate
							className='mr-[0.5rem]'
							css={adminNewsUpdateDateTextInput}
							value={form.START_DATE_DATE.value}
							type='date'
							autoComplete='date'
							onChange={form.START_DATE_DATE.onChange}
						/>
						<DefaultInputText
							css={adminNewsUpdateTimeTextInput}
							value={form.START_DATE_TIME.value}
							type='time'
							autoComplete='time'
							onChange={form.START_DATE_TIME.onChange}
						/>
					</div>
					<div className='flex items-center'>
						<div className='w-[7rem]'>{form.END_DATE_DATE.placeholder}：</div>
						<DefaultInputDate
							className='mr-[0.5rem]'
							css={adminNewsUpdateDateTextInput}
							value={form.END_DATE_DATE.value}
							type='date'
							autoComplete='date'
							onChange={form.END_DATE_DATE.onChange}
						/>
						<DefaultInputText
							css={adminNewsUpdateTimeTextInput}
							value={form.END_DATE_TIME.value}
							type='time'
							autoComplete='time'
							onChange={form.END_DATE_TIME.onChange}
						/>
					</div>
				</div>
				<div className='flex mb-[1rem]'>
					<div
						className='flex items-center w-[200px]'
						css={adminNewsUpdateTHead}
					>
						{form.TITLE.placeholder}
					</div>
					<div className='w-[calc(100%_-_200px)]' css={adminNewsUpdateTBody}>
						<DefaultInputText
							className='!w-full !border-none'
							css={adminNewsUpdateInputText}
							value={form.TITLE.value}
							type='text'
							autoComplete='text'
							onChange={form.TITLE.onChange}
						/>
					</div>
				</div>
				<div className='flex mb-[1rem]'>
					<div
						className='flex items-center w-[200px]'
						css={adminNewsUpdateTHead}
					>
						{form.DESCRIPTION.placeholder}
					</div>
					<div className='w-[calc(100%_-_200px)]'>
						<RichTextEditorForm
							initialFormValue={form.DESCRIPTION.value}
							setFormValue={form.DESCRIPTION.setValue}
						/>
					</div>
				</div>
				<div className='flex mb-[2rem]'>
					{data?.FILE_GET_URL ? (
						<div className='flex items-center'>
							<Link
								className='mr-[1rem] underline'
								href={`${data.FILE_GET_URL}`}
								target='_blank'
							>
								ファイルを表示
							</Link>
							<FontAwesomeIcon
								onClick={() => _deleteNewsFile()}
								icon={faMinusCircle}
								css={minusCircleStyle}
							/>
						</div>
					) : (
						<input type='file' onChange={onChangeFile} />
					)}
				</div>
				<div className='flex justify-center'>
					<SecondaryButton css={adminNewsImageModalButton} onClick={onClose}>
						キャンセル
					</SecondaryButton>
					<SecondaryButton css={adminNewsImageModalButton} onClick={onSubmit}>
						{isLoading ? (
							<Oval
								wrapperClass='flex justify-center'
								height={16}
								color={theme.users.colors.primary}
								visible={isLoading}
							/>
						) : (
							'保存'
						)}
					</SecondaryButton>
				</div>

				<AdminCloseButton
					css={adminNewsUpdateModalCloseButton}
					onClick={onClose}
				/>
			</div>
		</Modal>
	);
}
