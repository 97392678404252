import { Theme, css } from '@emotion/react';

export const modalInnerStyle = (theme: Theme) => css`
	position: relative;
	width: 1080px;
	padding: 50px;
`;

export const adminNewsUpdateModalH2 = (theme: Theme) => css`
	font-weight: 700;
	font-size: 1.25rem;
	line-height: 1.5;
	margin: 0 0 25px;
`;

export const adminNewsUpdateModalCloseButton = (theme: Theme) => css`
	position: absolute;
	right: -10px;
	top: -10px;
	background-color: #333333;
`;

export const adminNewsUpdateInputText = (theme: Theme) => css`
	width: calc(100% - 7rem); // モック: 6.5625rem, fontsize 15px
	appearance: auto;
	padding: 6px;
	border-radius: 5px;
`;

export const adminNewsUpdateSelectBox = (theme: Theme) => css`
	width: calc(100% - 7rem); // モック: 6.5625rem, fontsize 15px
	height: auto;
	appearance: auto;
	padding: 6px;
	border-radius: 5px;
`;

export const adminNewsUpdateDateTextInput = (theme: Theme) => css`
	width: 130px;
	appearance: auto;
	padding: 6px;
	border-radius: 5px;
`;

export const adminNewsUpdateTimeTextInput = (theme: Theme) => css`
	width: 90px;
	appearance: auto;
	padding: 6px;
	border-radius: 5px;
`;

export const adminNewsUpdateTHead = (theme: Theme) => css`
	background-color: ${theme.users.colors.background.tableHead};
	padding: 10px;
	border: 1px solid ${theme.users.colors.border.default};
	border-right: 0;
`;

export const adminNewsUpdateTBody = (theme: Theme) => css`
	background-color: ${theme.users.colors.background.default};
	padding: 10px;
	border: 1px solid ${theme.users.colors.border.default};
`;

export const adminNewsImageModalButton = (theme: Theme) => css`
	width: 170px;
	height: 42px;
	padding-left: 0;
	padding-right: 0;
	margin: 0 10px;
`;

export const minusCircleStyle = (theme: Theme) => css`
	color: red;
	font-size: 1.2rem;
	cursor: pointer;
`;
