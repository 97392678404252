import { Modal } from 'components/molecules/Modal';
import { AdminLinkNewModalPresenterType } from './types';
import {
	adminLinkNewInputText,
	adminLinkNewModalCloseButton,
	adminLinkNewModalH2,
	adminLinkModalButton,
	modalInnerStyle,
} from './styles';
import { AdminCloseButton } from 'components/molecules/Buttons/AdminClose';
import { DefaultInputText } from 'components/molecules/InputTexts/Default';
import { SecondaryButton } from 'components/molecules/Buttons/Secondary';
import { CheckBox } from 'components/atoms/CheckBox';

export function AdminLinkNewModalPresenter({
	form,
	isVisible,
	onClose,
	onSubmit,
}: AdminLinkNewModalPresenterType) {
	return (
		<Modal isVisible={isVisible} closeModal={onClose}>
			<div css={modalInnerStyle}>
				<h2 css={adminLinkNewModalH2}>リンク集 ー 新規作成</h2>
				<div className='mb-[1.5rem] flex items-center'>
					<div className='w-[7rem]'>{form.LINK_CD.placeholder}：</div>
					<DefaultInputText
						css={adminLinkNewInputText}
						value={form.LINK_CD.value}
						type='text'
						autoComplete='text'
						onChange={form.LINK_CD.onChange}
					/>
				</div>
				<div className='mb-[1.5rem] flex items-center'>
					<div className='w-[7rem]'>{form.LINK_NM.placeholder}：</div>
					<DefaultInputText
						css={adminLinkNewInputText}
						value={form.LINK_NM.value}
						type='text'
						autoComplete='text'
						onChange={form.LINK_NM.onChange}
					/>
				</div>
				<div className='mb-[1.5rem] flex items-center'>
					<div className='w-[7rem]'>{form.LINK_URL.placeholder}：</div>
					<DefaultInputText
						css={adminLinkNewInputText}
						value={form.LINK_URL.value}
						type='text'
						autoComplete='text'
						onChange={form.LINK_URL.onChange}
					/>
				</div>
				<div className='mb-[1.5rem] flex items-center'>
					<label className='flex cursor-pointer'>
						<CheckBox
							className='inline-block mr-[0.5rem]'
							checked={form.SUSPEND_FLG.value}
							onChange={form.SUSPEND_FLG.onChange}
						/>
						<div>{form.SUSPEND_FLG.placeholder}</div>
					</label>
				</div>

				<div className='flex justify-center'>
					<SecondaryButton css={adminLinkModalButton} onClick={onClose}>
						キャンセル
					</SecondaryButton>
					<SecondaryButton css={adminLinkModalButton} onClick={onSubmit}>
						保存
					</SecondaryButton>
				</div>

				<AdminCloseButton
					css={adminLinkNewModalCloseButton}
					onClick={onClose}
				/>
			</div>
		</Modal>
	);
}
