import { MockedRequest, ResponseResolver, restContext } from 'msw';
import { WebNextOrderDoneResponseType } from 'types/api/users/webNextOrderDone';
import { webNextDoneItem, webNextOrderItem } from 'mocks/constants/web';

export const webNextOrderDone: ResponseResolver<
	MockedRequest,
	typeof restContext
> = async (req, res, ctx) => {
	if (req.headers.get('Authorization')) {
		if (process.env.NODE_ENV == 'development')
			await new Promise((resolve) => setTimeout(resolve, 1000));
		const response: WebNextOrderDoneResponseType = webNextDoneItem;

		return res(ctx.status(200), ctx.json(response));
	} else {
		return res(ctx.status(401));
	}
};
