import { useModal } from 'hooks/useModal';
import { AdminNewsCategoryNewModalPresenter } from './presenter';
import { useAdminNewsCategoryNewForm } from 'hooks/forms/useAdminNewsCategoryNewForm';

export function AdminNewsCategoryNew() {
	const [isVisible, setVisible] = useModal('adminNewsCategoryNew');
	const { form, onSubmit } = useAdminNewsCategoryNewForm();

	return (
		<AdminNewsCategoryNewModalPresenter
			isVisible={isVisible}
			form={form}
			onClose={() => {
				setVisible(false);
			}}
			onSubmit={async () => {
				await onSubmit();
				setVisible(false);
			}}
		/>
	);
}
