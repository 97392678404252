import { Theme, css } from '@emotion/react';
import { maxScreen } from 'styles/mediaQuery';

export const modalInnerStyle = (theme: Theme) => css`
	position: relative;
	padding: 1.5rem;
	${maxScreen('mobile')} {
		width: 92vw;
		white-space: nowrap;
		padding: 4vw;
	}
`;

export const dialogText = (theme: Theme) => css`
	font-size: 1rem;
	margin-bottom: 1.5rem;
	${maxScreen('mobile')} {
		white-space: normal;
		word-wrap: break-word;
		margin-bottom: 4vw;
	}
`;

export const dialogButtonWrapper = (theme: Theme) => css`
	display: flex;
	justify-content: flex-end;
	min-width: 400px;
	${maxScreen('mobile')} {
		min-width: auto;
	}
`;

export const dialogTitleText = (theme: Theme) => css`
	font-weight: bold;
	margin-bottom: 1rem;
	font-size: 1.2rem;
	${maxScreen('mobile')} {
		margin-bottom: 2vw;
	}
`;
