import { v4 as uuidv4 } from 'uuid';

export const cancellableIndexItem1 = {
	transactionUUID: uuidv4(),
	is_photo_present: true,
	id: 1,
	shipment_provider_name: '出荷者名',
	product_name: '商品名',
	color: '赤',
	specification: '121-100 404-407 005 1-2F 80-120cm',
	remarks: '備考',
	quantity_per_package: 10,
	quantity: 5,
	unit_price: 2599,
	hasu: 2,
	cost: 12995,
};
