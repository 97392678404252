import { AxiosResponse } from 'axios';
import { WebBuyBodyType, WebBuyResponseType } from 'types/api/users/webBuy';
import {
	WebBuyDoneParamsType,
	WebBuyDoneResponseType,
} from 'types/api/users/webBuyDone';
import {
	WebDetailParamsType,
	WebDetailResponseType,
} from 'types/api/users/webDetail';
import {
	WebIndexParamsType,
	WebIndexResponseType,
} from 'types/api/users/webIndex';
import {
	WebNextOrderBodyType,
	WebNextOrderResponseType,
} from 'types/api/users/webNextOrder';
import {
	WebNextOrderDoneParamsType,
	WebNextOrderDoneResponseType,
} from 'types/api/users/webNextOrderDone';
import {
	WebPhotoParamsType,
	WebPhotoResponseType,
} from 'types/api/users/webPhoto';
import { instance } from 'components/organisms/AxiosClientProvider';
import { WebNegotiationBodyType } from 'types/api/users/webNegotiation';

export async function getWebIndex(token: string, data: WebIndexParamsType) {
	try {
		const response: AxiosResponse<WebIndexResponseType> = await instance.get(
			`${process.env.API_ENDPOINT}/api/v1/users/web`,
			{
				params: data,
				headers: {
					Authorization: `Bearer ${token}`,
				},
			},
		);
		return response.data;
	} catch (e) {}
}

export async function getWebDetail(token: string, data: WebDetailParamsType) {
	try {
		const response: AxiosResponse<WebDetailResponseType> = await instance.get(
			`${process.env.API_ENDPOINT}/api/v1/users/web/detail`,
			{
				params: data,
				headers: {
					Authorization: `Bearer ${token}`,
				},
			},
		);
		return response.data;
	} catch (e) {}
}

export async function postWebBuy(token: string, data: WebBuyBodyType) {
	try {
		const response: AxiosResponse<WebBuyResponseType> = await instance.post(
			`${process.env.API_ENDPOINT}/api/v1/users/web/buy`,
			{
				...data,
			},
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			},
		);
		return response.data;
	} catch (e) {}
}

export async function postWebNextOrder(
	token: string,
	data: WebNextOrderBodyType,
) {
	try {
		const response: AxiosResponse<WebNextOrderResponseType> =
			await instance.post(
				`${process.env.API_ENDPOINT}/api/v1/users/web/next-order`,
				{
					...data,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			);
		return response.data;
	} catch (e) {}
}

export async function getWebNextOrderDone(
	token: string,
	data: WebNextOrderDoneParamsType,
) {
	try {
		const response: AxiosResponse<WebNextOrderDoneResponseType> =
			await instance.get(
				`${process.env.API_ENDPOINT}/api/v1/users/web/next-order/done`,
				{
					params: data,
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			);
		return response.data;
	} catch (e) {}
}

export async function getBuyOrderDone(
	token: string,
	data: WebBuyDoneParamsType,
) {
	try {
		const response: AxiosResponse<WebBuyDoneResponseType> = await instance.get(
			`${process.env.API_ENDPOINT}/api/v1/users/web/buy/done`,
			{
				params: data,
				headers: {
					Authorization: `Bearer ${token}`,
				},
			},
		);
		return response.data;
	} catch (e) {}
}

export async function getWebPhoto(token: string, data: WebPhotoParamsType) {
	try {
		const response: AxiosResponse<WebPhotoResponseType> = await instance.get(
			`${process.env.API_ENDPOINT}/api/v1/users/web/photo`,
			{
				params: data,
				headers: {
					Authorization: `Bearer ${token}`,
				},
			},
		);
		return response.data;
	} catch (e) {}
}

export async function postWebNegotiation(
	token: string,
	data: WebNegotiationBodyType,
) {
	try {
		const response: AxiosResponse<WebNextOrderResponseType> =
			await instance.post(
				`${process.env.API_ENDPOINT}/api/v1/users/web/negotiation`,
				{
					...data,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			);
		return response.data;
	} catch (e) {}
}
