import { useAdminReceiving } from 'hooks/api/useAdminReceiving';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { AdminReceivingParamsType } from 'types/api/admins/receiving';

export function generateQueryObject(query: {
	[k: string]: any;
}): AdminReceivingParamsType {
	return {
		query: query.query || '',
		SALE_DATE: query.SALE_DATE || '',
		category: query.category || '',
		ITEM_NM: query.ITEM_NM || '',
		SHOHIN_NM: query.SHOHIN_NM || '',
		SHUKKASHA_NM: query.SHUKKASHA_NM || '',
		TANTOSHA_NM: query.TANTOSHA_NM || '',
		per: Number(query.per) || 150,
		page: Number(query.page) || 1,
		order_by: query.order_by || '',
		order: query.order || '',
	};
}

export function useAdminReceivingBody() {
	const { data, mutate, isLoading, setParams } = useAdminReceiving();
	const router = useRouter();

	useEffect(() => {
		if (!router) return;
		const query = router.query as { [k: string]: string };

		setParams(generateQueryObject(query));
		/* setParamsを依存関係に持たせないため */
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [router]);

	return { data, isLoading, mutate, setParams };
}
