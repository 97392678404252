import { FC, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { DropZoneWrapper, noimageWrapper } from './styles';
import { DropZoneType } from './types';
import { fireToBase64 } from 'utils/application';

export const DropZone: FC<DropZoneType> = ({ className, onChange }) => {
	const onDrop = useCallback(
		async (acceptedFiles: File[]) => {
			onChange(acceptedFiles[0], await fireToBase64(acceptedFiles[0]));
		},
		[onChange],
	);

	const { getRootProps, getInputProps } = useDropzone({ onDrop });

	return (
		<div {...getRootProps()} css={DropZoneWrapper} className={className}>
			<input {...getInputProps()} />
			<div css={noimageWrapper}>
				<p>ここにファイルをドロップ</p>
				<p>または</p>
				<p>クリックしてファイルを選択</p>
			</div>
		</div>
	);
};
