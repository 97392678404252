export const mypageItem1 = {
	date: '20230601',
	label: '切花上場',
	color_code: '#e5ffec',
};

export const mypageItem2 = {
	date: '20230623',
	label: '市場休場',
	color_code: '#ffe5e5',
};

export const mypageItem3 = {
	date: '20230630',
	label: '園芸上場',
	color_code: '#e5f0ff',
};
