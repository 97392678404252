import { Theme, css } from '@emotion/react';
import { maxScreen } from 'styles/mediaQuery';

export const webShowTbodyWrapper = (isKanbai: boolean) => css`
	background-color: white;
	${isKanbai &&
	css`
		background-color: #bebebe;
		color: #666;
		cursor: not-allowed;
	`}
`;

export const webShowTbody = (theme: Theme) => css`
	padding: 5px;
	border: 1px solid ${theme.users.colors.border.default};
	font-size: 0.875rem;
	text-align: center;
`;

export const webShowPhotoTbody = (theme: Theme) => css`
	padding: 5px;
	border: 1px solid ${theme.users.colors.border.default};
	font-size: 0.875rem;
	text-align: center;
	background-color: white;
`;

export const webShowTableButton = (theme: Theme) => css`
	padding: 0 15px;
	line-height: 1.8;
	white-space: nowrap;
	min-height: 1.5rem;
	${maxScreen('mobile')} {
		padding: 0 30px;
		line-height: 2.7;
	}
`;

export const webShowTableSelectBox = (theme: Theme) => css`
	width: auto;
	appearance: auto;
`;

export const webShowTbodyInputText = (theme: Theme) => css`
	padding: 6px;
`;

export const webShowTbodyTankaInputText = (theme: Theme) => css`
	padding: 6px;
	margin-right: 0.5rem;
`;

export const discountTag = (theme: Theme) => css`
	display: inline-flex;
	justify-content: center;
	align-items: center;
	background-color: ${theme.users.colors.primary};
	height: 1.5rem;
	padding: 0 0.5rem;
	border-radius: 5px;
	color: white;
	white-space: nowrap;
`;

export const newTag = (theme: Theme) => css`
	width: 50px;
	background-color: ${theme.users.colors.primary};
	height: 1.5rem;
	color: white;
	text-align: center;
	border-radius: 5px;
	margin-bottom: 0.25rem;
`;
