import React, { Dispatch, ReactElement, SetStateAction, useState } from 'react';
import dynamic from 'next/dynamic';
// NOTE: https://github.com/zenoamaro/react-quill/issues/292#issuecomment-1057178885
// NOTE: このライブラリを使用するには、ダイナミックインポートを使ってサーバーサイドレンダリングを無効にする必要があります。
const ReactQuill = dynamic(() => import('react-quill'), { ssr: false });
import 'react-quill/dist/quill.snow.css';

type RichTextEditorFormPropsType = {
	initialFormValue: string;
	setFormValue: Dispatch<SetStateAction<string>>;
};

export function RichTextEditorForm({
	initialFormValue,
	setFormValue,
}: RichTextEditorFormPropsType) {
	const [editorText, setEditorText] = useState(initialFormValue);

	const modules = {
		toolbar: [
			[{ header: [1, 2, false] }],
			['bold'],
			[{ color: ['red', 'blue', 'black', 'gray'] }],
			['clean'],
		],
	};

	const formats = ['header', 'bold', 'color'];

	const handleChange = (html: string) => {
		setEditorText(html);
		setFormValue(html);
	};

	return (
		<>
			<ReactQuill
				theme='snow'
				value={`${initialFormValue}`}
				onChange={handleChange}
				modules={modules}
				formats={formats}
			/>
		</>
	);
}
