import { MockedRequest, ResponseResolver, restContext } from 'msw';

export const changePassword: ResponseResolver<
	MockedRequest,
	typeof restContext
> = async (req, res, ctx) => {
	if (req.headers.get('Authorization')) {
		return res(ctx.status(200));
	} else {
		return res(ctx.status(401));
	}
};
