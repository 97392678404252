import { AxiosResponse } from 'axios';
import { instance } from 'components/organisms/AxiosClientProvider';
import {
	AdminNewsCategoryNewBodyType,
	AdminNewsCategoryParamsType,
	AdminNewsCategoryResponseType,
	AdminNewsCategoryUpdateBodyType,
} from 'types/api/admins/newsCategory';

export async function getNewsCategory(
	token: string,
	data: AdminNewsCategoryParamsType,
) {
	try {
		const response: AxiosResponse<AdminNewsCategoryResponseType> =
			await instance.get(
				`${process.env.API_ENDPOINT}/api/v1/admins/news_category`,
				{
					params: data,
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			);

		return response.data;
	} catch (e) {}
}

export async function newNewsCategory(
	token: string,
	body: AdminNewsCategoryNewBodyType,
) {
	try {
		await instance.post(
			`${process.env.API_ENDPOINT}/api/v1/admins/news_category`,
			{ ...body },
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			},
		);
		return true;
	} catch (e) {
		return false;
	}
}

export async function updateNewsCategory(
	token: string,
	id: string,
	body: AdminNewsCategoryUpdateBodyType,
) {
	try {
		await instance.put(
			`${process.env.API_ENDPOINT}/api/v1/admins/news_category/${id}`,
			{ ...body },
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			},
		);
		return true;
	} catch (e) {
		return false;
	}
}

export async function deleteNewsCategory(token: string, id: string) {
	try {
		await instance.delete(
			`${process.env.API_ENDPOINT}/api/v1/admins/news_category/delete/${id}`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			},
		);
		return true;
	} catch (e) {
		return false;
	}
}
