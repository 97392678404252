import { Modal } from 'components/molecules/Modal';
import { AdminNewsNewModalPresenterType } from './types';
import {
	adminNewsNewInputText,
	adminNewsNewModalCloseButton,
	adminNewsNewModalH2,
	adminNewsNewDateTextInput,
	modalInnerStyle,
	adminNewsNewTimeTextInput,
	adminNewsNewSelectBox,
	adminNewsNewTHead,
	adminNewsNewTBody,
	adminNewsImageModalButton,
} from './styles';
import { AdminCloseButton } from 'components/molecules/Buttons/AdminClose';
import { DefaultInputText } from 'components/molecules/InputTexts/Default';
import { DefaultSelectBox } from 'components/molecules/SelectBoxes/Default';
import { CheckBox } from 'components/atoms/CheckBox';
import { SecondaryButton } from 'components/molecules/Buttons/Secondary';
import { RichTextEditorForm } from 'components/organisms/RichTextEditorForm';
import { DefaultInputDate } from 'components/molecules/InputDates/Defalut';

export function AdminNewsNewModalPresenter({
	form,
	isVisible,
	onClose,
	onSubmit,
	onChangeFile,
}: AdminNewsNewModalPresenterType) {
	return (
		<Modal isVisible={isVisible} closeModal={onClose}>
			<div css={modalInnerStyle}>
				<h2 css={adminNewsNewModalH2}>お知らせ ー 新規追加</h2>
				<div className='mb-[1.5rem] flex'>
					<div className='w-[280px] flex items-center justify-between mr-[2rem]'>
						<div>{form.DATE.placeholder}：</div>
						<DefaultInputDate
							css={adminNewsNewInputText}
							value={form.DATE.value}
							type='date'
							autoComplete='date'
							onChange={form.DATE.onChange}
						/>
					</div>
					<div className='w-[280px] flex items-center justify-between mr-[2rem]'>
						<div>{form.CATEGORY_NM.placeholder}：</div>
						<DefaultSelectBox
							css={adminNewsNewSelectBox}
							options={form.CATEGORY_NM.options}
							value={form.CATEGORY_NM.value}
							onChange={form.CATEGORY_NM.onChange}
						/>
					</div>
					<div className='flex items-center justify-between mr-[1rem]'>
						<label className='flex cursor-pointer'>
							<CheckBox
								className='inline-block mr-[0.5rem]'
								checked={form.IMPORTANT_FLG.value}
								onChange={form.IMPORTANT_FLG.onChange}
							/>
							<div>{form.IMPORTANT_FLG.placeholder}</div>
						</label>
					</div>
					<div className='flex items-center justify-between'>
						<label className='flex cursor-pointer'>
							<CheckBox
								className='inline-block mr-[0.5rem]'
								checked={form.SUSPEND_FLG.value}
								onChange={form.SUSPEND_FLG.onChange}
							/>
							<div>{form.SUSPEND_FLG.placeholder}</div>
						</label>
					</div>
				</div>
				<div className='flex items-center mb-[1.5rem]'>
					<div className='flex items-center mr-[2rem]'>
						<div className='w-[7rem]'>{form.START_DATE_DATE.placeholder}：</div>
						<DefaultInputDate
							className='mr-[0.5rem]'
							css={adminNewsNewDateTextInput}
							value={form.START_DATE_DATE.value}
							type='date'
							autoComplete='date'
							onChange={form.START_DATE_DATE.onChange}
						/>
						<DefaultInputText
							css={adminNewsNewTimeTextInput}
							value={form.START_DATE_TIME.value}
							type='time'
							autoComplete='time'
							onChange={form.START_DATE_TIME.onChange}
						/>
					</div>
					<div className='flex items-center'>
						<div className='w-[7rem]'>{form.END_DATE_DATE.placeholder}：</div>
						<DefaultInputDate
							className='mr-[0.5rem]'
							css={adminNewsNewDateTextInput}
							value={form.END_DATE_DATE.value}
							type='date'
							autoComplete='date'
							onChange={form.END_DATE_DATE.onChange}
						/>
						<DefaultInputText
							css={adminNewsNewTimeTextInput}
							value={form.END_DATE_TIME.value}
							type='time'
							autoComplete='time'
							onChange={form.END_DATE_TIME.onChange}
						/>
					</div>
				</div>
				<div className='flex mb-[1rem]'>
					<div className='flex items-center w-[200px]' css={adminNewsNewTHead}>
						{form.TITLE.placeholder}
					</div>
					<div className='w-[calc(100%_-_200px)]' css={adminNewsNewTBody}>
						<DefaultInputText
							className='!w-full !border-none'
							css={adminNewsNewInputText}
							value={form.TITLE.value}
							type='text'
							autoComplete='text'
							onChange={form.TITLE.onChange}
						/>
					</div>
				</div>
				<div className='flex mb-[1rem]'>
					<div className='flex items-center w-[200px]' css={adminNewsNewTHead}>
						{form.DESCRIPTION.placeholder}
					</div>
					<div className='w-[calc(100%_-_200px)]'>
						<RichTextEditorForm
							initialFormValue={form.DESCRIPTION.value}
							setFormValue={form.DESCRIPTION.setValue}
						/>
					</div>
				</div>
				<div className='flex mb-[2rem]'>
					<input type='file' onChange={onChangeFile} />
				</div>
				<div className='flex justify-center'>
					<SecondaryButton css={adminNewsImageModalButton} onClick={onClose}>
						キャンセル
					</SecondaryButton>
					<SecondaryButton css={adminNewsImageModalButton} onClick={onSubmit}>
						保存
					</SecondaryButton>
				</div>

				<AdminCloseButton
					css={adminNewsNewModalCloseButton}
					onClick={onClose}
				/>
			</div>
		</Modal>
	);
}
