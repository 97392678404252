import { Modal } from 'components/molecules/Modal';
import { AdminProductImageModalPresenterType } from './types';
import {
	adminProductImageModalButton,
	adminProductImageModalCloseButton,
	adminProductImageModalDropArea,
	adminProductImageModalH2,
	adminProductImageModalImageWrapper,
	modalInnerStyle,
} from './styles';
import Image from 'next/image';
import { SecondaryButton } from 'components/molecules/Buttons/Secondary';
import { AdminCloseButton } from 'components/molecules/Buttons/AdminClose';
import { DropZone } from 'components/organisms/DropZone';

export function AdminProductImageModalPresenter({
	isVisible,
	src,
	onClose,
	onChange,
	onSubmit,
	onDelete,
}: AdminProductImageModalPresenterType) {
	return (
		<Modal isVisible={isVisible} closeModal={onClose}>
			<div css={modalInnerStyle}>
				<h2 css={adminProductImageModalH2}>商品写真</h2>
				<div className='flex justify-between'>
					<div css={adminProductImageModalImageWrapper}>
						{src && src !== '' ? <Image src={src} alt='' fill /> : <div></div>}
					</div>
					<DropZone css={adminProductImageModalDropArea} onChange={onChange} />
				</div>
				<div className='flex justify-center'>
					<SecondaryButton css={adminProductImageModalButton} onClick={onClose}>
						キャンセル
					</SecondaryButton>
					<SecondaryButton
						css={adminProductImageModalButton}
						onClick={onSubmit}
					>
						保存
					</SecondaryButton>
					{src && src !== '' ? (
						<SecondaryButton
							css={adminProductImageModalButton}
							onClick={onDelete}
						>
							削除
						</SecondaryButton>
					) : (
						<div></div>
					)}
				</div>
				<AdminCloseButton
					css={adminProductImageModalCloseButton}
					onClick={onClose}
				/>
			</div>
		</Modal>
	);
}
