import { useAdminLink } from 'hooks/api/useAdminLink';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { LinkItemType, LinkParamsType } from 'types/api/admins/link';

export function generateQueryObject(query: {
	[k: string]: any;
}): LinkParamsType {
	return {
		query: query.query || '',
		include_suspension_rows: query.include_suspension_rows || '0',
	};
}

export const INITIAL_LINK_ITEM_STATE: LinkItemType = {
	LINK_NM: '',
	LINK_URL: '',
	LINK_CD: '',
	SUSPEND_FLG: false,
};

export function useAdminLinkBody() {
	const { data, mutate, isLoading, setParams } = useAdminLink();
	const router = useRouter();

	useEffect(() => {
		if (!router) return;
		const query = router.query as { [k: string]: string };

		setParams(generateQueryObject(query));
		/* setParamsを依存関係に持たせないため */
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [router]);

	return { data, isLoading, mutate };
}
