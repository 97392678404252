import { useModal } from 'hooks/useModal';
import { ChangePasswordModalPresenter } from './presenter';
import { useUserState } from 'hooks/state/useUserState';
import { useRouter } from 'next/router';
import { extendsPassword } from 'lib/api/users/signin';

export function ChangePasswordModal() {
	const [isVisible, setVisible] = useModal('changePasswordModal');
	const [user, setUser] = useUserState();
	const router = useRouter();

	function onClickNow() {
		setUser({ ...user, requires_password_change: false });
		setVisible(false);
		router.push('/reset-password');
	}

	async function onClickNext() {
		setUser({ ...user, requires_password_change: false });
		await extendsPassword(user.token, {
			baisan_cd: user.baisan_cd,
			baisan_eda: user.branch_number,
		});
		setVisible(false);
	}

	return (
		<ChangePasswordModalPresenter
			isVisible={isVisible}
			onClickNow={onClickNow}
			onClickNext={onClickNext}
		/>
	);
}
