import { adminItemItem1, adminItemItem2 } from 'mocks/constants/admins/items';
import { pagination } from 'mocks/constants/pagenation';
import { MockedRequest, ResponseResolver, restContext } from 'msw';
import { ItemResponseType } from 'types/api/admins/item';

export const itemIndex: ResponseResolver<
	MockedRequest,
	typeof restContext
> = async (req, res, ctx) => {
	if (true) {
		const response: ItemResponseType = {
			...pagination,
			datas: [adminItemItem1, adminItemItem2],
		};
		if (process.env.NODE_ENV == 'development')
			await new Promise((resolve) => setTimeout(resolve, 1000));
		return res(ctx.status(200), ctx.json(response));
	} else {
	}
};
