import { AdminSystemParameterResponseType } from 'types/api/admins/systemParameter';

export const systemParameterIndexResponse: AdminSystemParameterResponseType = {
	datas: [
		{
			PARAMETER_TYP: 'SALE_SAVE_PERIOD',
			PARAMETER_CD: '1',
			REMARK: '購入取消可能時間 単位(秒)',
			PARAMETER: '120',
		},
		{
			PARAMETER_TYP: 'MAIL_FROM',
			PARAMETER_CD: '1',
			REMARK: '上場時のNewの表示時間 単位(秒)',
			PARAMETER: '400000000',
		},
	],
};
