import { NewsItemType } from 'types/api/admins/news';
import { NewsCategoryItemType } from 'types/api/admins/newsCategory';
import { AdminNewsWebItemType } from 'types/api/admins/newsWeb';
import { NewsIndexItemType } from 'types/api/users/news';

export const newsItem1: NewsIndexItemType = {
	INFORMATION_CD: '1',
	DATE: '2023.06.01',
	TITLE: '台風14号被害における市場への影響について',
	CATEGORY_NM: '市場',
	FILE_URL: '',
	IMPORTANT_FLG: false,
};

export const newsItem2: NewsIndexItemType = {
	INFORMATION_CD: '2',
	DATE: '2023.06.01',
	TITLE: '2022年末～2023年始の市場の営業について',
	CATEGORY_NM: '商品紹介',
	FILE_URL: '',
	IMPORTANT_FLG: false,
};

export const newsItem3: NewsIndexItemType = {
	INFORMATION_CD: '3',
	DATE: '2023.06.01',
	TITLE: 'オンラインショップシステム公開のお知らせ',
	CATEGORY_NM: '市場',
	FILE_URL: '',
	IMPORTANT_FLG: false,
};

export const adminNewsItem1: NewsItemType = {
	INFORMATION_CD: 'UID123456', // UID
	DATE: '2023/07/28', // 日付
	START_DATE: '2023/07/27 10:00', // 開始日時
	END_DATE: '2023/07/28 13:00', // 終了日時
	TITLE: 'テストのタイトル', // タイトル
	DESCRIPTION: 'これはテストの内容です。テストデータの生成に使用されています。', // 内容
	CATEGORY_NM: 'テストカテゴリー', // カテゴリー名
	IMPORTANT_FLG: true, // 重要フラグ
	SUSPEND_FLG: false, // 休止フラグ
};

export const newsCategoryItem1: NewsCategoryItemType = {
	id: 1,
	name: '市場',
	order: '1',
	SUSPEND_FLG: true,
};

export const newsCategoryItem2: NewsCategoryItemType = {
	id: 2,
	name: '商品紹介',
	order: '1',
	SUSPEND_FLG: false,
};

export const newsWebItem: AdminNewsWebItemType = {
	id: '',
	DATE: '2023/07/28', // 日付
	START_DATE: '2023/07/27 10:00', // 開始日時
	END_DATE: '2023/07/28 13:00', // 終了日時
	TITLE: 'テストのタイトル', // タイトル
	SUSPEND_FLG: false, // 休止フラグ
	PRINT_ORDER: '1',
};
