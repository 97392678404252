import { useAdminNewsRecoveryMail } from 'hooks/api/useAdminNewsRecoveryMail';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { AdminNewsCategoryParamsType } from 'types/api/admins/newsCategory';

export function generateQueryObject(query: {
	[k: string]: any;
}): AdminNewsCategoryParamsType {
	return {
		query: query.query || '',
		include_suspension_rows: query.include_suspension_rows || '0',
		per: Number(query.per) || 50,
		page: Number(query.page) || 1,
	};
}

export function useAdminNewsRecoveryMailBody() {
	const { data, mutate, isLoading, setParams } = useAdminNewsRecoveryMail();
	const router = useRouter();

	useEffect(() => {
		if (!router) return;
		const query = router.query as { [k: string]: string };

		setParams(generateQueryObject(query));
		/* setParamsを依存関係に持たせないため */
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [router]);

	return { data, isLoading, mutate };
}
