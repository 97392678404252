import { useRouter } from 'next/router';
import { AdminProductImageModal } from '../Modal/AdminProductImage';
import { SessionFailedModal } from '../Modal/SessionFailed';
import { ChangePasswordModal } from '../Modal/ChangePassword';
import { AdminReceivingImageModal } from '../Modal/AdminReceivingImage';
import { Dialog } from '../Dialog';
import { AdminNewsUpdate } from '../Modal/AdminNewsUpdate';
import { AdminNewsNew } from '../Modal/AdminNewsNew';
import { AdminLinkUpdate } from '../Modal/AdminLinkUpdate';
import { AdminLinkNew } from '../Modal/AdminLinkNew';
import { AdminNewsCategoryUpdate } from '../Modal/AdminNewsCategoryUpdate';
import { AdminNewsCategoryNew } from '../Modal/AdminNewsCategoryNew';
import { AdminNewsWebNew } from '../Modal/AdminNewsWebNew';
import { AdminNewsWebUpdate } from '../Modal/AdminNewsWebUpdate';
import { Negotiation } from '../Modal/Negotiation';
import { AdminNewsRecoveryMailNew } from '../Modal/AdminNewsRecoveryMailNew';
import { AdminRecoveryMailCategoryNew } from '../Modal/AdminRecoveryMailCategoryNew';
import { AdminNewsRecoveryMailUpdate } from '../Modal/AdminNewsRecoveryMailUpdate';

export function ModalLayer() {
	const router = useRouter();

	return (
		<>
			{router.pathname === '/admins/master/products' && (
				<AdminProductImageModal />
			)}
			{router.pathname === '/admins/transaction/receiving' && (
				<AdminReceivingImageModal />
			)}
			{router.pathname === '/admins/master/link' && (
				<>
					<AdminLinkUpdate />
					<AdminLinkNew />
				</>
			)}
			{router.pathname.includes('/admins/news') && (
				<>
					<AdminNewsUpdate />
					<AdminNewsNew />
				</>
			)}
			{router.pathname.includes('/admins/news/web') && (
				<>
					<AdminNewsWebUpdate />
					<AdminNewsWebNew />
				</>
			)}
			{router.pathname.includes('/admins/news/recovery_mail') && (
				<>
					<AdminNewsRecoveryMailNew />
					<AdminRecoveryMailCategoryNew />
					<AdminNewsRecoveryMailUpdate />
				</>
			)}

			{router.pathname.includes('/admins/news/category') && (
				<>
					<AdminNewsCategoryUpdate />
					<AdminNewsCategoryNew />
				</>
			)}
			{router.pathname.includes('/web') && <Negotiation />}
			<SessionFailedModal />
			<ChangePasswordModal />
			<Dialog />
		</>
	);
}
