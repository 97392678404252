import { css, Theme } from '@emotion/react';

export const DropZoneWrapper = (theme: Theme) => css`
	position: relative;
	width: 100%;
	border: #ddd dashed 5px;
	cursor: pointer;
`;

export const noimageWrapper = (theme: Theme) => css`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	color: #999;
	font-size: 1.25rem;
	p {
		margin: 0 0 0.5em;
	}
`;
