import { AxiosResponse } from 'axios';
import { instance } from 'components/organisms/AxiosClientProvider';
import {
	AdminLinkNewBodyType,
	AdminLinkUpdateBodyType,
	LinkParamsType,
	LinkResponseType,
} from 'types/api/admins/link';

export async function getAdminLink(token: string, data: LinkParamsType) {
	try {
		const response: AxiosResponse<LinkResponseType> = await instance.get(
			`${process.env.API_ENDPOINT}/api/v1/admins/link`,
			{
				params: data,
				headers: {
					Authorization: `Bearer ${token}`,
				},
			},
		);

		return response.data;
	} catch (e) {}
}

export async function updateLink(
	token: string,
	id: string,
	body: AdminLinkUpdateBodyType,
) {
	try {
		await instance.put(
			`${process.env.API_ENDPOINT}/api/v1/admins/link/${id}`,
			{ ...body },
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			},
		);
		return true;
	} catch (e) {
		return false;
	}
}

export async function newLink(token: string, body: AdminLinkNewBodyType) {
	try {
		await instance.post(
			`${process.env.API_ENDPOINT}/api/v1/admins/link`,
			{ ...body },
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			},
		);
		return true;
	} catch (e) {
		return false;
	}
}

export async function deleteLink(token: string, id: string) {
	try {
		await instance.delete(
			`${process.env.API_ENDPOINT}/api/v1/admins/link/delete/${id}`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			},
		);
		return true;
	} catch (e) {
		return false;
	}
}
