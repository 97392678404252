import { css } from '@emotion/react';

export const defaultInputTextWrapper = () => css`
	border: 1px solid #ddd;
	transition: all 0.2s ease;
	background-color: white;
	font-size: 1rem;
	padding: 12px 6px;
	&:focus {
		background-color: #f8f8f8;
	}
`;
