import { pagination } from 'mocks/constants/pagenation';
import {
	branchItems,
	saleIndexItems,
	saleShowItems,
} from 'mocks/constants/sale';
import { MockedRequest, ResponseResolver, restContext } from 'msw';
import { SaleShowResponseType } from 'types/api/users/saleShow';

export const saleShow: ResponseResolver<
	MockedRequest,
	typeof restContext
> = async (req, res, ctx) => {
	if (true) {
		const response: SaleShowResponseType = {
			datas: Object.assign(pagination, {
				datas: saleShowItems,
			}),
			sale_item: saleIndexItems[0],
			branches: branchItems,
		};
		if (process.env.NODE_ENV == 'development')
			await new Promise((resolve) => setTimeout(resolve, 1000));
		return res(ctx.status(200), ctx.json(response));
	} else {
		return res(ctx.status(401));
	}
};
