import { AdminReceivingItem } from 'types/api/admins/receiving';

export const adminReceivingItem: AdminReceivingItem = {
	shohin_image1: '',
	shohin_image2: '',
	shohin_image1_put_url: '',
	shohin_image2_put_url: '',
	niuke_image1: '',
	niuke_image2: '',
	niuke_image1_put_url: '',
	niuke_image2_put_url: '',
	NIUKE_NO: '12345',
	NIUKE_DEMPYO_NO: '12345',
	SHUKKASHA_NM: '出荷者A',
	SHOHIN_NM: '商品A',
	COLOR_NM: '赤',
	specification: 'サイズM',
	IRI_SU: 10,
	STOCK_SU: 5,
	SO_SU: 15,
	BIKO: '特記事項なし',
	SHOHIN_CD: '111111',
	SALE_DATE: '2022/02/02',
	SALES: [
		{
			SALE_DATE: '2023-06-17 10:00:00',
			SALE_KBN: 'WEB',
			BAISAN_NM: '購入者A',
			BAISAN_EDA: '001',
			WEB_TANKA: 1000,
			KUCHI_SU: 2,
			HA_SU: 0,
			SO_SU: 2,
			PRICE: 2000,
		},
		{
			SALE_DATE: '2023-06-17 14:30:00',
			SALE_KBN: '相対',
			BAISAN_NM: '購入者B',
			BAISAN_EDA: '002',
			WEB_TANKA: 1500,
			KUCHI_SU: 1,
			HA_SU: 0,
			SO_SU: 1,
			PRICE: 1500,
		},
	],
};
