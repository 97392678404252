import { nextReservationItem1 } from 'mocks/constants/admins/nextReservation';
import { pagination } from 'mocks/constants/pagenation';
import { item_names } from 'mocks/constants/web';
import { MockedRequest, ResponseResolver, restContext } from 'msw';
import { AdminNextReservationResponseType } from 'types/api/admins/nextReservation';

export const adminNextReservation: ResponseResolver<
	MockedRequest,
	typeof restContext
> = async (req, res, ctx) => {
	if (true) {
		const response: AdminNextReservationResponseType = {
			ITEM_NMS: item_names,
			SHOHIN_NMS: ['商品名1', '商品名2'],
			SHUKKASHA_NMS: ['出荷者名1', '出荷者名2'],
			TANTOSHA_NMS: ['担当者名1', '担当者名2'],
			datas: {
				...pagination,
				datas: [nextReservationItem1],
			},
		};
		if (process.env.NODE_ENV == 'development')
			await new Promise((resolve) => setTimeout(resolve, 1000));
		return res(ctx.status(200), ctx.json(response));
	} else {
		return res(ctx.status(401));
	}
};
