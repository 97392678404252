import { useUserState } from 'hooks/state/useUserState';
import { useState } from 'react';
import useSWR from 'swr';
import { AdminNewsRecoveryMailParamsType } from 'types/api/admins/recoveryMail';
import { getRecoveryMails } from 'lib/api/admins/recoveryNews';

export function useAdminNewsRecoveryMail() {
	const [user] = useUserState();
	const [params, setParams] = useState<AdminNewsRecoveryMailParamsType>();
	const key = `/mails+${Object.values(params || {})}`;

	const { data, error, isLoading, mutate } = useSWR(key, async () =>
		params ? await getRecoveryMails(user.token, params) : undefined,
	);

	return { data, mutate, isLoading, setParams };
}
