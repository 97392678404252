import { WebBuyDoneResponseType } from 'types/api/users/webBuyDone';
import { WebDetailItem } from 'types/api/users/webDetail';
import { WebNextOrderDoneResponseType } from 'types/api/users/webNextOrderDone';

export const item_names = [
	'すべて',
	'アルストロメリア',
	'カーネ',
	'カーネ　スプレー',
	'ガーベラ',
	'キク　小菊',
	'キク　スプレー菊',
	'キク　その他菊',
	'キク　輪菊',
	'切枝',
	'切葉',
	'切花',
	'スターチス　シヌアータ',
	'スターチス　ハイブリッド',
	'その他',
	'ダリア',
	'デルフィニューム',
	'トルコ桔梗',
	'バラ',
	'バラ　スプレー',
];

export const color_groups = [
	'すべて',
	'白系',
	'赤系',
	'黄系',
	'桃系',
	'紫・青系',
	'橙系',
	'茶系',
	'緑系',
	'複色',
	'黒系',
	'その他',
];

export const shippers = [
	'北空知広域種・花きセンター',
	'酒田市袖浦農協',
	'アキバナーセリー',
	'宮島農協・花きセンター',
	'フラワー埼玉',
];

export const varieties = [
	{ name: 'ボイジャー', reading: 'ボイジャー' },
	{ name: 'エストラーダ', reading: 'エストラーダ' },
	{ name: 'オライオン', reading: 'オライオン' },
	{ name: 'ハニーソフィア', reading: 'ハニーソフィア' },
	{ name: 'アレグラ', reading: 'アレグラ' },
	{ name: 'ソレロ', reading: 'ソレロ' },
	{ name: 'バニラ', reading: 'バニラ' },
	{ name: 'メモリー', reading: 'メモリー' },
	{ name: 'ナタリア', reading: 'ナタリア' },
	{ name: 'シカゴ', reading: 'シカゴ' },
	{ name: 'ティエスト', reading: 'ティエスト' },
	{ name: 'マニラ', reading: 'マニラ' },
	{ name: 'ガウディオ', reading: 'ガウディオ' },
	{ name: 'フィレンツェ', reading: 'フィレンツェ' },
	{ name: 'サーモンクイーン', reading: 'サーモンクイーン' },
	{ name: 'トロピカーナ', reading: 'トロピカーナ' },
	{ name: 'カクテル', reading: 'カクテル' },
	{ name: 'オランディア', reading: 'オランディア' },
	{ name: 'ソウル', reading: 'ソウル' },
	{ name: 'エリサ', reading: 'エリサ' },
	{ name: 'ピンクサプライズ', reading: 'ピンクサプライズ' },
	{ name: 'ブライダルピンク', reading: 'ブライダルピンク' },
	{ name: 'クミコ', reading: 'クミコ' },
	{ name: 'ピンクフロイド', reading: 'ピンクフロイド' },
	{ name: 'プリマドンナ', reading: 'プリマドンナ' },
	{ name: 'ハネムーン', reading: 'ハネムーン' },
	{ name: 'プラダ', reading: 'プラダ' },
	{ name: 'パバロッティー', reading: 'パバロッティー' },
	{ name: 'オニックス', reading: 'オニックス' },
	{ name: 'メイフェアー', reading: 'メイフェアー' },
	{ name: 'アルーラ', reading: 'アルーラ' },
	{ name: 'アズーロ', reading: 'アズーロ' },
	{ name: 'ドルフィン', reading: 'ドルフィン' },
	{ name: 'アバランシェ', reading: 'アバランシェ' },
	{ name: 'エベレスト', reading: 'エベレスト' },
	{ name: 'キッス', reading: 'キッス' },
	{ name: 'ルアンダ', reading: 'ルアンダ' },
	{ name: 'プモリ', reading: 'プモリ' },
	{ name: 'ハッピーフィート', reading: 'ハッピーフィート' },
	{ name: 'ビジョン', reading: 'ビジョン' },
	{ name: 'パリ', reading: 'パリ' },
	{ name: 'ガリレオ', reading: 'ガリレオ' },
	{ name: 'コロンビア', reading: 'コロンビア' },
	{ name: 'スイートドリーム', reading: 'スイートドリーム' },
	{ name: 'LA百合', reading: 'LAユリ' },
	{ name: 'ＳＰストック', reading: 'ＳＰストック' },
];

export const colors = [
	'すべて',
	'白',
	'白系',
	'オフホワイト',
	'赤',
	'濃赤',
	'赤系',
	'黄',
	'薄黄',
	'黄系',
	'桃',
	'薄桃',
	'桃系',
	'サーモンP',
	'赤紫',
	'濃赤紫',
	'桃紫系',
	'紫',
	'薄紫',
];

export const webDetailItem1: WebDetailItem = {
	id: 1,
	is_photo_present: true,
	is_negotiable: false,
	is_new: true,
	recommended_flag: false,
	discount_details: [''],
	bundle_tanka_list: [
		{
			quantity: 1,
			bundle_tanka: 100,
		},
		{
			quantity: 2,
			bundle_tanka: 200,
		},
	],
	shipment_provider_name: '山形県　株式会社　アキバナーセリー',
	product_name: 'バラ白系　オートクチュール',
	color: '赤',
	specification: '121-100 404-407 005 1-2F 80-120cm',
	quantity_per_package: 100,
	remaining_quantity: 50,
	max_quantity: 100,
	unit_price: '100',
	done: 10,
	remarks: '備考A',
	recommended_unit_price: 10,
	recommended_start_time: 1684484318,
	hasu: 10,
	YOYAKU_PERMIT_FLG: 1,
	BUY_PERMIT_FLG: true,
	item_cls: '1',
};

export const webDetailItem2: WebDetailItem = {
	id: 2,
	is_photo_present: false,
	is_negotiable: false,
	is_new: false,
	recommended_flag: false,
	discount_details: ['テストテスト', 'テストテスト', 'テストテスト'],
	bundle_tanka_list: [
		{
			quantity: 1,
			bundle_tanka: 100,
		},
		{
			quantity: 2,
			bundle_tanka: 200,
		},
	],
	shipment_provider_name: '山形県　株式会社　アキバナーセリー',
	product_name: 'バラ白系　オートクチュール',
	color: '赤',
	specification: '121-100 404-407 005 1-2F 80-120cm ',
	quantity_per_package: 100,
	remaining_quantity: 0,
	max_quantity: 100,
	unit_price: '100',
	done: 10,
	remarks: '備考A',
	recommended_unit_price: 0,
	recommended_start_time: 0,
	hasu: 0,
	YOYAKU_PERMIT_FLG: 1,
	BUY_PERMIT_FLG: true,
	item_cls: '1',
};

export const webDetailItem3: WebDetailItem = {
	id: 3,
	is_photo_present: false,
	is_negotiable: false,
	is_new: false,
	recommended_flag: false,
	discount_details: ['割引内容割引内容割引内容割引内容割引内容'],
	bundle_tanka_list: [
		{
			quantity: 1,
			bundle_tanka: 100,
		},
		{
			quantity: 2,
			bundle_tanka: 200,
		},
	],
	shipment_provider_name: '山形県　株式会社　アキバナーセリー',
	product_name: 'バラ白系　オートクチュール',
	color: '赤',
	specification: '121-100 404-407 005 1-2F 80-120cm ',
	quantity_per_package: 100,
	remaining_quantity: 100,
	max_quantity: 100,
	unit_price: '100',
	done: 109,
	remarks:
		'この文章はダミーです。文字の大きさ、量、字間、行間等を確認するために入れています。',
	recommended_unit_price: 0,
	recommended_start_time: 0,
	hasu: 0,
	YOYAKU_PERMIT_FLG: 1,
	BUY_PERMIT_FLG: true,
	item_cls: '1',
};

export const webDetailItem4: WebDetailItem = {
	id: 4,
	is_photo_present: true,
	is_negotiable: true,
	is_new: false,
	recommended_flag: false,
	discount_details: [''],
	bundle_tanka_list: [
		{
			quantity: 1,
			bundle_tanka: 100,
		},
		{
			quantity: 2,
			bundle_tanka: 200,
		},
	],
	shipment_provider_name: '山形県　株式会社　アキバナーセリー',
	product_name: 'バラ白系　オートクチュール',
	color: '赤',
	specification: '404-407 01 431 5-10F 005 1-2F 80-120cm',
	quantity_per_package: 100,
	remaining_quantity: 50,
	max_quantity: 100,
	unit_price: '0',
	done: 10,
	remarks: '備考A',
	recommended_unit_price: 0,
	recommended_start_time: 0,
	hasu: 0,
	YOYAKU_PERMIT_FLG: 1,
	BUY_PERMIT_FLG: true,
	item_cls: '1',
};

export const webDetailItem5: WebDetailItem = {
	id: 211,
	is_photo_present: false,
	is_negotiable: false,
	is_new: false,
	recommended_flag: false,
	discount_details: ['テストテスト', 'テストテスト', 'テストテスト'],
	bundle_tanka_list: [
		{
			quantity: 1,
			bundle_tanka: 100,
		},
		{
			quantity: 2,
			bundle_tanka: 200,
		},
	],
	shipment_provider_name: '山形県　株式会社　アキバナーセリー',
	product_name: 'バラ白系　オートクチュール',
	color: '赤',
	specification: '121-100 404-407 005 1-2F 80-120cm ',
	quantity_per_package: 100,
	remaining_quantity: 0,
	max_quantity: 100,
	unit_price: '100',
	done: 10,
	remarks: '備考A',
	recommended_unit_price: 0,
	recommended_start_time: 0,
	hasu: 10,
	YOYAKU_PERMIT_FLG: 0,
	BUY_PERMIT_FLG: true,
	item_cls: '1',
};

export const webNextOrderItem = {
	shipment_provider_name: '出荷業者名',
	product_name: '商品名A',
	sale_date: '2023/05/01',
	next_purchase_date: '2023/05/02',
	category: 1,
	color: '赤',
	grade: 'A',
	class: '1階級',
	specification: '121-100 404-407 005 1-2F 80-120cm',
	dimensions: 'dimensions',
	quantity_per_package: 10,
	unit_price: 100,
	remarks: '備考',
};

export const webNextDoneItem: WebNextOrderDoneResponseType = {
	shipment_provider_name: '出荷業者名',
	product_name: '商品名A',
	sale_date: '2023/05/01',
	next_purchase_date: '2023/05/02',
	category: 1,
	color: '赤',
	specification: '121-100 404-407 005 1-2F 80-120cm',
	quantity_per_package: 10,
	unit_price: 100,
	remarks: '備考',
};

export const webBuyItem = {
	shipment_provider_name: '出荷業者名',
	product_name: '商品名A',
	receiving_number: 'ABC123',
	sale_date: '2023-05-01',
	category: 1,
	color: '赤',
	grade: 'A',
	class: '1階級',
	specification: '121-100 404-407 005 1-2F 80-120cm',
	dimensions: 'dimensions',
	quantity_per_package: 10,
	quantity: 5,
	remaining_quantity: 3,
	max_quantity: 10,
	unit_price: 999,
	remarks: '備考',
};

export const webBuyDoneItem: WebBuyDoneResponseType = {
	shipment_provider_name: '出荷業者名',
	product_name: '商品名A',
	receiving_number: 'ABC123',
	sale_date: '2023-05-01',
	category: 1,
	color: '赤',
	specification: '121-100 404-407 005 1-2F 80-120cm',
	quantity_per_package: 10,
	HA_SU: 5,
	KUCHI_SU: 5,
	remaining_quantity: 3,
	max_quantity: 10,
	unit_price: 999,
	remarks: '備考',
};

export const webPhotoItem = {
	shohin_image_1:
		'https://s3.ap-northeast-1.amazonaws.com/hana.bucket87.test/image/shohin/004207_1.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAWN3U5JPCYRJY5D66%2F20230620%2Fap-northeast-1%2Fs3%2Faws4_request&X-Amz-Date=20230620T101201Z&X-Amz-Expires=5400&X-Amz-Signature=052b070658376fbd8419984e7b80ea8ba37974054be8c970f17dea8a8a4ab475&X-Amz-SignedHeaders=host',
	shohin_image_2:
		'https://s3.ap-northeast-1.amazonaws.com/hana.bucket87.test/image/shohin/004207_2.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAWN3U5JPCYRJY5D66%2F20230620%2Fap-northeast-1%2Fs3%2Faws4_request&X-Amz-Date=20230620T101201Z&X-Amz-Expires=5400&X-Amz-Signature=2aa077b131b5acbb89c998bb6cd7427a2a5525d071a46edf29b3d0ea7d3fa66f&X-Amz-SignedHeaders=host',
	niuke_image_1: null,
	niuke_image_2: null,
	shohin_name: 'アイビー',
};
