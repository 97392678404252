import { InputText } from 'components/atoms/InputText';
import { DefaultInputTextType } from './types';
import { defaultInputTextWrapper } from './styles';

export function DefaultInputText<T extends string | number>({
	className = '',
	...props
}: DefaultInputTextType<T>) {
	return (
		<InputText
			css={defaultInputTextWrapper}
			className={className + ' rounded-[8px] w-full'}
			{...props}
		/>
	);
}
