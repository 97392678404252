import { NextReservationIndexItem } from 'types/api/users/nextReservationIndex';

export const nextReservationItem: NextReservationIndexItem = {
	purchase_date: '2023/05/01',
	color: '赤',
	product_name: 'アストロメリア白系・アイスクリーム',
	specification: '121-100 404-407 005 1-2F 80-120cm',
	quantity_per_package: 0,
	quantity: 0,
	remarks: '備考',
};
