import {
	INITIAL_LINK_ITEM_STATE,
	useAdminLinkBody,
} from 'hooks/body/useAdminLinkBody';
import { CheckBoxType, useCheckBox } from 'hooks/inputs/useCheckBox';
import { InputTextType, useInputText } from 'hooks/inputs/useInputText';
import { useUserState } from 'hooks/state/useUserState';
import { updateLink } from 'lib/api/admins/link';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { LinkItemType } from 'types/api/admins/link';

export type AdminLinkFormType = {
	SUSPEND_FLG: CheckBoxType;
	LINK_CD: InputTextType;
	LINK_NM: InputTextType;
	LINK_URL: InputTextType;
};

export function useAdminLinkUpdateForm(_data?: LinkItemType) {
	const data = _data || INITIAL_LINK_ITEM_STATE;
	const { mutate } = useAdminLinkBody();
	const [user] = useUserState();
	const SUSPEND_FLG = useCheckBox(data.SUSPEND_FLG, '休止');
	const LINK_CD = useInputText(data.LINK_CD, 'No', false);
	const LINK_NM = useInputText(data.LINK_NM, 'リンク名称', false);
	const LINK_URL = useInputText('', 'URL', false);

	async function onSubmit() {
		const response = await updateLink(user.token, data.LINK_CD, {
			SUSPEND_FLG: form.SUSPEND_FLG.value,
			LINK_NM: form.LINK_NM.value,
			LINK_URL: form.LINK_URL.value,
			LINK_CD: form.LINK_CD.value,
		});
		mutate();
		if (response) {
			toast.success('リンクを更新しました。');
		}
	}

	const form: AdminLinkFormType = {
		SUSPEND_FLG: {
			...SUSPEND_FLG,
			onChange: async () => {
				form.SUSPEND_FLG.setValue(!form.SUSPEND_FLG.value);
				const response = await updateLink(user.token, data.LINK_CD, {
					SUSPEND_FLG: !form.SUSPEND_FLG.value,
				});
				if (response) {
					toast.success('休止フラグを更新しました。');
					mutate();
				}
			},
		},
		LINK_CD,
		LINK_NM,
		LINK_URL,
	};

	useEffect(() => {
		if (!_data) return;
		form.SUSPEND_FLG.setValue(_data.SUSPEND_FLG);
		form.LINK_CD.setValue(_data.LINK_CD);
		form.LINK_NM.setValue(_data.LINK_NM);
		form.LINK_URL.setValue(_data.LINK_URL);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [_data]);

	return { form, onSubmit };
}
