import { ProductsItem } from 'types/api/admins/products';

export const productsItem: ProductsItem = {
	id: '1',
	image1:
		'http://hanaichiba-sales.s3-website-ap-northeast-1.amazonaws.com/images/photo/24013564_s.jpg',
	image2: '',
	image1_put_url: '',
	image2_put_url: '',
	item_name: '品目名',
	product_name: '商品名称',
	kana_product_name: 'ショウヒンメイ',
	product_code: 'ABC123',
	item_name_code: 'XYZ456',
	abbr: '略称',
	kana_abbr: 'リャクショウ',
	color: '赤',
	color_code: '#FF0000',
	tax_group: '税率グループ',
	jf_code: 'JF123',
	old_item_code: 'OLD789',
	is_suspensions: false,
};

export const productsItem2: ProductsItem = {
	id: '2',
	image1:
		'http://hanaichiba-sales.s3-website-ap-northeast-1.amazonaws.com/images/photo/24013564_s.jpg',
	image2: '',
	image1_put_url: '',
	image2_put_url: '',
	item_name: '品目名',
	product_name: '商品名称',
	kana_product_name: 'ショウヒンメイ',
	product_code: 'ABC123',
	item_name_code: 'XYZ456',
	abbr: '略称',
	kana_abbr: 'リャクショウ',
	color: '赤',
	color_code: '#FF0000',
	tax_group: '税率グループ',
	jf_code: 'JF123',
	old_item_code: 'OLD789',
	is_suspensions: true,
};
