import { AxiosResponse } from 'axios';
import { instance } from 'components/organisms/AxiosClientProvider';
import {
	AdminNewsRecoveryMailNewBodyType,
	AdminNewsRecoveryMailParamsType,
	AdminNewsRecoveryMailResponseType,
	AdminNewsRecoveryMailUpdateBodyType,
} from 'types/api/admins/recoveryMail';

export async function getRecoveryMails(
	token: string,
	data: AdminNewsRecoveryMailParamsType,
) {
	try {
		const response: AxiosResponse<AdminNewsRecoveryMailResponseType> =
			await instance.get(`${process.env.API_ENDPOINT}/api/v1/admins/mails`, {
				params: data,
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

		return response.data;
	} catch (e) {}
}

export async function newNewsRecoveryMail(
	token: string,
	body: AdminNewsRecoveryMailNewBodyType,
) {
	try {
		await instance.post(
			`${process.env.API_ENDPOINT}/api/v1/admins/mails`,
			{ ...body },
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			},
		);
		return true;
	} catch (e) {
		return false;
	}
}

export async function updateNewsRecoveryMail(
	token: string,
	id: string,
	body: AdminNewsRecoveryMailUpdateBodyType,
) {
	try {
		await instance.put(
			`${process.env.API_ENDPOINT}/api/v1/admins/mails/${id}`,
			{ ...body },
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			},
		);
		return true;
	} catch (e) {
		return false;
	}
}

export async function deleteRecoveryMail(token: string, id: string) {
	try {
		await instance.delete(
			`${process.env.API_ENDPOINT}/api/v1/admins/mails/${id}`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			},
		);
		return true;
	} catch (e) {
		return false;
	}
}
