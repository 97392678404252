import { useTheme } from '@emotion/react';
import { FC } from 'react';
import { ButtonPropsType } from './types';

export const Button: FC<ButtonPropsType> = ({
	children,
	type,
	className,
	onClick,
}) => {
	const theme = useTheme();

	return (
		<button className={className} type={type} onClick={onClick}>
			{children}
		</button>
	);
};
