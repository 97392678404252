import { SaleItem } from 'types/api/admins/sale';

export const saleItem: SaleItem = {
	SALE_ID: '111',
	SALE_DATE: '2023-06-01 10:30:00',
	NIUKE_SALE_DATE: '2023-06-01 10:30:00',
	SALE_KBN: 'A',
	BAISAN_NM: '田中太郎',
	BAISAN_EDA: '001',
	SHOHIN_NM: '商品A',
	COLOR_NM: '赤',
	specification: '121-100 404-407 005 1-2F 80-120cm',
	WEB_TANKA: 1000,
	RATE_TANKA: 1100,
	KUCHI_SU: 5,
	HA_SU: 0,
	SO_SU: 5,
	PRICE: 5000,
	NIUKE_NO: '20230601-001',
	SHUKKASHA_NM: '出荷株式会社',
	IRI_SU: 10,
	DISCOUNT_TANKA: 900,
	STOCK_SU: 20,
	BIKO: '特別な要件はありません',
	max_quantity: 1111,
	DELETABLE_FLG: true,
};
