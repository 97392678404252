import { useModal } from 'hooks/useModal';
import { AdminNewsWebNewModalPresenter } from './presenter';
import { useAdminNewsWebNewForm } from 'hooks/forms/useAdminNewsWebNewForm';

export function AdminNewsWebNew() {
	const [isVisible, setIsVisible] = useModal('adminNewsWebNew');
	const { form, onSubmit } = useAdminNewsWebNewForm();

	return (
		<AdminNewsWebNewModalPresenter
			isVisible={isVisible}
			form={form}
			onClose={() => {
				setIsVisible(false);
			}}
			onSubmit={async () => {
				await onSubmit();
				setIsVisible(false);
			}}
		/>
	);
}
