import { MockedRequest, ResponseResolver, restContext } from 'msw';
import { SigninBodyType, SigninResponseType } from 'types/api/users/signin';

export const signin: ResponseResolver<
	MockedRequest,
	typeof restContext
> = async (req, res, ctx) => {
	const { id, password }: SigninBodyType = await req.json();

	if (id == 'test' && password == 'test') {
		const response: SigninResponseType = {
			token: '37507887|1DKmTVvdAcabATcTS5yf0I8LMdls5cUQw5fGtJkHpfgnirgn',
			requires_password_change: false,
			branch_number: '001',
			branch_name: 'テスト',
			baisan_cd: '0242',
			is_admin: true,
			is_first_login: false,
			news_ids: ['1', '2'],
			BUY_PERMIT_FLG: true,
		};

		return res(ctx.status(200), ctx.json(response));
	} else if (id == 'test' && password == 'first') {
		const response: SigninResponseType = {
			token: '37507887|1DKmTVvdAcabATcTS5yf0I8LMdls5cUQw5fGtJkHpfgnirgn',
			requires_password_change: true,
			branch_number: '002',
			baisan_cd: '0242',
			branch_name: 'テスト',
			is_admin: false,
			is_first_login: true,
			news_ids: ['1', '2'],
			BUY_PERMIT_FLG: true,
		};

		return res(ctx.status(200), ctx.json(response));
	} else {
		return res(ctx.status(401));
	}
};
