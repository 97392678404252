import {
	color_groups,
	item_names,
	shippers,
	varieties,
} from 'mocks/constants/web';
import { MockedRequest, ResponseResolver, restContext } from 'msw';
import { WebIndexResponseType } from 'types/api/users/webIndex';
import { getNthDateOptions } from 'utils/date';

export const web: ResponseResolver<MockedRequest, typeof restContext> = async (
	req,
	res,
	ctx,
) => {
	if (true) {
		const response: WebIndexResponseType = {
			item_names: item_names.map((d) => {
				return { name: d, is_new: true };
			}),
			sale_dates: getNthDateOptions(100).map((d) => d.value),
			color_groups: color_groups,
			shippers: shippers,
			varieties: varieties,
			open_hours: ['10時以降', '11時以降'],
			is_web_open: true,
			notice: [
				'ここにはお知らせテロップのテキストが入りますここにはお知らせテロップのテキストが入ります',
			],
		};
		if (process.env.NODE_ENV == 'development')
			await new Promise((resolve) => setTimeout(resolve, 1000));
		return res(ctx.status(200), ctx.json(response));
	} else {
	}
};
