import { useUserState } from 'hooks/state/useUserState';
import { useState } from 'react';
import useSWR from 'swr';
import { AdminNewsParamsType } from 'types/api/admins/news';
import { getAdminNews } from 'lib/api/admins/news';

export function useAdminNews() {
	const [user] = useUserState();
	const [params, setParams] = useState<AdminNewsParamsType>();
	const key = `/news+${Object.values(params || {})}`;
	const { data, error, isLoading, mutate } = useSWR(key, async () =>
		params ? await getAdminNews(user.token, params) : undefined,
	);

	return { data, mutate, isLoading, setParams };
}
