import { useModal } from 'hooks/useModal';
import { AdminRecoveryMailCategoryNewModalPresenter } from './presenter';
import { useAdminRecoveryMailCategoryNewForm } from 'hooks/forms/useAdminRecoveryMailCategoryNewForm';

export function AdminRecoveryMailCategoryNew() {
	const [isVisible, setVisible] = useModal('adminNewsRecoveryMailCategoryNew');
	const { form, onSubmit } = useAdminRecoveryMailCategoryNewForm();

	return (
		<AdminRecoveryMailCategoryNewModalPresenter
			isVisible={isVisible}
			form={form}
			onClose={() => {
				setVisible(false);
			}}
			onSubmit={async () => {
				await onSubmit();
				setVisible(false);
			}}
		/>
	);
}
