import { useModal } from 'hooks/useModal';
import { NegotiationModalPresenter } from './presenter';
import { useNegotiationNewForm } from 'hooks/forms/useNegotiationNewForm';
import { delay } from 'utils/application';

export function Negotiation() {
	const [isVisible, setVisible, modalState, setModalState] =
		useModal('negotiations');
	const { form, onSubmit, resetForm } = useNegotiationNewForm(modalState.data);

	return (
		<NegotiationModalPresenter
			data={modalState.data}
			isVisible={isVisible}
			form={form}
			onClose={async () => {
				setVisible(false);
				await delay(1000);
				setModalState({});
				resetForm();
			}}
			onSubmit={async () => {
				await onSubmit();
				setVisible(false);
				await delay(1000);
				setModalState({});
				resetForm();
			}}
		/>
	);
}
