import { instance } from 'components/organisms/AxiosClientProvider';

export async function uploadFile(url: string, file: File) {
	try {
		await instance.put(url, file, {
			headers: {
				'Content-Type': file.type,
			},
		});
		return true;
	} catch (e) {}
}
