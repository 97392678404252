import { useModal } from 'hooks/useModal';
import { SessionFailedModalPresenter } from './presenter';
import { useUserState } from 'hooks/state/useUserState';
import { INITIAL_USER_STATE } from 'globalStates/atoms/user';

export function SessionFailedModal() {
	const [isVisible, setVisible] = useModal('sessionFailedModal');
	const [, setUser] = useUserState();

	function onClickLogout() {
		setUser(INITIAL_USER_STATE);
		setVisible(false);
	}

	return (
		<SessionFailedModalPresenter
			isVisible={isVisible}
			onClickLogout={onClickLogout}
		/>
	);
}
