import { defaultTheme } from 'theme/theme';

const breakPoints = defaultTheme.breakpoints;

export function maxScreen(breakpoint: keyof typeof breakPoints | number) {
	const mediaQuery =
		typeof breakpoint == 'number'
			? `@media screen and (max-width: ${breakpoint}px)`
			: `@media screen and (max-width: ${breakPoints[breakpoint]})`;
	return mediaQuery;
}
