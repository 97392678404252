import { Theme, css } from '@emotion/react';

export const modalInnerStyle = (theme: Theme) => css`
	position: relative;
	width: 1080px;
	padding: 50px;
`;

export const adminProductImageModalH2 = (theme: Theme) => css`
	font-weight: 700;
	font-size: 1.25rem;
	line-height: 1.5;
	margin: 0 0 25px;
`;

export const adminProductImageModalImageWrapper = (theme: Theme) => css`
	position: relative;
	width: calc(50% - 0.5rem);
	min-height: 300px;
	background: ${theme.users.colors.background.gray1};
	margin-bottom: 30px;

	img {
		display: block;
		object-fit: contain;
		position: relative !important;
	}
`;

export const adminProductImageModalDropArea = (theme: Theme) => css`
	width: calc(50% - 0.5rem);
	background: ${theme.users.colors.background.gray1};
	margin-bottom: 30px;
`;

export const adminProductImageModalButton = (theme: Theme) => css`
	width: 170px;
	padding-left: 0;
	padding-right: 0;
	margin: 0 10px;
`;

export const adminProductImageModalCloseButton = (theme: Theme) => css`
	position: absolute;
	right: -10px;
	top: -10px;
	background-color: #333333;
`;
