import { MailItem } from 'types/api/admins/recoveryMail';

export const adminRecoveryMailItem1: MailItem = {
	MAIL_NO: 1,
	MAIL_CATEGORY_NO: 1,
	MAIL_CATEGORY_NAME: 'テストカテゴリー',
	MAIL_TITLE: '臨時メンテナンスについて',
	MAIL_TEXT: 'テストメール',
	MAIL_SEND_DATE: '2024/07/12  12:01:01',
	MAIL_CREATE_DATE: '2024/07/11  12:01:01',
	MAIL_SEND_START_DATE: '2024/07/11 12:01:01',
};

export const adminRecoveryMailItem2: MailItem = {
	MAIL_NO: 1,
	MAIL_CATEGORY_NO: 1,
	MAIL_CATEGORY_NAME: 'テストカテゴリー',
	MAIL_TITLE: '臨時メンテナンスについて',
	MAIL_TEXT: 'テストメール',
	MAIL_SEND_DATE: '',
	MAIL_CREATE_DATE: '2024/07/11  12:01:01',
	MAIL_SEND_START_DATE: '2024/07/11 12:01:01',
};
