import {
	INITIAL_NEWS_WEB_ITEM_STATE,
	useAdminNewsWebBody,
} from 'hooks/body/useAdminNewsWebBody';
import { CheckBoxType, useCheckBox } from 'hooks/inputs/useCheckBox';
import { InputTextType, useInputText } from 'hooks/inputs/useInputText';
import { useUserState } from 'hooks/state/useUserState';
import { updateNewsWeb } from 'lib/api/admins/newsWeb';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { AdminNewsWebItemType } from 'types/api/admins/newsWeb';

export type AdminNewsWebFormType = {
	SUSPEND_FLG: CheckBoxType;
	DATE: InputTextType;
	TITLE: InputTextType;
	START_DATE_DATE: InputTextType;
	START_DATE_TIME: InputTextType;
	END_DATE_DATE: InputTextType;
	END_DATE_TIME: InputTextType;
	PRINT_ORDER: InputTextType;
};

export function useAdminNewsWebUpdateForm(_data?: AdminNewsWebItemType) {
	const data = _data || INITIAL_NEWS_WEB_ITEM_STATE;
	const { mutate } = useAdminNewsWebBody();
	const [user] = useUserState();
	const SUSPEND_FLG = useCheckBox(data.SUSPEND_FLG, '休止');
	const DATE = useInputText(
		data.DATE?.replace(/\//g, '-') || '',
		'日付',
		false,
	);
	const TITLE = useInputText(data.TITLE, 'タイトル', false);
	const PRINT_ORDER = useInputText(data.PRINT_ORDER, '表示順', false);
	const START_DATE_DATE = useInputText(
		data.START_DATE?.split(' ')[0].replace(/\//g, '-') || '',
		'開始日時',
		false,
	);
	const START_DATE_TIME = useInputText(
		data.START_DATE?.split(' ')[1] || '',
		'開始日時',
		false,
	);
	const END_DATE_DATE = useInputText(
		data.END_DATE?.split(' ')[0].replace(/\//g, '-') || '',
		'終了日時',
		false,
	);
	const END_DATE_TIME = useInputText(
		data.END_DATE?.split(' ')[1] || '',
		'終了日時',
		false,
	);

	async function onSubmit() {
		const response = await updateNewsWeb(user.token, data.id, {
			SUSPEND_FLG: form.SUSPEND_FLG.value,
			DATE: DATE.value,
			START_DATE: `${START_DATE_DATE.value} ${START_DATE_TIME.value}`,
			END_DATE: `${END_DATE_DATE.value} ${END_DATE_TIME.value}`,
			TITLE: TITLE.value,
			PRINT_ORDER: PRINT_ORDER.value,
		});
		mutate();
		if (response) {
			toast.success('お知らせを更新しました。');
		}
	}

	const form: AdminNewsWebFormType = {
		SUSPEND_FLG: {
			...SUSPEND_FLG,
			onChange: async () => {
				form.SUSPEND_FLG.setValue(!form.SUSPEND_FLG.value);
				const response = await updateNewsWeb(user.token, data.id, {
					SUSPEND_FLG: !form.SUSPEND_FLG.value,
				});
				if (response) {
					toast.success('休止フラグを更新しました。');
					mutate();
				}
			},
		},
		DATE,
		START_DATE_DATE,
		START_DATE_TIME,
		END_DATE_DATE,
		END_DATE_TIME,
		TITLE,
		PRINT_ORDER,
	};

	useEffect(() => {
		if (!_data) return;
		form.SUSPEND_FLG.setValue(_data.SUSPEND_FLG);
		form.DATE.setValue(_data.DATE?.replace(/\//g, '-') || '');
		form.START_DATE_DATE.setValue(
			_data.START_DATE?.split(' ')[0].replace(/\//g, '-') || '',
		);
		form.START_DATE_TIME.setValue(_data.START_DATE?.split(' ')[1] || '');
		form.END_DATE_DATE.setValue(
			_data.END_DATE?.split(' ')[0].replace(/\//g, '-') || '',
		);
		form.END_DATE_TIME.setValue(_data.END_DATE?.split(' ')[1]);
		form.TITLE.setValue(_data.TITLE);
		form.PRINT_ORDER.setValue(_data.PRINT_ORDER);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [_data]);

	return { form, onSubmit };
}
